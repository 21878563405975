import styled from "styled-components";
import { useTable } from "react-table";
import React from "react";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const WalletTable = ({ transactions }) => {
  const data = React.useMemo(
    () => [
      transactions &&
        transactions.forEach((transaction) => {
          return {
            col1: `${
              transaction.time_transaction.substr(8, 2) +
              "." +
              transaction.time_transaction.substr(5, 2) +
              "." +
              transaction.time_transaction.substr(0, 4) +
              " " +
              transaction.time_transaction.substr(11, 4)
            }`,
            col2: "Списание средств",
            col3: "-20.00 ",
            col4: "0.00",
            col5: 'Поднятие объявления Ноутбук Apple MacBook Air 13" M',
          };
        }),
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Дата",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Событие",
        accessor: "col2",
      },
      {
        Header: "Сумма, BYN",
        accessor: "col3",
      },
      {
        Header: "Баланс",
        accessor: "col4",
      },
      {
        Header: "Примечание",
        accessor: "col5",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const Styles = styled.div`
    padding: 1rem;

    table {
      border-spacing: 0;
      border: 1px solid #f0f3f5;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th {
        background: rgba(76, 201, 240, 0.07);
        font-weight: 600;
        font-size: 18px;
        margin: 0;
        padding: 0.5rem;
        border: 1px solid #f0f3f5;
      }

      td {
        margin: 0;
        padding: 0.5rem;
        border: 1px solid #f0f3f5;
        line-height: 19px;
        color: #091d20;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;

  return (
    <div>
      <div>
        <Styles>
          <Table columns={columns} data={data} />
        </Styles>
      </div>
    </div>
  );
};

export default WalletTable;
