import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Requests from "../../http/axios-requests";
import { reloadData } from "../../redux/actions/userData";
import { Redirect } from "react-router-dom";

const UpdateItemSubmit = ({
  itemUpdateType,
  itemUpdateId,
  modalActiveSubmit,
  setModalActiveSubmit,
  setItemUpdateId,
  setItemUpdateType,
}) => {
  //обработчики кнопок

  const dispatch = useDispatch();

  const [redirect, setRedirect] = React.useState(null);

  const { reload } = useSelector(({ userData }) => userData);

  const noHandler = () => {
    setModalActiveSubmit(false);
    setItemUpdateType(false);
    setItemUpdateId(null);
  };

  const yesHandler = () => {
    if (itemUpdateType === "CLONE") {
      Requests.copySubject(itemUpdateId)
        .then(() => {
          dispatch(reloadData(!reload));
          setModalActiveSubmit(false);
          setRedirect(<Redirect to="/i-rent-out" />);
        })
        .catch((e) => {
          console.log("Ошибка копирования. Попробуйте перезагрузить страницу.");
        });
    } else if (itemUpdateType === "HIDE") {
      Requests.hideSubject(itemUpdateId)
        .then(() => {
          dispatch(reloadData(!reload));
          setModalActiveSubmit(false);
          setRedirect(<Redirect to="/i-rent-out" />);
        })
        .catch((e) => {
          console.log(
            "Ошибка скрытия позиции. Попробуйте перезагрузить страницу."
          );
        });
    } else if (itemUpdateType === "SHOW") {
      Requests.showSubject(itemUpdateId)
        .then(() => {
          dispatch(reloadData(!reload));
          setModalActiveSubmit(false);
          setRedirect(<Redirect to="/i-rent-out" />);
        })
        .catch((e) => {
          console.log(
            "Ошибка показа позиции. Попробуйте перезагрузить страницу."
          );
        });
    } else if (itemUpdateType === "DELETE") {
      Requests.deleteSubject(itemUpdateId)
        .then(() => {
          dispatch(reloadData(!reload));
          setModalActiveSubmit(false);
          setRedirect(<Redirect to="/i-rent-out" />);
        })
        .catch((e) => {
          console.log(
            "Ошибка показа позиции. Попробуйте перезагрузить страницу."
          );
        });
    }
  };

  return (
    <div
      className={
        modalActiveSubmit ? "reg-auth-wrapper active" : "reg-auth-wrapper"
      }
      style={{ display: "flex" }}
      onClick={() => setModalActiveSubmit(false)}
    >
      {redirect}
      <div className="reg-content">
        <div onClick={(e) => e.stopPropagation()} className="reg-form-wrapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              borderRadius: "10px",
              padding: "35px",
            }}
            className="reg-form-email-verification"
          >
            <div className="log-form-text-label-p-email__upper">
              <p>
                {itemUpdateType === "DELETE"
                  ? "Вы уверены, что хотите удалить позицию?"
                  : itemUpdateType === "CLONE"
                  ? "Вы уверены, что хотите копировать позицию?"
                  : itemUpdateType === "HIDE"
                  ? "Вы уверены, что хотите скрыть позицию?"
                  : itemUpdateType === "SHOW"
                  ? "Вы уверены, что хотите показать позицию?"
                  : ""}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
              }}
              className="reg-form-input-area"
            >
              <input
                style={{ marginRight: "20px" }}
                type="button"
                value="Нет"
                className="reg-form-contact-input__delete"
                onClick={noHandler}
              />

              <input
                style={{
                  backgroundColor: "red",
                  boxShadow: "3px 3px 22px rgba(219, 13, 13, 0.24)",
                }}
                type="button"
                className="reg-form-contact-input__delete"
                value="Да"
                onClick={yesHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateItemSubmit;
