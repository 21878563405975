import React from "react";
import { ProfileModerateComponent } from "../../components";
import "../../css/ReviewModerate.scss";
import Requests from "../../http/axios-requests";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";

const ProfilesModerate = () => {
  const [words, setWords] = React.useState();
  const [profiles, setProfiles] = React.useState();
  const [page, setPage] = React.useState(1);
  const [pagesCount, setPagesCount] = React.useState();
  const [reload, toggleReload] = React.useState(false);

  //обработчик клика по enter
  const keyDownHandler = React.useCallback((event) => {
    if (event.keyCode === 13) {
      setProfiles([]);
      Requests.getModeratorProfiles(page, words).then((res) => {
        setPagesCount(Number(res.headers["count-filter-items"]));
        setProfiles(res.data);
      });
    }

    if (event.keyCode === 27) {
      setProfiles([]);
      setPage(1);
      setWords("");
      Requests.getModeratorProfiles(1).then((res) => {
        setPagesCount(Number(res.headers["count-filter-items"]));
        setProfiles(res.data);
      });
    }
  });

  const handleChange = (event, value) => {
    setProfiles([]);
    setPage(value);
    Requests.getModeratorProfiles(value, words).then((res) => {
      setPagesCount(Number(res.headers["count-filter-items"]));
      setProfiles(res.data);
    });
  };

  React.useEffect(() => {
    setProfiles([]);
    Requests.getModeratorProfiles(page, words).then((res) => {
      setPagesCount(Number(res.headers["count-filter-items"]));
      setProfiles(res.data);
    });
  }, [reload]);

  return (
    <div className="admin_reviews_wrapper" onKeyDown={(e) => keyDownHandler(e)}>
      <div className="admin_reviews_body_wrapper">
        <div className="admin_reviews_optional_row">
          <p className="admin_reviews_optional_row_title"></p>
          <TextField
            style={{ marginBottom: "20px" }}
            id="standard-basic"
            label="Поиск по словам или id"
            variant="standard"
            value={words}
            onChange={(e) => setWords(e.target.value)}
          />
          <Pagination count={pagesCount} page={page} onChange={handleChange} />
        </div>
        <div className="admin_reviews_body_cards">
          {profiles &&
            profiles.map((item, index) => {
              return (
                <ProfileModerateComponent
                  item={item}
                  key={index}
                  reload={reload}
                  toggleReload={toggleReload}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProfilesModerate;
