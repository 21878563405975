import React from "react";
import {
  ItemReviewModerateComponent,
  ProfileReviewModerateComponent,
} from "../../components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { pink } from "@mui/material/colors";

import "../../css/ReviewModerate.scss";
import Requests from "../../http/axios-requests";

const ReviewModerate = () => {
  const [showOnlyHidden, setShowOnlyHidden] = React.useState("false");
  const [showReviewsType, setShowReviewsType] = React.useState("profiles");
  const [itemsReviewsList, setItemsReviewsList] = React.useState(null);
  const [profilesReviewsList, setProfilesReviewsList] = React.useState(null);
  const [reloadReviews, toggleReloadReviews] = React.useState(false);

  React.useEffect(() => {
    Requests.getItemsReviewsToModerate().then((res) =>
      setItemsReviewsList(res.data)
    );

    Requests.getProfilesReviewsToModerate().then((res) =>
      setProfilesReviewsList(res.data)
    );
  }, [reloadReviews]);

  return (
    <div className="admin_reviews_wrapper">
      <div className="admin_reviews_body_wrapper">
        <div className="admin_reviews_optional_row">
          <p className="admin_reviews_optional_row_title"></p>
          <FormControl component="fieldset">
            <FormLabel component="legend">Показывать</FormLabel>
            <RadioGroup
              onChange={(e) => setShowOnlyHidden(e.target.value)}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="false"
                checked={showOnlyHidden === "false"}
                control={<Radio color="secondary" />}
                label="Все"
              />
              <FormControlLabel
                value="true"
                checked={showOnlyHidden === "true"}
                control={<Radio color="secondary" />}
                label="Только скрытые"
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <RadioGroup
              onChange={(e) => setShowReviewsType(e.target.value)}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="profiles"
                checked={showReviewsType === "profiles"}
                control={<Radio color="secondary" />}
                label="Отзывы на профили"
              />
              <FormControlLabel
                value="items"
                checked={showReviewsType === "items"}
                control={<Radio color="secondary" />}
                label="Отзывы на позиции"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="admin_reviews_body_cards">
          {itemsReviewsList &&
            showReviewsType === "items" &&
            itemsReviewsList.map((item, index) => (
              <ItemReviewModerateComponent
                item={item}
                key={index}
                showOnlyHidden={showOnlyHidden}
                reloadReviews={reloadReviews}
                toggleReloadReviews={toggleReloadReviews}
              />
            ))}
          {profilesReviewsList &&
            showReviewsType === "profiles" &&
            profilesReviewsList.map((item, index) => (
              <ProfileReviewModerateComponent
                item={item}
                key={index}
                showOnlyHidden={showOnlyHidden}
                reloadReviews={reloadReviews}
                toggleReloadReviews={toggleReloadReviews}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewModerate;
