import React from "react";
import {
  ReviewModerate,
  ItemModerate,
  Statistics,
  ProfilesModerate,
  Mailing,
} from "..";

import "../../css/AdminBasis.scss";

const AdminPanelBasis = () => {
  const [activeComponent, setActiveCmponent] = React.useState("profiles");

  return (
    <div className="admin_reviews_wrapper">
      <div className="admin_reviews_header">
        <p className="admin_reviews_header_p">Администратор</p>
      </div>
      <div className="admin_basis_wrapper">
        <div className="admin_basis_left_menu">
          <ul className="admin_basis_menu__ul">
            <li
              onClick={() => setActiveCmponent("profiles")}
              className={
                activeComponent === "profiles"
                  ? "admin_basis_menu__li active"
                  : "admin_basis_menu__li"
              }
            >
              Модерация профилей
            </li>
            <li
              onClick={() => setActiveCmponent("items")}
              className={
                activeComponent === "items"
                  ? "admin_basis_menu__li active"
                  : "admin_basis_menu__li"
              }
            >
              Модерация позиций
            </li>
            <li
              onClick={() => setActiveCmponent("reviews")}
              className={
                activeComponent === "reviews"
                  ? "admin_basis_menu__li active"
                  : "admin_basis_menu__li"
              }
            >
              Модерация отзывов
            </li>
            <li
              onClick={() => setActiveCmponent("mailing")}
              className={
                activeComponent === "mailing"
                  ? "admin_basis_menu__li active"
                  : "admin_basis_menu__li"
              }
            >
              Рассылка по Email
            </li>
            <li
              onClick={() => setActiveCmponent("stats")}
              className={
                activeComponent === "stats"
                  ? "admin_basis_menu__li active"
                  : "admin_basis_menu__li"
              }
            >
               Статистика
            </li>
          </ul>
        </div>

        <div className="admin_basis_right_component">
          {activeComponent === "profiles" && <ProfilesModerate />}
          {activeComponent === "items" && <ItemModerate />}
          {activeComponent === "reviews" && <ReviewModerate />}
          {activeComponent === "stats" && <Statistics />}
          {activeComponent === "mailing" && <Mailing />}
        </div>
      </div>
    </div>
  );
};

export default AdminPanelBasis;
