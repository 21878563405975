import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDispatch } from "react-redux";

const CountryChoicePop = ({
  townSelectorActive,
  setTownSelectorActive,
  parentRef,
  locations,
  setUserCoords,
  setUserLocation,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (localStorage.getItem("location")) {
      dispatch(setUserLocation(localStorage.getItem("location")));
      dispatch(setUserCoords(localStorage.getItem("coords")));
    }
  }, []);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (!parentRef.current.contains(e.target)) setTownSelectorActive(false);
      }}
    >
      <div
        className={
          townSelectorActive
            ? "dropdown-profile-menu-wrapper active"
            : "dropdown-profile-menu-wrapper"
        }
        style={{
          position: "absolute",
          top: "35px",
          right: "-45px",
          overflowY: "scroll",
          height: "300px",
        }}
      >
        <div className="dropdown-profile-menu-content">
          {locations &&
            locations.map((item) => (
              <p
                onClick={() => {
                  dispatch(
                    setUserCoords(
                      `${item.coords[1]} ${item.coords[0].split(",")[0]}`
                    )
                  );
                  dispatch(setUserLocation(item.location));
                  localStorage.setItem("location", item.location);
                  localStorage.setItem(
                    "coords",
                    `${item.coords[1]} ${item.coords[0].split(",")[0]}`
                  );
                }}
                className="dropdown-profile-menu-p"
                style={
                  localStorage.getItem("location") === item.location
                    ? { color: "#4cc9f0" }
                    : {}
                }
              >
                {item.location}
              </p>
            ))}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default CountryChoicePop;
