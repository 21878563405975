import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Requests from "../../http/axios-requests";
import Delete from "../../img/MainPage/delete.webp";
import hide from "../../img/MainPage/hide.webp";
import avatar from "../../img/Google.png";
import { useSelector, useDispatch } from "react-redux";
import { openErrorAlert, openSuccessAlert } from "../../redux/actions/userData";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import "../../css/ItemsModerate.scss";

const ItemReviewModerateComponent = ({ item, reload, toggleReload, page }) => {
  const dispatch = useDispatch();

  const [textFieldsActive, setTextFieldsActive] = React.useState(false);

  const [profileName, setProfileName] = React.useState(item.first_name);
  const [profileSurname, setProfileSurname] = React.useState(item.last_name);
  const [profileEmail, setProfileEmail] = React.useState(item.email);
  const [profileNumber, setProfileNumber] = React.useState(item.phone);
  const [profileType, setProfileType] = React.useState(
    item.status === 1 ? "1" : item.status === 2 ? "2" : ""
  );
  const [birth, setBirth] = React.useState(item.date_birthday);
  const [companyName, setCompanyName] = React.useState(item.company_name);
  const [about, setAbout] = React.useState(item.about);
  const [emailVerify, setEmailVerify] = React.useState(item.email_verify);
  const [numberVerify, setNumberVerify] = React.useState(item.phone_verify);
  const [itemsLimit, setItemsLimit] = React.useState(item.count_items);
  const [instagram, setInstagram] = React.useState(item.link_instagram);
  const [vk, setVk] = React.useState(item.vk_account);
  const [wa, setWa] = React.useState(item.whatsapp_account);
  const [google, setGoogle] = React.useState(item.google_account);
  const [telegram, setTelegram] = React.useState(item.telegram_account);
  const [site, setSite] = React.useState(item.user_site);
  const [viber, setViber] = React.useState(item.viber_account);
  const [facebook, setFacebook] = React.useState(item.link_facebook);
  const [ok, setOk] = React.useState(item.ok_account);

  const [openedAdditional, setOpenedAdditional] = React.useState(false);

  //обрабатываем обновление профиля
  const updateProfile = () => {
    Requests.changeModeratorProfile(
      item.id,
      profileType,
      profileName,
      profileSurname,
      companyName,
      profileEmail,
      profileNumber,
      emailVerify,
      numberVerify,
      itemsLimit,
      birth,
      about,
      vk,
      instagram,
      viber,
      wa,
      telegram,
      google,
      site,
      facebook,
      ok
    )
      .then(() => {
        dispatch(openSuccessAlert("Успешно!"));
        setTextFieldsActive(false);
        toggleReload(!reload);
      })
      .catch((err) => dispatch(openErrorAlert(err.response.data)));
  };

  const blockProfile = () => {
    Requests.moderatorBlockProfile(item.id)
      .then(() => {
        dispatch(openSuccessAlert("Успешно заблокирован"));
      })
      .catch((err) => dispatch(openErrorAlert(err.response.data)))
      .finally(() => toggleReload(!reload));
  };

  const unblockProfile = () => {
    Requests.moderatorUnblockProfile(item.id)
      .then(() => {
        dispatch(openSuccessAlert("Успешно разюлокирован"));
      })
      .catch((err) => dispatch(openErrorAlert(err.response.data)))
      .finally(() => toggleReload(!reload));
  };

  return (
    <div className={"cardthing_rewieus_container_content"}>
      <div className={"cardthing_rewieus_container_content"}>
        <a
          target="_blank"
          href={`/item/${1}`}
          style={{ textDecoration: "none" }}
        >
          <img
            alt="logo"
            src={`data:image/png;base64,${item.image_profile}`}
            className="logo_photo_rewieus_block"
          />
        </a>
        <div className="rewieus_container_content_info">
          <div
            style={{ marginTop: "10px", marginBottom: "0px" }}
            className="add-item-input-wrapper"
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Тип аккаунта</FormLabel>
              <RadioGroup
                value={profileType}
                onChange={(e) => setProfileType(e.target.value)}
                row
                aria-label="gender"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      checked={profileType === "1"}
                      disabled={!textFieldsActive}
                    />
                  }
                  label="Личный"
                />

                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      checked={profileType === "2"}
                      disabled={!textFieldsActive}
                    />
                  }
                  label="Бизнес"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div
            style={{ marginTop: "10px" }}
            className="moderate_items_name_item_wrapper"
          >
            <p
              style={{ marginRight: "5px", fontWeight: "500" }}
              className="rewieus_container_content_info_text"
            >
              Id:
            </p>
            <p className="moderate_items_name_item_name_item">{item.id}</p>
          </div>
          {!textFieldsActive && (
            <div
              style={{ marginTop: "10px" }}
              className="moderate_items_name_item_wrapper"
            >
              <p
                style={{ marginRight: "5px", fontWeight: "500" }}
                className="rewieus_container_content_info_text"
              >
                Имя:
              </p>
              <p className="moderate_items_name_item_name_item">
                {profileName}
              </p>
            </div>
          )}

          {textFieldsActive && (
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              label="Имя"
              id="outlined-size-small"
              size="small"
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
            />
          )}

          {!textFieldsActive && (
            <div
              style={{ marginTop: "10px" }}
              className="moderate_items_name_item_wrapper"
            >
              <p
                style={{ marginRight: "5px", fontWeight: "500" }}
                className="rewieus_container_content_info_text"
              >
                Фамилия:
              </p>
              <p className="moderate_items_name_item_name_item">
                {profileSurname}
              </p>
            </div>
          )}

          {textFieldsActive && (
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              label="Фамилия"
              id="outlined-size-small"
              size="small"
              value={profileSurname}
              onChange={(e) => setProfileSurname(e.target.value)}
            />
          )}

          {!textFieldsActive && (
            <div
              style={{ marginTop: "10px" }}
              className="moderate_items_name_item_wrapper"
            >
              <p
                style={{ marginRight: "5px", fontWeight: "500" }}
                className="rewieus_container_content_info_text"
              >
                Название компании (для Бизнеса):
              </p>
              <p className="moderate_items_name_item_name_item">
                {companyName}
              </p>
            </div>
          )}

          {textFieldsActive && (
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              label="Название компании (для Бизнеса)"
              id="outlined-size-small"
              size="small"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          )}

          {!textFieldsActive && (
            <div
              style={{ marginTop: "10px" }}
              className="moderate_items_name_item_wrapper"
            >
              <p
                style={{ marginRight: "5px", fontWeight: "500" }}
                className="rewieus_container_content_info_text"
              >
                Email:
              </p>
              <p className="moderate_items_name_item_name_item">
                {profileEmail}
              </p>
            </div>
          )}

          {textFieldsActive && (
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              label="Email"
              id="outlined-size-small"
              size="small"
              value={profileEmail}
              onChange={(e) => setProfileEmail(e.target.value)}
            />
          )}

          {!textFieldsActive && (
            <div
              style={{ marginTop: "10px" }}
              className="moderate_items_name_item_wrapper"
            >
              <p
                style={{ marginRight: "5px", fontWeight: "500" }}
                className="rewieus_container_content_info_text"
              >
                Телефон:
              </p>
              <p className="moderate_items_name_item_name_item">
                {profileNumber}
              </p>
            </div>
          )}

          {textFieldsActive && (
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              label="Телефон"
              id="outlined-size-small"
              size="small"
              value={profileNumber}
              onChange={(e) => setProfileNumber(e.target.value)}
            />
          )}

          <FormGroup style={{ marginTop: "10px", display: "flex" }}>
            <FormControlLabel
              disabled={!textFieldsActive}
              control={
                <Checkbox
                  checked={emailVerify}
                  onChange={(e) => setEmailVerify(e.target.checked)}
                />
              }
              label="Подтверждение почты"
            />
            <FormControlLabel
              disabled={!textFieldsActive}
              control={
                <Checkbox
                  checked={numberVerify}
                  onChange={(e) => setNumberVerify(e.target.checked)}
                />
              }
              label="Подтверждение телефона"
            />
          </FormGroup>

          {!textFieldsActive && (
            <div
              style={{ marginTop: "10px" }}
              className="moderate_items_name_item_wrapper"
            >
              <p
                style={{ marginRight: "5px", fontWeight: "500" }}
                className="rewieus_container_content_info_text"
              >
                Персональный лимит позиций:
              </p>
              <p className="moderate_items_name_item_name_item">{itemsLimit}</p>
            </div>
          )}

          {textFieldsActive && (
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              label="Персональный лимит позиций"
              id="outlined-size-small"
              size="small"
              value={itemsLimit}
              onChange={(e) => setItemsLimit(e.target.value)}
            />
          )}

          <p
            style={{
              marginTop: "20px",
              fonyWeight: "600",
              color: "blue",
              fontSize: "18px",
              cursor: "pointer",
            }}
            onClick={() => setOpenedAdditional(!openedAdditional)}
          >
            {openedAdditional ? "Скрыть" : "Раскрыть"} все параметры
          </p>

          {openedAdditional && (
            <div>
              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    Дата рождения:
                  </p>
                  <p className="moderate_items_name_item_name_item">{birth}</p>
                </div>
              )}

              {textFieldsActive && (
                <input
                  type="date"
                  value={birth}
                  onChange={(e) => setBirth(e.target.value)}
                  style={{ marginTop: "10px" }}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    О себе:
                  </p>
                  <p className="moderate_items_name_item_name_item">{about}</p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="О себе"
                  id="outlined-size-small"
                  size="small"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    VK:
                  </p>
                  <p className="moderate_items_name_item_name_item">{vk}</p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="VK"
                  id="outlined-size-small"
                  size="small"
                  value={vk}
                  onChange={(e) => setVk(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    Instagram:
                  </p>
                  <p className="moderate_items_name_item_name_item">
                    {instagram}
                  </p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="Instagram"
                  id="outlined-size-small"
                  size="small"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    Facebook:
                  </p>
                  <p className="moderate_items_name_item_name_item">
                    {facebook}
                  </p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="Facebook"
                  id="outlined-size-small"
                  size="small"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    Viber:
                  </p>
                  <p className="moderate_items_name_item_name_item">{viber}</p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="Viber"
                  id="outlined-size-small"
                  size="small"
                  value={viber}
                  onChange={(e) => setViber(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    WhatsApp:
                  </p>
                  <p className="moderate_items_name_item_name_item">{wa}</p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="WhatsApp"
                  id="outlined-size-small"
                  size="small"
                  value={wa}
                  onChange={(e) => setWa(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    Telegram:
                  </p>
                  <p className="moderate_items_name_item_name_item">
                    {telegram}
                  </p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="Telegram"
                  id="outlined-size-small"
                  size="small"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    Google:
                  </p>
                  <p className="moderate_items_name_item_name_item">{google}</p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="Google"
                  id="outlined-size-small"
                  size="small"
                  value={google}
                  onChange={(e) => setGoogle(e.target.value)}
                />
              )}

              {!textFieldsActive && (
                <div
                  style={{ marginTop: "10px" }}
                  className="moderate_items_name_item_wrapper"
                >
                  <p
                    style={{ marginRight: "5px", fontWeight: "500" }}
                    className="rewieus_container_content_info_text"
                  >
                    Сайт:
                  </p>
                  <p className="moderate_items_name_item_name_item">{site}</p>
                </div>
              )}

              {textFieldsActive && (
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  label="Сайт"
                  id="outlined-size-small"
                  size="small"
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                />
              )}
            </div>
          )}

          <div className="reviews_moderate_lower_buttons">
            {textFieldsActive && (
              <div className="item-card-profile-button-wrapper">
                <p
                  style={{ marginLeft: "0px" }}
                  type="button"
                  className="item-card-profile-button__optional"
                  onClick={updateProfile}
                >
                  Сохранить
                </p>
              </div>
            )}

            {textFieldsActive && (
              <div className="item-card-profile-button-wrapper">
                <p
                  style={{ marginLeft: "0px" }}
                  type="button"
                  className="item-card-profile-button__optional"
                  onClick={() => {
                    toggleReload(!reload);
                    setTextFieldsActive(false);
                  }}
                >
                  Отменить
                </p>
              </div>
            )}

            {!textFieldsActive && (
              <div className="item-card-profile-button-wrapper">
                <p
                  style={{ marginLeft: "0px" }}
                  type="button"
                  className="item-card-profile-button__optional"
                  onClick={() => setTextFieldsActive(true)}
                >
                  Редактировать
                </p>
              </div>
            )}
            {!item.is_block && (
              <div
                onClick={blockProfile}
                className="item-card-profile-button-wrapper"
              >
                <p
                  value="Показать"
                  type="button"
                  className="item-card-profile-button__optional"
                >
                  Заблокировать
                </p>
              </div>
            )}

            {item.is_block && (
              <div
                onClick={unblockProfile}
                className="item-card-profile-button-wrapper"
              >
                <p
                  value="Показать"
                  type="button"
                  className="item-card-profile-button__optional"
                >
                  Разблокировать
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemReviewModerateComponent;
