import React from "react";
import Requests from "../../http/axios-requests";
import { ItemCardMini } from "../index";
import Carousel from "react-elastic-carousel";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const RandomItemsSlider = () => {
  const [randomItems, setRandomItems] = React.useState(false);

  React.useEffect(() => {
    Requests.fetchPopular().then((res) => {
      setRandomItems(res.data);
    });
  }, []);

  const carouselRef = React.useRef(null);
  const totalPages = 5;

  const carouselRefMobile = React.useRef(null);
  const totalPagesMobile = 8;

  const carouselRefTablet = React.useRef(null);

  const carouselRef1024 = React.useRef(null);

  let resetTimeout;

  return (
    <section className="recent-wrapper" id="wrapper_slider">
      <div className="recent-content">
        <h2 className="recent-p">Часто просматриваемые</h2>
        <div className="recent-blocks-wrapper">
          <div className="recent-blocks-slider-container">
            {window.screen.width > 1024 && randomItems && (
              <Carousel
                itemsToShow={6}
                pagination={false}
                itemPadding={[20, 20, 40, 20]}
                enableAutoPlay
                autoPlaySpeed={3000}
                preventDefaultTouchmoveEvent={true}
                enableSwipe={false}
                ref={carouselRef}
                onNextEnd={({ index }) => {
                  clearTimeout(resetTimeout);
                  if (index + 1 === totalPages) {
                    resetTimeout = setTimeout(() => {
                      carouselRef.current.goTo(0);
                    }, 1500); // same time
                  }
                }}
              >
                {randomItems &&
                  randomItems.map((item, index) => (
                    <ItemCardMini key={index} item={item} />
                  ))}
              </Carousel>
            )}

            {/* копия для адаптива 480 */}
            {randomItems && (
              <div id="swiper_mobile">
                <Carousel
                  itemsToShow={3}
                  pagination={false}
                  itemPadding={[20, 15, 20, 15]}
                  enableAutoPlay
                  autoPlaySpeed={3000}
                  showArrows={false}
                  ref={carouselRefMobile}
                  onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout);
                    if (index + 1 === totalPages) {
                      resetTimeout = setTimeout(() => {
                        carouselRefMobile.current.goTo(0);
                      }, 1500); // same time
                    }
                  }}
                >
                  {randomItems &&
                    randomItems.map((item, index) => (
                      <ItemCardMini key={index} item={item} />
                    ))}
                </Carousel>
              </div>
            )}

            {/* копия для планшета 800 */}
            {randomItems && (
              <div id="swiper_mobile_768">
                <Carousel
                  itemsToShow={3}
                  pagination={false}
                  itemPadding={[0, 7, 0, 7]}
                  enableAutoPlay
                  autoPlaySpeed={3000}
                  ref={carouselRefTablet}
                  onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout);
                    if (index + 1 === totalPages) {
                      resetTimeout = setTimeout(() => {
                        carouselRefTablet.current.goTo(0);
                      }, 1500); // same time
                    }
                  }}
                >
                  {randomItems &&
                    randomItems.map((item, index) => (
                      <ItemCardMini key={index} item={item} />
                    ))}
                </Carousel>
              </div>
            )}

            {/* копия для планшета 800 */}
            {window.screen.width >= 1024 &&
              window.screen.width < 1280 &&
              randomItems && (
                <Carousel
                  itemsToShow={3}
                  pagination={false}
                  itemPadding={[20, 20, 40, 20]}
                  enableAutoPlay
                  autoPlaySpeed={3000}
                  ref={carouselRef1024}
                  onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout);
                    if (index + 1 === totalPages) {
                      resetTimeout = setTimeout(() => {
                        carouselRef1024.current.goTo(0);
                      }, 1500); // same time
                    }
                  }}
                >
                  {randomItems &&
                    randomItems.map((item, index) => (
                      <ItemCardMini key={index} item={item} />
                    ))}
                </Carousel>
              )}

            {!randomItems && (
              <Box
                sx={{
                  display: "flex",
                  height: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RandomItemsSlider;
