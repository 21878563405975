import React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ViberIcon from "@mui/icons-material/WifiCalling3";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";

const style = {
  margin: 0,
  right: 20,
  bottom: 20,
  position: "fixed",
};

const actions = [
  {
    icon: <TelegramIcon style={{ fill: "#000000" }} />,
    name: "Telegram",
    link: "https://t.me/razdelisdrugim",
  },
  {
    icon: <WhatsAppIcon style={{ fill: "#000000" }} />,
    name: "WhatsApp",
    link: "https://api.whatsapp.com/send/?phone=375291587752",
  },
  {
    icon: <ViberIcon style={{ fill: "#000000" }} />,
    name: "Viber",
    link: "viber://chat?number=+375291587752",
  },
];

export default function Socials() {
  const [open, setOpen] = React.useState(false);
  const handleOpenWindow = (link) => {
    window.open(`${link}`, "_blank");
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        style={style}
        hidden={false}
        icon={<AddIcCallIcon />}
        onClose={() => setOpen(false)}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleOpenWindow(action.link)}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
