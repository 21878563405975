import React from "react";
import { Header, Footer } from "../../components/index";
import "./footer-pages.css";
import { Helmet } from "react-helmet";
import { rootAddress } from "../../http/axios-requests";

const OfferAgreement = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CardThings">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={`https://razdelisdrugim.by/offer-agreement`}
        />
        <title>Договор оказания услуг: #разделисдругим</title>
        <meta
          name="description"
          content=" Договор оказания услуг: #разделисдругим"
        />
        <meta
          name="keywords"
          content="Договор оказания услуг: #разделисдругим"
        />
        <meta
          property="og:title"
          content={`Договор оказания услуг: #разделисдругим`}
        />
        <meta
          property="title"
          content={`Договор оказания услуг: #разделисдругим`}
        />
        <meta property="og:site_name" content="Razdelisdrugim.by" />
        <meta
          property="og:description"
          content="Договор оказания услуг: #разделисдругим"
        />

        <meta
          property="og:url"
          content={`https://razdelisdrugim.by/offer-agreement`}
        />
        <meta
          property="og:image"
          content={`${rootAddress}/media/favicon.ico`}
          data-react-helmet="true"
        />
      </Helmet>
      <div className="CardThings_Wrapper">
        <div className="CardThings_Wrapper_container">
          <div className="container_content_card">
            <div className="card_content_footer_pages">
              <h1 className="footer_pages_main_title">
                ПУБЛИЧНЫЙ ДОГОВОР ОКАЗАНИЯ УСЛУГ (ДОГОВОР-ОФЕРТА){" "}
              </h1>
              <h2 className="footer_pages_main_title">
                (между платформой Razdelisdrugim.by и Арендодателем)
              </h2>
              <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ </h3>
              <p className="footer_pages_main_p">
                1.1. Настоящий договор (именуемый далее – Договор), заключаемый
                в порядке статей 396, 398 Гражданского кодекса Республики
                Беларусь, определяет порядок оказания услуг, а также взаимные
                права, обязанности и порядок взаимоотношений между
                Индивидуальным предпринимателем Клементьевой Ольгой
                Анатольевной, именуемой в дальнейшем «ИСПОЛНИТЕЛЬ», действующей
                на основании свидетельства о государственной регистрации
                индивидуального предпринимателя, выданного Минским районным
                исполнительным комитетом 06.09.2021 г. и юридическим лицом
                (индивидуальным предпринимателем) зарегистрированными в
                Республике Беларусь и имеющими право осуществления коммерческой
                деятельности, либо физическим лицом, осуществляющим
                деятельность, которая не является в соответствии с
                законодательством предпринимательской, присоединившимся к
                Договору в целом и именуемым в дальнейшем «ЗАКАЗЧИК».
                <br></br>
                1.2. В настоящем Публичном договоре оказания услуг термины
                используются в следующих значениях:<br></br>
                ИСПОЛНИТЕЛЬ (администрация (владелец) портала) – Индивидуальный
                предприниматель Клементьева Ольга Анатольевна и/или лица,
                уполномоченные им должным образом на управление Порталом и
                оказание Услуг ЗАКАЗЧИКУ. ИСПОЛНИТЕЛЬ является поставщиком Услуг
                промежуточного характера в информационной сфере и не является
                инициатором передачи информации.<br></br>
                ЗАКАЗЧИК (арендодатель) – любое дееспособное физическое лицо
                (или юридическое лицо), которое в порядке, установленном
                настоящим Договором, получил услугу доступа к Порталу и
                публикующее в соответствующем разделе Портала объявления с
                информацией об Имуществе, предлагаемом Пользователям к принятию
                в аренду.<br></br>
                Пользователь Портала (арендатор) – любое дееспособное физическое
                лицо (или юридическое лицо), принявшее условия пользовательского
                соглашения и взаимодействующее с Порталом.<br></br>
                Верификация (идентификация ЗАКАЗЧИКА) – совокупность мероприятий
                по установлению сведений о ЗАКАЗЧИКЕ, а именно: проверка
                ЗАКАЗЧИКА, которая осуществляется путем введения в
                соответствующее поле на странице верификации специального кода,
                отправленного Администрацией Портала в SMS/Viber-сообщении на
                номер и/или адрес электронной почты, указанные ЗАКАЗЧИКОМ при
                регистрации на Портале. При этом один номер мобильного телефона,
                адрес электронной почты и (или) один комплект иных
                идентификационных данных могут быть использованы для регистрации
                не более одной Учетной записи ЗАКАЗЧИКА на Портале.<br></br>
                Контент – любая информация, размещаемая Арендодателем на
                Портале, включая, но не ограничиваясь: название и описание
                Имущества, фотографии, характеристики, информация об
                Арендодателе и т.д.<br></br>
                Личный кабинет – это раздел Портала, функции которого доступны
                Пользователю после ввода логина-пароля. Также в Личном кабинете
                отображается хранимая на Портале информация о Пользователе.
                <br></br>
                Объявление – сообщение с информацией об Имуществе, предлагаемом
                в аренду, которое размещается Арендодателем.<br></br>
                Имущество – любая вещь, объект, движимое и недвижимое имущество,
                предлагаемые Арендодателем в аренду Пользователям.<br></br>
                Политика конфиденциальности – условия работы с конфиденциальной
                информацией на Портале, которая является неотъемлемой частью
                настоящего Соглашения и размещена по ссылке на сайте
                Razdelisdrugim.by.<br></br>
                Имущество – любая вещь, объект, движимое и недвижимое имущество,
                предлагаемые Арендодателем в аренду Пользователям.<br></br>
                Политика конфиденциальности – условия работы с конфиденциальной
                информацией на Портале, которая является неотъемлемой частью
                настоящего Договора и размещена по ссылке Razdelisdrugim.by.
                <br></br>
                Портал (платформа, площадка Razdelisdrugim.by) – совокупность
                программных и аппаратных средств, результат компьютерного
                программирования в виде онлайн сервиса (платформы)
                Razdelisdrugim.by, который размещен в сети Интернет по адресу
                https://razdelisdrugim.by (http://разделисдругим.бел).<br></br>
                Правила оформления рекламных объявлений на Портале – требования
                к оформлению Контента на Портале, которые являются неотъемлемой
                частью настоящего Соглашения и размещены по ссылке
                Razdelisdrugim.by.<br></br>
                Правила публикации отзывов – требования, которые должны
                соблюдать Пользователи при размещении и публикации оценок и
                отзывов о деятельности Арендодателей на Портале и их Имуществе,
                которые являются неотъемлемой частью настоящего Соглашения и
                размещены по ссылке Razdelisdrugim.by.
                <br></br>
                Сервисы – совокупность Услуг, которые предоставляются ЗАКАЗЧИКУ
                на Портале в соответствии с настоящим Договором.<br></br>
                Модерация — контроль со стороны ИСПОЛНИТЕЛЯ за информацией,
                которую размещает ЗАКАЗЧИК на Портале. <br></br>
                1.3. ЗАКАЗЧИК признает, что на момент заключения Договора
                ИСПОЛНИТЕЛЬ предоставил ему полную и достоверную информацию в
                отношении Услуг, оказываемых по Договору.<br></br>
                1.4. ЗАКАЗЧИК, заключая настоящий Договор, соглашается с тем,
                что ИСПОЛНИТЕЛЬ имеет право в любое время и без предварительного
                уведомления отказать в предоставлении Услуг, в случае, если
                такое их предоставление противоречит требованиям
                законодательства и (или) настоящего Договора.<br></br>
                1.5. Прейскуранты цен на Услуги ИСПОЛНИТЕЛЯ, Политика
                конфиденциальности, Правила оформления рекламных объявлений
                являются неотъемлемой частью настоящего Договора.<br></br>
                1.6. Местом заключения Договора является местонахождение
                ИСПОЛНИТЕЛЯ.
              </p>
              <h3>2. ПРЕДМЕТ ДОГОВОРА </h3>
              <p className="footer_pages_main_p">
                2.1. ИСПОЛНИТЕЛЬ обязуется по заявке ЗАКАЗЧИКА оказать
                последнему рекламные услуги в глобальной компьютерной сети
                Интернет с использованием площадки Razdelisdrugim.by (далее —
                Услуги), связанные с осуществлением ЗАКАЗЧИКОМ
                предпринимательской деятельности, а также иной, разрешенной
                законодательством Республики Беларусь деятельности, которая не
                является в соответствии с законодательством предпринимательской,
                в соответствии с Прейскурантом цен на Услуги ИСПОЛНИТЕЛЯ.
                Прейскурант цен опубликован на странице Razdelisdrugim.by.
                <br></br>
                2.2. Перечень Услуг, которые должны быть оказаны в рамках
                Договора, и иные условия, определяющие порядок оказания Услуг, а
                также другая информация, являющаяся существенной для оказания
                Услуг, определяются настоящим Договором.<br></br>
                2.3. ЗАКАЗЧИК принимает условия Договора в целом и обязуется
                выполнять условия Договора.<br></br>
                2.4. ЗАКАЗЧИК предоставляет ИСПОЛНИТЕЛЮ право получать, хранить,
                обрабатывать, использовать и раскрывать персональные данные
                ЗАКАЗЧИКА на условиях Политики конфиденциальности, являющейся
                неотъемлемой частью Договора.
              </p>
              <h3>3. ЗАКЛЮЧЕНИЕ ДОГОВОРА </h3>
              <p className="footer_pages_main_p">
                3.1. Заключение Договора производится путем присоединения
                ЗАКАЗЧИКА к Договору, размещенному на сайте ИСПОЛНИТЕЛЯ по
                адресу Razdelisdrugim.by, т.е. посредством принятия (акцепта)
                ЗАКАЗЧИКОМ условий Договора в целом, без каких-либо условий,
                изъятий и оговорок (в соответствии со ст. 398 Гражданского
                кодекса Республики Беларусь).<br></br>
                3.2. Фактом принятия (акцепта) ЗАКАЗЧИКОМ условий настоящего
                Договора является бесплатное заполнение регистрационной формы на
                информационной платформе Razdelisdrugim.by в порядке и на
                условиях, определенных настоящим Договором (пункт 3 статьи 408
                Гражданского Кодекса Республики Беларусь). Настоящий Договор,
                при условии соблюдения порядка его акцепта, считается
                заключенным в простой письменной форме (пункты 2, 3 статьи 404,
                пункт 3 статьи 408 Гражданского Кодекса Республики Беларусь) со
                дня создания ЗАКАЗЧИКОМ личного кабинета на информационной
                платформе Razdelisdrugim.by, позволяющего идентифицировать
                ЗАКАЗЧИКА.
              </p>
              <h3>4. ПОРЯДОК ОКАЗАНИЯ УСЛУГ, ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
              <p className="footer_pages_main_p">
                4.1. ИСПОЛНИТЕЛЬ предоставляет услуги, позволяющие ЗАКАЗЧИКУ
                бесплатно зарегистрироваться (создать Личный кабинет) на Портале
                и размещать рекламные Объявления с предложением Имущества в
                аренду.
                <br></br>
                4.2. После регистрации и успешной Верификации на Портале
                ЗАКАЗЧИК имеет возможность пользоваться всеми Сервисами Портала,
                включая размещение объявлений.<br></br>
                4.3. ЗАКАЗЧИК получает доступ к Личному кабинету в котором
                хранится информация о нем, история Объявлений и коммуникации с
                Пользователями, другая информация и с помощью которого он может
                публиковать Объявления, не заполняя повторно свои данные,
                общаться с Пользователями с помощью чата, определять условия и
                заключать сделки. ЗАКАЗЧИК имеет возможность в личном кабинете
                изменять (редактировать) свои объявления, удалять их, временно
                скрывать для поиска их иными пользователями, и показывать
                скрытые объявления.<br></br>
                4.4. ЗАКАЗЧИК может оставлять свои отзывы о Пользователях и их
                Имуществе, соблюдая при этом Правила публикации отзывов.
                <br></br>
                4.5. ИСПОЛНИТЕЛЬ не является стороной электронной сделки между
                ЗАКАЗЧИКОМ (Арендодателем) и Пользователем (Арендатором),
                предметом которой выступает передача в аренду Имущества,
                предлагаемого ЗАКАЗЧИКОМ на Портале. Все сделки между ЗАКАЗЧИКОМ
                и Пользователями заключаются напрямую. ИСПОЛНИТЕЛЬ не является
                участником таких соглашений, а лишь предоставляет
                коммуникационную площадку для размещения объявлений и другой
                рекламной информации. ИСПОЛНИТЕЛЬ не несет ответственности за
                содержание передаваемой или получаемой информации и за ущерб,
                причиненный в результате использования ЗАКАЗЧИКОМ результатов
                Сервисов Портала.
                <br></br>
                4.6. Обязанности ИСПОЛНИТЕЛЯ заключаются исключительно в
                обеспечении возможности получения ЗАКАЗЧИКОМ услуг в порядке,
                определенном настоящим Договором.
                <br></br>
                4.7. ИСПОЛНИТЕЛЬ оставляет за собой право по собственному
                усмотрению изменять или удалять любую информацию, которая
                публикуется ЗАКАЗЧИКОМ на Портале, приостанавливать,
                ограничивать или прекращать доступ ЗАКАЗЧИКУ к Сервисам Портала
                в любое время и без объяснения причин. Пользователя.<br></br>
                4.8. ИСПОЛНИТЕЛЬ вправе устанавливать какие-либо ограничения в
                использовании Сервисов ЗАКАЗЧИКАМИ.<br></br>
                4.9. В случае нарушения ЗАКАЗЧИКОМ условий настоящего Договора,
                ИСПОЛНИТЕЛЬ имеет право приостановить, ограничить или прекратить
                доступ данному ЗАКАЗЧИКУ к любому из Сервисов в одностороннем
                порядке в любое время, без дополнительного уведомления. При
                этом, ИСПОЛНИТЕЛЬ не несет ответственности за любой ущерб,
                который может быть нанесен такими действиями.<br></br>
                4.10. ИСПОЛНИТЕЛЬ имеет право осуществлять рассылки ЗАКАЗЧИКАМ
                сообщений (коммерческих предложений), в том числе электронных
                сообщений на адреса предоставленной ЗАКАЗЧИКАМИ электронной
                почты, телефонных SMS сообщений или сообщений посредствам
                мессенджеров Viber, WhatsAPP, Telegram, на предоставленные
                номера мобильных телефонов, содержащие
                организационно-техническую, информационную, маркетинговую или
                другую информацию о возможностях Сервисов на Портале,
                деятельности ИСПОЛНИТЕЛЯ или его партнеров. ЗАКАЗЧИК в любой
                момент может отказаться от получения новостной рассылки.
                ЗАКАЗЧИК не имеет возможности отказаться от рассылки
                информационных сообщений, связанных с изменением в организации
                работы Портала, внесением изменений в настоящий Договор, а также
                связанных с передачей Имущества ЗАКАЗЧИКА в аренду (в том числе
                информация о бронировании Имущества, сообщения от Пользователей
                Портала и пр.).<br></br>
                4.11. ИСПОЛНИТЕЛЬ обязуется не использовать данные ЗАКАЗЧИКА,
                полученные при регистрации в целях, не предусмотренных настоящим
                Договором и приложениями к нему, и гарантирует неразглашение
                этих данных, кроме случаев, когда раскрытие такой информации
                является обязанностью Портала в силу законодательства Республики
                Беларусь.<br></br>
                4.12. ИСПОЛНИТЕЛЬ обязуется предоставлять ЗАКАЗЧИКУ возможность
                получения консультаций службы поддержки в случае указания
                ЗАКАЗЧИКОМ идентификационных данных. Объем консультаций
                ограничивается конкретными вопросами, связанными с
                предоставлением Сервисов. ИСПОЛНИТЕЛЬ может по своему усмотрению
                требовать от ЗАКАЗЧИКА оформления запроса в службу поддержки по
                электронной почте с адреса, указанного ЗАКАЗЧИКОМ при
                регистрации и/или указанного в его Личном кабинете.<br></br>
                4.13. ИСПОЛНИТЕЛЬ вправе осуществлять Модерацию всего Контента и
                Объявлений на соответствие их требованиям настоящего Договора,
                Правил размещения объявлений, Правил публикации отзывов и
                действующего законодательства Республики Беларусь, и в случае
                выявленных нарушений в любое время по своему усмотрению удалять
                такую информацию с Портала без объяснения причин.<br></br>
                4.14. ИСПОЛНИТЕЛЬ оставляет за собой право удалять с Портала и
                из собственных серверов любую информацию или материалы, которые,
                по ее мнению, являются неприемлемыми, незаконными,
                недостоверными, дискриминационными, нарушающими этические,
                гуманистические, моральные нормы, пренебрегают правилами
                приличия, и т.п. или нарушают условия настоящего Договора.
                <br></br>
                4.15. ИСПОЛНИТЕЛЬ имеет право отказаться от предоставления услуг
                ЗАКАЗЧИКУ, в одностороннем порядке отказавшись от исполнения
                договора (без дополнительного предупреждения об этом ЗАКАЗЧИКА),
                в случае нарушения ЗАКАЗЧИКОМ Правил размещения объявлений и/или
                Правил публикации отзывов.<br></br>
                4.16. Во время регистрации и/или заполнения формы подачи
                Объявления ИСПОЛНИТЕЛЬ должен предоставить правдивую, точную и
                полную информацию о себе по вопросам, которые предлагаются в
                форме для регистрации и в формах разделов Портала и поддерживать
                эту информацию в актуальном состоянии, которое соответствует
                действительности.<br></br>
                4.17. В случае предоставления недостоверной или неполной
                информации, ЗАКАЗЧИК имеет право приостановить или отменить
                регистрацию ЗАКАЗЧИКА и прекратить предоставление ему Сервисов.
                <br></br>
                4.18. ЗАКАЗЧИК обязуется использовать Портал только в законных
                целях, соблюдать действующее законодательство Республики
                Беларусь, а также права и законные интересы ИСПОЛНИТЕЛЯ. Не
                допускается размещение ЗАКАЗЧИКОМ на Портале сообщений и
                материалов, содержащих информацию, распространение которой
                запрещено законодательством Республики Беларусь.<br></br>
                4.19. ЗАКАЗЧИК не имеет права совершать действий, которые влияют
                на нормальную работу Портала и является его недобросовестным
                использованием.<br></br>
                4.20. ЗАКАЗЧИК обязуется не осуществлять действий, направленных
                на получение доступа к чужому личному кабинету, путем подбора
                логина и пароля, взлома или иных действий. <br></br>
                4.21. ЗАКАЗЧИК не имеет права передавать, уступать, продавать,
                передавать в пользование и т.п. свои логин и пароль для доступа
                к Порталу и Сервисам третьим лицам без согласия ИСПОЛНИТЕЛЯ.{" "}
                <br></br>
                4.22. В случае нарушения безопасности или несанкционированного
                использования Учетной записи ЗАКАЗЧИКА, он должен немедленно
                сообщить об этом ИСПОЛНИТЕЛЮ. ИСПОЛНИТЕЛЬ не несет
                ответственности за любые убытки, вызванные несанкционированным
                использованием Личного кабинета ЗАКАЗЧИКА. <br></br>
                4.23. ЗАКАЗЧИК обязуется не использовать самостоятельно или с
                привлечением третьих лиц возможности Портала в целях, которые
                могут быть квалифицированы как нарушение прав третьих лиц на
                объекты интеллектуальной собственности, недобросовестная
                конкуренция, иное нарушение действующего законодательства
                Республики Беларусь.<br></br>
                4.24. ЗАКАЗЧИК гарантирует, что обладает всеми правами на
                использование материалов и Контента, размещаемых ими на Портале
                и в Объявлениях, в т.ч. правами интеллектуальной собственности.
                <br></br>
                4.25. ЗАКАЗЧИК не может создавать, отправлять, передавать,
                размещать или публиковать любую информацию на Портале и в
                Объявлениях об Имуществе, которое не имеет права рекламировать
                или предоставлять в аренду в соответствии с законодательством
                Республики Беларусь. В случае, если в соответствии с действующим
                законодательством, реклама и/или передача в аренду определенного
                имущества запрещена, ЗАКАЗЧИК самостоятельно несёт полную
                ответственность за соблюдение такого законодательства при
                размещении им такой информации на Портале. <br></br>
                4.26. Если ИСПОЛНИТЕЛЬ будет считать или обнаружит, что
                ЗАКАЗЧИКОМ нарушаются права Портала или права других
                пользователей или третьих лиц любым способом, указанным выше, а
                также в случае наличия в действиях ЗАКАЗЧИКА признаков
                мошенничества, ИСПОЛНИТЕЛЬ по своему усмотрению может принять
                меры для предотвращения такого нарушения, поскольку своими
                действиями ЗАКАЗЧИК наносит вред деловой репутации Портала.
                Такими мерами могут быть: ограничение, приостановление или
                прекращение доступа к Личному кабинету ЗАКАЗЧИКА и доступа к
                Сервисам Портала, удаление размещенного ЗАКАЗЧИКОМ Контента,
                Объявлений и отзывов, уменьшение размера любых скидок на услуги
                Портала (или их отмены), применение других технических,
                юридических и других, не запрещенных законодательством мер,
                связанных с ограничением услуг Портала, которые предоставляются
                ЗАКАЗЧИКУ, допустившему нарушение.<br></br>
                4.27. Публикация информации (Контента, в том числе в
                Объявлениях) на Портале должна осуществляться ЗАКАЗЧИКОМ в
                полном соответствии с требованиями действующего законодательства
                Республики Беларусь, положений настоящего Договора.<br></br>
                4.28. Предложение ЗАКАЗЧИКА об аренде товара, которое
                публикуется им на Портале, должно содержать всю предусмотренную
                законодательством Республики Беларусь (в частности, но не
                исключительно, Законом Республики Беларусь «О защите прав
                потребителей») информацию для потребителей.<br></br>
                4.29. ИСПОЛНИТЕЛЬ не несет ответственности за использование (как
                правомерное, так и неправомерное) третьими лицами информации,
                размещенной на Портале, включая ее воспроизведение и
                распространение, осуществленные как в рамках Портала, так и
                другими возможными способами.<br></br>
                4.30. ИСПОЛНИТЕЛЬ оставляет за собой право в одностороннем
                порядке удалять размещенный ЗАКАЗЧИКОМ Контент по требованию от
                правообладателей или компетентных государственных органов, а
                также в случае, если размещенный Контент нарушает права (в т.ч.
                права интеллектуальной собственности) третьих лиц или не
                соответствует по мнению ИСПОЛНИТЕЛЯ принципам общественной
                морали.<br></br>
                4.31. В случае возникновения в связи с размещением ЗАКАЗЧИКОМ
                Контента и/или любой информации, рекламных материалов, и/или
                содержанием рекламных материалов (соответствием содержания
                рекламы требованиям действующего законодательства) и/или
                качеством услуг, которые рекламируются, претензий, споров,
                применения штрафных санкций со стороны третьих лиц и/или
                контролирующих органов или по решению суда, такой ЗАКАЗЧИК
                обязуется рассматривать и решать эти вопросы самостоятельно без
                привлечения ИСПОЛНИТЕЛЯ, а также обязуется компенсировать
                ИСПОЛНИТЕЛЮ в полном объеме убытки, расходы, включая штрафные
                санкции, вызванные нарушением им условий настоящего Договора.
                <br></br>
                4.32. Вся размещенная ЗАКАЗЧИКОМ на Портале информация считается
                собственностью ЗАКАЗЧИКА, который ее разместил, до тех пор, пока
                не будет оснований считать иначе. Всю ответственность за
                соответствие такой информации (Контента) несет ЗАКАЗЧИК.
                ЗАКАЗЧИК не получает никаких прав на использование информации,
                размещенной другим ЗАКАЗЧИКОМ и несёт полную ответственность
                перед владельцем информации за ее неправомерное использование.
                <br></br>
                4.33. ЗАКАЗЧИК обязан добросовестно выполнять принятые на себя
                обязательства перед Пользователями Портала. <br></br>
                4.34. ИСПОЛНИТЕЛЬ не несет ответственности за любые ошибки,
                упущения, прерывания, дефекты и задержки в обработке или
                передаче данных, сбои в линиях связи, уничтожения любого
                оборудования, неправомерный доступ третьих лиц к Порталу,
                ставших причиной ограничения доступа ЗАКАЗЧИКУ к Сервисам.
                ИСПОЛНИТЕЛЬ не несет ответственности за любые технические сбои
                или иные проблемы любых телефонных сетей или служб, компьютерных
                систем, серверов или провайдеров, компьютерного или телефонного
                оборудования, программного обеспечения, сбоев сервисов
                электронной почты или скриптов по техническим причинам, за
                нормальное функционирование и доступность отдельных сегментов
                сети Интернет и сетей операторов электросвязи, задействованных
                при осуществлении доступа ЗАКАЗЧИКУ к Сервисам.<br></br>
                4.35. Услуги на Портале предоставляются «как есть». ИСПОЛНИТЕЛЬ
                не гарантирует безусловного сохранения Учетной записи ЗАКЗЧИКА и
                размещенной ЗАКАЗЧИКОМ информации на Портале.<br></br>
                4.36. ИСПОЛНИТЕЛЬ не несет ответственности за соответствие
                Сервиса целиком или его частей ожиданиям ЗАКАЗЧИКА, безошибочное
                и бесперебойное предоставление Сервисов, прекращение доступа
                ЗАКАЗЧИКА к Сервисам, а также за сохранность логина и пароля
                ЗАКАЗЧИКА, обеспечивающие доступ к Сервисам, по причинам,
                связанным с техническими сбоями аппаратного или программного
                обеспечения Портала, и не возмещает ЗАКАЗЧИКУ любые связанные с
                этим убытки.<br></br>
                4.37. При нарушении ЗАКАЗЧИКОМ условий настоящего Договора
                ИСПОЛНИТЕЛЬ оставляет за собой право ограничить доступ ЗАКАЗЧИКА
                к Сервисам Портала на временной основе, а в случае грубого и/или
                неоднократного нарушения условий настоящего Договора – отказать
                в доступе к Сервисам и в предоставлении услуг на постоянной
                основе.<br></br>
                4.38. ИСПОЛНИТЕЛЬ не несет ответственности перед ЗАКАЗЧИКОМ за
                ограничение доступа к Сервисам, за прекращение доступа к
                Сервисам, если эти ограничения и прекращения возникли вследствие
                обстоятельств непреодолимой силы, возникших после заключения
                настоящего Договора и на наступление которых Стороны не могли
                повлиять, включая, но не ограничиваясь, следующим: война,
                мятежи, забастовки, саботаж, эмбарго, пожары, наводнения,
                стихийные бедствия, ухудшение радиоэлектронной или
                радиологической обстановки, взрывы, действия или бездействие
                государственных органов Республики Беларусь или другой страны,
                акты государственных органов и/или органов местного
                самоуправления, внесение изменений в законодательство Республики
                Беларусь, аварии на сетях общего пользования, изменения условий
                доступа к линейно-кабельным средствам связи и прочее.<br></br>
                4.39. ИСПОЛНИТЕЛЬ не несет ответственности перед ЗАКАЗЧИКОМ или
                любыми третьими лицами за любые прямые и/или косвенные убытки,
                включая упущенную выгоду или потерянные данные, вред чести,
                достоинству или деловой репутации, понесенные в связи с
                использованием им Сервисов, или невозможности его использования
                или несанкционированного доступа к коммуникациям ЗАКАЗЧИКА
                третьих лиц.<br></br>
                4.40. ИСПОЛНИТЕЛЬ не несет ответственности за любые убытки
                электронным устройствам ЗАКАЗЧИКА или иного лица, любого другого
                оборудования или программного обеспечения, вызванные или
                связанные с использованием ЗАКАЗЧИКОМ Сервисов.<br></br>
                4.41. ИСПОЛНИТЕЛЬ ни при каких обстоятельствах не будет нести
                ответственность за любой ущерб или убытки, прямо или косвенно
                возникшие у ЗАКАЗЧИКА или в результате деятельности
                Пользователей на Портале. <br></br>
                4.42. Совокупность программ, данных, торговых марок, объектов
                авторского права, и других объектов, используемых на Портале при
                предоставлении Сервисов, является интеллектуальной
                собственностью его законных правообладателей и охраняется
                законодательством об интеллектуальной собственности Республики
                Беларусь, а также соответствующими международными договорами и
                конвенциями. Любое использование элементов, символики, текстов,
                графических изображений, программ и других объектов, входящих в
                состав Сервиса и принадлежащих Порталу, кроме разрешенного в
                настоящем Договоре, без разрешения ИСПОЛНИТЕЛЯ или иного
                законного правообладателя является незаконным и может стать
                причиной судебного разбирательства и привлечения нарушителей к
                гражданско-правовой, административной и уголовной
                ответственности в соответствии с законодательством Республики
                Беларусь.
              </p>
              <h3>5. СТОИМОСТЬ УСЛУГ И УСЛОВИЯ ОПЛАТЫ </h3>
              <p className="footer_pages_main_p">
                5.1. Стоимость Услуг, оказываемых ИСПОЛНИТЕЛЕМ по Договору,
                определяется в соответствии с действующим прейскурантом цен
                ИСПОЛНИТЕЛЯ.<br></br>
                5.2. Оказание Услуг, предоставляемых ЗАКАЗЧИКУ согласно
                настоящему Договору, осуществляется на условиях 100%
                предварительной оплаты Услуг.<br></br>
                5.3. ЗАКАЗЧИК осуществляет оплату Услуг путем:<br></br>-
                безналичного денежного перевода;
                <br></br>- с помощью пластиковой банковской карты;
                <br></br>- посредством СМС-сообщения;
                <br></br>- перевода посредством ЕРИП.
                <br></br>
                5.4. Размер поступившей предоплаты за услуги отображается в
                личном кабинете ЗАКАЗЧИКА как белорусские рубли.<br></br>
                5.5. В последующем ИСПОЛНИТЕЛЕМ производится списание суммы с
                баланса ЗАКАЗЧИКА в размере стоимости Услуг оказанных
                ИСПОЛНИТЕЛЕМ.<br></br>
                5.6. Тарифы на оказание услуг по размещению на Портале приведены
                тут:{" "}
                <a href="https://razdelisdrugim.by/delivery-and-payment">
                  https://razdelisdrugim.by/delivery-and-payment
                </a>
              </p>
              <h3>6. ПОРЯДОК СДАЧИ-ПРИЕМКИ </h3>
              <p className="footer_pages_main_p">
                6.1. Оказание Услуг ЗАКАЗЧИКУ в рамках Договора подтверждается
                Актом об оказании услуг. Отчетным периодом оказания услуг
                является месяц. Руководствуясь п. 6 ст. 10 Закона Республики
                Беларусь от 12.07.2013 № 57-З «О бухгалтерском учете и
                отчетности» и постановлением Министерства финансов Республики
                Беларусь от 12.02.2018 г. № 13 «О единоличном составлении
                первичных учетных документов», ИСПОЛНИТЕЛЬ единолично составляет
                Акты об оказании услуг и направляет их ЗАКАЗЧИКУ до 20 числа
                месяца, следующего за отчетным. ЗАКАЗЧИК вправе составить
                первичные учетные документы, подтверждающие оказание Услуг по
                Договору, единолично на основании Акта об оказании услуг,
                ежемесячно составляемого ИСПОЛНИТЕЛЕМ и направляемого ЗАКАЗЧИКУ.
                <br></br>
                6.2. ЗАКАЗЧИК безоговорочно соглашается с тем, что если он в
                течение десяти календарных дней с момента составления
                ИСПОЛНИТЕЛЕМ единолично Акта об оказании услуг по настоящему
                Договору не заявит каких-либо письменных претензий по оказанным
                ИСПОЛНИТЕЛЕМ Услугам, это будет рассматриваться как однозначное
                согласие ЗАКАЗЧИКА с тем, что Услуги оказаны ИСПОЛНИТЕЛЕМ
                своевременно, в полном объеме и надлежащим образом.
              </p>
              <h3>7. ГАРАНТИИ</h3>
              <p className="footer_pages_main_p">
                7.1. ИСПОЛНИТЕЛЬ гарантирует, что обладает всеми правами и
                полномочиями, необходимыми для заключения и исполнения Договора.
                <br></br>
                7.2. ЗАКАЗЧИК, соглашаясь с условиями и принимая условия
                Договора, гарантирует ИСПОЛНИТЕЛЮ, что:<br></br>
                7.2.1. ЗАКАЗЧИК указал достоверные данные, в том числе
                персональные данные ЗАКАЗЧИКА, при регистрации в качестве
                пользователя на портале Razdelisdrugim.by и достоверные данные,
                в том числе персональные данные ЗАКАЗЧИКА, при оформлении
                платежных документов по оплате Услуг.<br></br>
                7.2.2. В организации своей деятельности посредством
                использования Услуг ИСПОЛНИТЕЛЯ ЗАКАЗЧИК соблюдает нормы
                законодательства Республики Беларусь в части, касающейся
                национального сегмента сети Интернет, рекламы, торговли, защиты
                прав потребителей.<br></br>
                7.2.3. Информация и сведения рекламного характера, размещенные
                на портале Razdelisdrugim.by по инициативе и в соответствии с
                пожеланиями ЗАКАЗЧИКА, являются полными, достоверными, не
                нарушают авторских прав, прав интеллектуальной собственности
                третьих лиц, других прав третьих лиц и соответствуют требованиям
                законодательства Республики Беларусь, в том числе связанным с
                соблюдением норм законодательства о рекламе, торговле, об
                ограничении недобросовестной конкуренции, антимонопольного
                законодательства, о защите прав потребителей, а также иным
                требованиям законодательства Республики Беларусь.
                Ответственность за несоответствие информации рекламного
                характера требованиям, указанным в настоящем пункте, несет
                ЗАКАЗЧИК.<br></br>
                7.2.4. ЗАКАЗЧИК заключает Договор добровольно, при этом ЗАКАЗЧИК
                полностью ознакомился с условиями Договора и его неотъемлемых
                частей и полностью понимает и признает его предмет.
              </p>
              <h3>8.ОТВЕТСТВЕННОСТЬ И ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ </h3>
              <p className="footer_pages_main_p">
                8.1. За нарушение условий Договора Стороны несут
                ответственность, установленную Договором и законодательством
                Республики Беларусь.
                <br></br>
                8.2. ЗАКАЗЧИК в полном объеме несет ответственность за:
                <br></br>
                8.2.1. соблюдение всех требований законодательства, в том числе
                законодательства о национальном сегменте сети Интернет, о
                рекламе, об интеллектуальной собственности, о конкуренции, о
                защите прав потребителей, но не ограничиваясь перечисленным, в
                отношении содержания и формы материалов, размещаемых на портале
                Razdelisdrugim.by;<br></br>
                8.2.2. достоверность сведений, указанных ЗАКАЗЧИКОМ при
                регистрации в качестве пользователя на портале
                Razdelisdrugim.by.
                <br></br>
                8.3. ИСПОЛНИТЕЛЬ не несет ответственности за содержание
                информации, размещаемой ЗАКАЗЧИКОМ на портале Razdelisdrugim.by.
                <br></br>
                8.4. ИСПОЛНИТЕЛЬ не несет ответственности и не дает каких-либо
                гарантий (в том числе гарантий соблюдения прав или пригодности
                для конкретных целей) на любую информацию, товар или услугу,
                распространяемые ЗАКАЗЧИКОМ или третьими лицами через сеть
                Интернет, в том числе, если они размещены, предлагаются или
                распространяются на портале Razdelisdrugim.by.
                <br></br>
                8.5. ЗАКАЗЧИК обязуется своими силами и за свой счет разрешать
                споры и урегулировать претензии третьих лиц в отношении
                размещаемых материалов, а также возместить убытки (включая
                судебные расходы), причиненные ИСПОЛНИТЕЛЮ в связи с претензиями
                и исками, основанием предъявления которых явилось размещение
                материалов ЗАКАЗЧИКА.
                <br></br>
                8.6. ЗАКАЗЧИК самостоятельно несет полную ответственность за
                любые действия, предпринятые им в процессе использования сети
                Интернет, информационных ресурсов или услуг ИСПОЛНИТЕЛЯ, а также
                за последствия таких действий.<br></br>
                8.7. ЗАКАЗЧИК самостоятельно несет полную ответственность за
                любые, в том числе и несанкционированные, действия третьих лиц,
                имевших место вследствие несоблюдения ЗАКАЗЧИКОМ
                конфиденциальности своих учетных данных или иной информации
                закрытого характера, а также за последствия таких действий.
                <br></br>
                8.8. ЗАКАЗЧИК самостоятельно несет полную ответственность за
                возможные нарушения авторского права, прав на товарные знаки и
                иных прав интеллектуальной собственности, иных норм
                законодательства Республики Беларусь, связанных с фактом
                размещения информации на портале Razdelisdrugim.by.
              </p>
              <h3>9. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ </h3>
              <p className="footer_pages_main_p">
                9.1. Стороны освобождаются от ответственности за частичное или
                полное неисполнение своих обязательств по Договору, если это
                явилось следствием действия обстоятельств непреодолимой силы
                (форс-мажора), возникших после заключения Договора в результате
                событий чрезвычайного характера, которые Стороны не могли ни
                предвидеть, ни предотвратить разумными мерами.<br></br>
                9.2. К обстоятельствам непреодолимой силы относятся события, на
                которые Сторона не может оказывать влияния и за возникновение
                которых она не несет ответственности, как то: война, восстание,
                забастовка, землетрясение, наводнение, пожар, суровые погодные
                условия или другие стихийные бедствия, нормативные правовые акты
                и (или) действия органов государственной власти и управления,
                принятые (совершенные) после акцепта настоящего Договора и
                делающие невозможным исполнение обязательств, установленных
                настоящим Договором, или препятствующие выполнению условий
                настоящего Договора, другие непредвиденные обстоятельства, в том
                числе неполадки в городской электросети, технические проблемы на
                транзитных узлах сети Интернет и прочие нарушения
                функционирования сетей передачи данных, находящихся вне сферы
                влияния Сторон, но не ограничиваясь указанным.<br></br>
                9.3. При наступлении обстоятельств непреодолимой силы,
                препятствующих исполнению обязательств по настоящему Договору,
                срок выполнения Сторонами таких обязательств переносится
                соразмерно времени действия таких обстоятельств, а также
                времени, требуемого для устранения их последствий, но не более
                шестидесяти календарных дней.<br></br>
                9.4. В случае если обстоятельства непреодолимой силы продолжают
                действовать более срока, указанного в п. 9.3 настоящего
                Договора, либо когда при их наступлении обеим Сторонам
                становится очевидным, что обстоятельства будут действовать более
                этого срока, Стороны обязуются обсудить возможности
                альтернативных способов исполнения настоящего Договора или его
                прекращения без возмещения убытков.
              </p>
              <h3>10. ИЗВЕЩЕНИЯ И УВЕДОМЛЕНИЯ </h3>
              <p className="footer_pages_main_p">
                10.1. Стороны направляют документы, извещения и уведомления
                почтовой (курьерской) связью, нарочным, а также признают
                юридическую силу документов, полученных по электронной почте,
                наравне с документами, исполненными в письменной форме на
                бумажном носителе. Все документы, извещения и уведомления должны
                быть подписаны руководителем или надлежащим образом
                уполномоченным лицом. Также стороны признают юридическую силу
                документов, подписанных электронной цифровой подписью.
                <br></br>
                10.2. Стороны соглашаются с тем, что все документы, направленные
                на адреса электронной почты, считаются доставленными адресату в
                надлежащей форме. В своём Личном кабинете ЗАКАЗЧИК имеет
                возможность изменить контактный адрес электронной почты.
                <br></br>
                10.3. Стороны обязаны своевременно проверять поступающую в их
                адрес корреспонденцию. Все риски, связанные с наступлением
                неблагоприятных последствий вследствие несоблюдения требований
                настоящего пункта Договора, несет Сторона, допустившая такое
                нарушение.
              </p>
              <h3>11. СРОК ДЕЙСТВИЯ, ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА </h3>
              <p className="footer_pages_main_p">
                11.1. Договор размещается в сети Интернет по адресу:
                Razdelisdrugim.by ИСПОЛНИТЕЛЬ имеет право в одностороннем
                порядке внести изменения в Договор в любой момент по своему
                усмотрению. В случае внесения ИСПОЛНИТЕЛЕМ изменений в Договор,
                такие изменения вступают в силу в день размещения измененного
                текста Договора в сети Интернет, если иной срок вступления
                изменений в силу не определен самим Договором.<br></br>
                11.2. В случае своего несогласия с внесенными изменениями и/или
                дополнениями ЗАКАЗЧИК имеет право расторгнуть Договор путем
                направления письменного уведомления (подписанного руководителем
                или надлежащим образом уполномоченным лицом, физическим лицом, в
                случае регистрации на Портале в качестве физического лица) об
                одностороннем отказе от исполнения Договора полностью в адрес
                ИСПОЛНИТЕЛЯ.<br></br>
                11.3. Стороны безоговорочно соглашаются с тем, что молчание
                (отсутствие письменных уведомлений о расторжении Договора либо о
                несогласии с отдельными положениями Договора, в том числе с
                изменением Прейскуранта цен) признается согласием и
                присоединением ЗАКАЗЧИКА к новой редакции Договора (пункт 3
                статьи 159 Гражданского кодекса Республики Беларусь).<br></br>
                11.4. Договор может быть расторгнут:
                <br></br>
                11.4.1. По соглашению Сторон в любое время.
                <br></br>
                11.4.2. ЗАКАЗЧИКОМ путем направления письменного уведомления
                ИСПОЛНИТЕЛЮ (подписанного руководителем или надлежащим образом
                уполномоченным лицом, физическим лицом, в случае регистрации на
                портале в качестве физического лица) об одностороннем отказе от
                исполнения Договора полностью либо частично за 5 (пять) рабочих
                дней до предполагаемой даты расторжения или изменения (в случае
                частичного отказа) Договора.
                <br></br>
                11.4.3. ИСПОЛНИТЕЛЕМ путем направления в порядке, установленном
                разделом 9 настоящего Договора, письменного уведомления
                ЗАКАЗЧИКУ (подписанного руководителем или надлежащим образом
                уполномоченным лицом) об одностороннем отказе от исполнения
                Договора полностью либо частично. Договор в данном случае
                считается расторгнутым (в случае отказа полностью) или
                измененным (в случае отказа частично) в дату, указанную в
                уведомлении (срок определяется и указывается ИСПОЛНИТЕЛЕМ
                самостоятельно). Отказ от исполнения договора (как полностью,
                так и частично) может быть заявлен ИСПОЛНИТЕЛЕМ как в связи, так
                и вне зависимости от нарушения условий Договора.
                <br></br>
                11.4.4. ИСПОЛНИТЕЛЕМ путем направления письменного уведомления
                ЗАКАЗЧИКУ (подписанного руководителем или надлежащим образом
                уполномоченным лицом) об одностороннем отказе от исполнения
                Договора полностью за 1 (один) рабочий день (если больший срок
                не указан в уведомлении) до предполагаемой даты расторжения
                Договора в случае нарушения ЗАКАЗЧИКОМ законодательства о
                национальном сегменте сети Интернет, о рекламе, о торговле, о
                защите прав потребителей, иного законодательства, а также в
                случае предоставления ИСПОЛНИТЕЛЮ компетентными
                (контролирующими) государственными органами Республики Беларусь
                предписания (требования, иного акта, документа) о прекращения
                оказания Услуг ЗАКАЗЧИКУ (временном либо постоянном) по причине
                нарушения ЗАКАЗЧИКОМ законодательства либо об устранении
                ИСПОЛНИТЕЛЕМ нарушений, возникших вследствие несоблюдения
                ЗАКАЗЧИКОМ законодательства.
                <br></br>
                11.5. При расторжении Договора ИСПОЛНИТЕЛЕМ (кроме случаев,
                когда такое расторжение обусловлено нарушением ЗАКАЗЧИКОМ
                условий Договора), ИСПОЛНИТЕЛЬ возвращает ЗАКАЗЧИКУ стоимость
                оплаченных, но не оказанных услуг.
                <br></br>
                11.6. В случае расторжения Договора ИСПОЛНИТЕЛЕМ в связи с
                нарушением ЗАКАЗЧИКОМ условий Договора либо в случае расторжения
                Договора по инициативе ЗАКАЗЧИКА, денежные средства,
                перечисленные ЗАКАЗЧИКОМ в счет оплаты Услуг, не возвращаются.
              </p>
              <h3>12. 12. ПРОЧИЕ УСЛОВИЯ </h3>
              <p className="footer_pages_main_p">
                12.1. Все споры и разногласия, связанные с Договором, Стороны
                обязуются разрешать путем переговоров, в том числе,
                осуществляемые путем переписки по электронной почте.<br></br>
                12.2. В случае если Сторонам не удастся разрешить все спорные
                вопросы путем переговоров, все споры, возникающие из Договора, в
                том числе связанные с его заключением, изменением, расторжением,
                исполнением, недействительностью подлежат разрешению в судебном
                порядке в соответствии с законодательством Республики Беларусь.
                <br></br>
                12.3. До обращения в суд обязателен претензионный (досудебный)
                порядок урегулирования спора.<br></br>
                12.3.1. Претензия подписывается заявителем претензии или его
                представителем и направляется получателю претензии заказной
                корреспонденцией с обратным уведомлением (или иным видом
                отправления с подтверждением получения другой стороной,
                например, почтовым отправлением с описью вложения, бандеролью,
                курьерской почтой) или вручается под роспись.<br></br>
                12.3.2. Получатель претензии в месячный срок со дня ее получения
                письменно уведомляет заявителя претензии о результатах
                рассмотрения претензии. Ответ на претензию (подписанный
                руководителем или надлежащим образом уполномоченным лицом)
                направляется получателем претензии заявителю либо заказной
                корреспонденцией с обратным уведомлением (или иным видом
                отправления с подтверждением получения другой стороной,
                например, почтовым отправлением с описью вложения, бандеролью,
                курьерской почтой), либо вручается под роспись.<br></br>
                12.4. В случае, если какое-либо из условий Договора утрачивает
                юридическую силу или будет исключено из Договора, это не влечет
                недействительность остальных условий Договора, которые сохраняют
                юридическую силу и являются обязательными для исполнения
                Сторонами.
              </p>
              <h3>13. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ </h3>
              <p className="footer_pages_main_p">
                Индивидуальный предприниматель Клементьева Ольга Анатольевна
                <br></br>
                Адрес: Республика Беларусь, Минский район, аг. Вишневка, ул.
                Школьная, д. 12А<br></br>
                УНП 692190483<br></br>
                тел. +375291587752<br></br>
                Telegram: @razdelisdrugim<br></br>
                Контакты для связи с исполнителем приведены тут:
                <a href="https://razdelisdrugim.by/contacts">
                  https://razdelisdrugim.by/contacts
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OfferAgreement;
