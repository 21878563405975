import React from "react";
import Requests from "../../http/axios-requests";
import { ItemCard } from "../index";
import Carousel from "react-elastic-carousel";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const PrimaryCardsBlock = () => {
  const [items, setItems] = React.useState(false);

  React.useEffect(() => {
    Requests.getRandomItems().then((res) => setItems(res.data));
  }, []);

  const carouselRef = React.useRef(null);
  const totalPages = 7;

  const carouselRefMobile = React.useRef(null);
  const totalPagesMobile = 9;

  const carouselRefTablet = React.useRef(null);

  const carouselRef1024 = React.useRef(null);

  let resetTimeout;

  return (
    <section className="popular-wrapper">
      <h2 className="popular-p">Обратите внимание</h2>
      <div className="recent-blocks-wrapper">
        <div className="recent-blocks-slider-container">
          {window.screen.width > 1024 && items && (
            <Carousel
              itemsToShow={4}
              pagination={false}
              enableAutoPlay
              autoPlaySpeed={3000}
              preventDefaultTouchmoveEvent={true}
              enableSwipe={false}
              ref={carouselRef}
              onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                if (index + 1 === totalPages) {
                  resetTimeout = setTimeout(() => {
                    carouselRef.current.goTo(0);
                  }, 1500); // same time
                }
              }}
            >
              {items &&
                items.map((item, index) => (
                  <ItemCard key={index} item={item} />
                ))}
            </Carousel>
          )}

          {/* копия для адаптива  480*/}
          {items && (
            <div id="swiper_mobile">
              <Carousel
                itemsToShow={2}
                pagination={false}
                enableAutoPlay
                autoPlaySpeed={3000}
                showArrows={false}
                id="swiper_mobile"
                ref={carouselRefMobile}
                onNextEnd={({ index }) => {
                  clearTimeout(resetTimeout);
                  if (index + 1 === totalPages) {
                    resetTimeout = setTimeout(() => {
                      carouselRefMobile.current.goTo(0);
                    }, 1500); // same time
                  }
                }}
              >
                {items &&
                  items.map((item, index) => (
                    <ItemCard key={index} item={item} />
                  ))}
              </Carousel>
            </div>
          )}

          {/* копия для адаптива  800*/}
          {items && (
            <div id="swiper_mobile_768">
              <Carousel
                itemsToShow={3}
                pagination={false}
                enableAutoPlay
                autoPlaySpeed={3000}
                showArrows={false}
                ref={carouselRefTablet}
                onNextEnd={({ index }) => {
                  clearTimeout(resetTimeout);
                  if (index + 1 === totalPages) {
                    resetTimeout = setTimeout(() => {
                      carouselRefTablet.current.goTo(0);
                    }, 1500); // same time
                  }
                }}
              >
                {items &&
                  items.map((item, index) => (
                    <ItemCard key={index} item={item} />
                  ))}
              </Carousel>
            </div>
          )}

          {/* копия для адаптива  1024*/}
          {window.screen.width >= 1024 && window.screen.width < 1280 && items && (
            <Carousel
              itemsToShow={4}
              pagination={false}
              enableAutoPlay
              autoPlaySpeed={3000}
              showArrows={false}
              ref={carouselRef1024}
              onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                if (index + 1 === totalPages) {
                  resetTimeout = setTimeout(() => {
                    carouselRef1024.current.goTo(0);
                  }, 1500); // same time
                }
              }}
            >
              {items &&
                items.map((item, index) => (
                  <ItemCard key={index} item={item} />
                ))}
            </Carousel>
          )}

          {!items && (
            <Box
              sx={{
                display: "flex",
                height: "200px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>
      </div>
    </section>
  );
};

export default PrimaryCardsBlock;
