import React from "react";
import { Header, Footer } from "../../components/index";
import "./footer-pages.css";
import { Helmet } from "react-helmet";
import { rootAddress } from "../../http/axios-requests";

const DeliveryAndPayment = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CardThings">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={`https://razdelisdrugim.by/delivery-and-payment`}
        />
        <title>Доставка и оплата: #разделисдругим</title>
        <meta
          name="description"
          content="Доставка и оплата на сервисе #разделисдругим"
        />
        <meta
          name="keywords"
          content="раздели с другим, доставка, оплата, контакты раздели с другим, разделисдругим"
        />
        <meta
          property="og:title"
          content={`Доставка и оплата: #разделисдругим`}
        />
        <meta property="title" content={`Доставка и оплата: #разделисдругим`} />
        <meta property="og:site_name" content="Razdelisdrugim.by" />
        <meta
          property="og:description"
          content="Доставка и оплата на сервисе #разделисдругим"
        />

        <meta
          property="og:url"
          content={`https://razdelisdrugim.by/delivery-and-payment`}
        />
        <meta
          property="og:image"
          content={`${rootAddress}/media/favicon.ico`}
          data-react-helmet="true"
        />
      </Helmet>
      <div className="CardThings_Wrapper">
        <div className="CardThings_Wrapper_container">
          <div className="container_content_card">
            <div className="card_content_footer_pages">
              <h1 className="footer_pages_main_title">Доставка и оплата</h1>
              <p className="footer_pages_main_p">
                Все вопросы по доставке и оплате товаров решаются в
                индивидуальном порядке между Владельцем и Арендатором,
                Исполнителем услуг и Заказчиком.
              </p>
              <p className="footer_pages_main_p">
                Варианты получения, которые можно указать при бронировании:{" "}
                <br></br>
                1. Самовывоз.<br></br>
                2. Доставка Владельцем за свой счет или за счет Арендатора
                <br></br>
                3. Отправка почтовыми и курьерскими службами.
              </p>
              <p className="footer_pages_main_p">
                При заказе услуг можно выбрать место исполнения: <br></br>
                1. По месту Исполнителя.<br></br>
                2. По месту Заказчика
              </p>
              <p className="footer_pages_main_p">
                Также возможна личная встреча в условленном месте с целью
                подписания договора и передачи имущества.
              </p>
              <p className="footer_pages_main_p">
                Все важные нюансы по доставке и оплате товара должны быть
                зафиксированы в договоре!
              </p>
              <h2>
                Оплата за услуги по размещению на платформе #разделисдругим
              </h2>
              <p className="footer_pages_main_p">
                Оплата производится посредством пополнения внутреннего кошелька
                на выбранную сумму. Минимальная сумма пополнения кошелька
                составляет 5,0 BYN. При размещении на платформе объявлений, с
                кошелька списывается сумма за каждый месяц размещения, согласно
                установленного тарифа. Оператор платформы может начислить на
                кошелек дополнительные денежные средства, которые в последствие
                можно использовать для оплаты услуг по размещению на платформе.
                Возврат оплаченных денежных средств предусмотрен по письменному
                обращению в адрес оператора платформы (см. раздел Контакты).
              </p>
              <h2>Тарифы за размещение объявлений на #разделисдругим</h2>
              <p className="footer_pages_main_p">
                Для граждан (физических лиц) о сдаче <strong>имущества</strong>{" "}
                в аренду (прокат) до 5 объявлений: Бесплатно<br></br>
                Для граждан (физических лиц) о сдаче <strong>
                  имущества
                </strong>{" "}
                в аренду (прокат) свыше 5 объявлений: <strong>2,5 BYN</strong>{" "}
                за 1 объявление в месяц.<br></br>
                Для предпринимателей (ИП), юридических лиц, ремесленников,
                самозанятых о сдаче <strong>имущества</strong> в аренду (прокат)
                1 объявление: Бесплатно<br></br>
                Для предпринимателей (ИП), юридических лиц, ремесленников,
                самозанятых о сдаче <strong>имущества</strong> в аренду (прокат)
                1 объявление: <strong>2,5 BYN</strong> за 1 объявление в месяц.
                <br></br>
                Для всех пользователей платформы, объявление о размещении{" "}
                <strong>услуг</strong>: <strong>2,5 BYN</strong> за 1 объявление
                в месяц.<br></br>
              </p>
              <p className="footer_pages_main_p">
                <strong>Акция:</strong> до 31.12.2021 все объявления размещаются{" "}
                <strong>бесплатно</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveryAndPayment;
