import React from "react";
import { useSelector } from "react-redux";
import Loading from "../../img/loading.gif";

const ItemCardMini = ({ item }) => {
  const { serviceIds } = useSelector(({ settings }) => settings);

  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <div className="mini-block-wrapper ">
      <div className="mini-block-wrapper" id="globaldata_pk">
        <a
          style={{ textDecoration: "none" }}
          href={`/item/${item.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <div style={{ cursor: "pointer" }} className="mini-block">
            <img
              onLoad={() => setImageLoaded(true)}
              loading="lazy"
              src={`data:image/png;base64,${item.image_1}`}
              className="mini-block-image"
              alt={item.name_item}
            />

            <div className="mini-block-up">
              <p className="mini-block-title-p">
                {item.name_item && item.name_item.length > 17
                  ? `${item.name_item.substr(0, 17)}...`
                  : item.name_item}
              </p>
            </div>
          </div>
        </a>
      </div>

      {/* МОБИЛЬНАЯ ВЕРСИЯ - ИЗБРАННОЕ В ПРОФИЛЕ */}
      <div className="mini-block-wrapper" id="globaldata_mobile">
        <a
          style={{ textDecoration: "none" }}
          href={`/item/${item.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <div style={{ cursor: "pointer" }} className="mini-block">
            <img
              onLoad={() => setImageLoaded(true)}
              loading="lazy"
              src={
                imageLoaded ? `data:image/png;base64,${item.image_1}` : Loading
              }
              className="block-image"
              alt={item.name_item}
            />
            <div className="mini-block-up">
              <p className="mini-block-title-p">
                {item.name_item && item.name_item.length > 10
                  ? `${item.name_item.substr(0, 10)}...`
                  : item.name_item}
              </p>
            </div>
          </div>
        </a>
      </div>

      {/* планшет  - ИЗБРАННОЕ В ПРОФИЛЕ */}
      <div className="mini-block-wrapper" id="swiper_mobile_800">
        <a
          style={{ textDecoration: "none" }}
          href={`/item/${item.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <div style={{ cursor: "pointer" }} className="mini-block">
            <img
              onLoad={() => setImageLoaded(true)}
              loading="lazy"
              src={
                imageLoaded ? `data:image/png;base64,${item.image_1}` : Loading
              }
              className="block-image"
              alt={item.name_item}
            />
            <div className="mini-block-up">
              <p className="mini-block-title-p">
                {item.name_item && item.name_item.length > 16
                  ? `${item.name_item.substr(0, 16)}...`
                  : item.name_item}
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ItemCardMini;
