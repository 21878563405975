import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { Redirect } from "react-router-dom";
import ru from "date-fns/locale/ru";
import { openErrorAlert, openSuccessAlert } from "../../redux/actions/userData";
import { useSelector, useDispatch } from "react-redux";
import HandShake from "../../img/CardThings/RightContent/handShake1.png";
import Car from "../../img/CardThings/RightContent/Vector3.png";
import freePrice from "../../img/MainPage/freePrice.webp";
import Requests from "../../http/axios-requests";
import CalendarImage from "../../img/CardThings/Booking/Union.png";
import metka from "../../img/CardThings/Booking/Union1.png";
import People from "../../img/CardThings/Booking/body.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

registerLocale("ru", ru);

const Booking = ({ itemData, setSelectedImage, selectedImage }) => {
  const dispatch = useDispatch();
  const { isLoggedIn, addresses, requestActive, userData } = useSelector(
    ({ userData }) => userData
  );
  const { isLoaded } = useSelector(({ items }) => items);

  const { serviceIds, maxAddressesCount } = useSelector(
    ({ settings }) => settings
  );

  const [timechecked, setTimeChecked] = React.useState(1);
  const [coords, setCoords] = React.useState();
  const [radioBooking, setRadioBooking] = React.useState(null);
  const [offeringPrice, setOfferingPrice] = React.useState();
  const [giveTime, setGiveTime] = React.useState(null);
  const [returnTime, setReturnTime] = React.useState(null);

  const [renterBookingName, setRenterBookingName] = React.useState(
    userData.first_name
  );
  const [renterBookingNumber, setRenterBookingNumber] = React.useState(
    userData.phone
  );
  const [renterBookingSms, setRenterBookingSms] = React.useState();

  const [redirect, setRedirect] = React.useState();

  const [busyBookingDates, setBusyBookingDates] = React.useState([]);

  // для дней мин время
  var dateminbooking = new Date().toJSON().slice(0, 10);

  const time = new Date().toLocaleString();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState();

  React.useEffect(() => {
    let resDates = [];
    Requests.getBusyBookingDates(itemData.id).then((res) => {
      resDates = res.data.map((object) =>
        object.dates.map((item) => item.split("").splice(0, 10).join(""))
      );
      setBusyBookingDates(resDates.flat());
    });
  }, []);

  // автоматический начальный выбор способа доставки
  React.useEffect(() => {
    if (itemData.delivery.includes("Самовывоз")) {
      setRadioBooking("1");
    } else if (itemData.delivery.includes("Доставка курьером")) {
      setRadioBooking("3");
    } else if (itemData.delivery.includes("Привезу и заберу сам")) {
      setRadioBooking("2");
    }
  }, [itemData]);

  const ref = React.useRef(null);

  // Установка времени возвраща и получения

  React.useEffect(() => {
    if (itemData) {
      setReturnTime(
        Number(itemData.return_time.substr(0, itemData.return_time.length - 1))
      );
      setGiveTime(
        Number(
          itemData.receive_time.substr(0, itemData.receive_time.length - 1)
        )
      );
    }
  }, []);

  // установка начальной даты брони
  React.useEffect(() => {
    let date = new Date();
    let oldDate;
    let convertedDate = convertDate(date).split("").splice(0, 10).join("");
    for (let i = 0; i < 999; i++) {
      if (busyBookingDates.includes(convertedDate)) {
        oldDate = date;
        date = new Date(
          oldDate.getFullYear(),
          oldDate.getMonth(),
          oldDate.getDate() + 1
        );
        convertedDate = convertDate(date).split("").splice(0, 10).join("");
      } else {
        setStartDate(date);
        break;
      }
    }
  }, [busyBookingDates]);

  // расчёт времени бронирования
  var resulthours = +timechecked;
  var resultdate = +timechecked;
  var resultweek = +timechecked;
  var resultmonths = +timechecked;

  // расчёт суммы бронирования если день/час аренды
  const resultSummaArends = Math.round(
    itemData.rent === "День"
      ? resultdate *
          (!itemData.offer_price_rent ? itemData.price_rent : offeringPrice)
      : itemData.rent === "Час"
      ? resulthours *
        (!itemData.offer_price_rent ? itemData.price_rent : offeringPrice)
      : itemData.rent === "Неделя"
      ? resultweek *
        (!itemData.offer_price_rent ? itemData.price_rent : offeringPrice)
      : itemData.rent === "Месяц"
      ? resultmonths *
        (!itemData.offer_price_rent ? itemData.price_rent : offeringPrice)
      : null
  );

  //  расчёт итоговой суммы
  const totalAmount =
    resultSummaArends +
    (itemData.pledge_price !== null ? itemData.pledge_price : 0) +
    (itemData.self_delivery_price !== null && radioBooking === "2"
      ? itemData.self_delivery_price
      : 0) +
    (itemData.franchise_price ? itemData.franchise_price : 0) +
    (itemData.insurance_price ? itemData.insurance_price : 0) +
    (itemData.servicefee ? itemData.servicefee_price : 0);

  // имя арендатора
  const renterBookingNameHandler = (e) => {
    setRenterBookingName(e.target.value);
  };
  // номер арендатора
  const renterBookingNumberHandler = (e) => {
    setRenterBookingNumber(e.target.value);
  };
  // сообщение арендатора
  const renterBookingSmsHandler = (e) => {
    setRenterBookingSms(e.target.value);
  };
  // длительность аренды
  const inputTimeCheked = (e) => {
    if (e.target.value < 0 || e.target.value > 365 || e.target.value === 0) {
      return;
    }

    if (
      !e.target.value.includes("-") ||
      !e.target.value.includes("--") ||
      !e.target.value.includes("+") ||
      !e.target.value.includes("++")
    ) {
      setTimeChecked(e.target.value);
    }
  };

  //выделяем адреса
  const addressesFormatted = [];
  isLoaded &&
    addresses.map((item, index) => {
      addressesFormatted.push([
        `${item.city}${item.street ? `, ${item.street}` : ""}`,
        item.coordinates,
      ]);
    });

  const handleBooking = () => {
    if (!radioBooking && itemData.delivery !== "Доставка не выбрана") {
      dispatch(openErrorAlert("Не выбран тип доставки!"));
      return;
    } else if (!startDate) {
      dispatch(openErrorAlert("Не указана дата начала бронирования!"));
      return;
    } else if (
      !resulthours &&
      !resultdate &&
      !resultweek &&
      !resultmonths &&
      !serviceIds.includes(itemData && itemData.category_id.id)
    ) {
      dispatch(openErrorAlert("Не указан срок бронирвания!"));
      return;
    } else if ((radioBooking === "2" || radioBooking === "3") && !coords) {
      dispatch(openErrorAlert("Не указан  адрес доставки/заказчика!"));
      return;
    } else if (!renterBookingName) {
      dispatch(openErrorAlert("Не указано имя арендатора!"));
      return;
    } else if (!renterBookingNumber) {
      dispatch(openErrorAlert("Не указано номер телефона арендатора!"));
      return;
    } else if (
      itemData.offer_price_rent &&
      !offeringPrice &&
      !serviceIds.includes(itemData && itemData.category_id.id)
    ) {
      dispatch(openErrorAlert("Не указана ваша цена за услугу!"));
      return;
    }

    Requests.createBooking({
      reservation_user_name: renterBookingName,
      reservation_user_phone: renterBookingNumber,
      reservation_text_sender: renterBookingSms,
      reservation_start_time: convertDate(startDate),
      item_id: itemData.id,
      owner_id: itemData.profile.id,
      delivery_choice: Number(radioBooking),
      reservation_time:
        itemData.rent === "Час"
          ? `${resulthours} час`
          : itemData.rent === "День"
          ? `${resultdate} сут`
          : itemData.rent === "Неделя"
          ? `${resultweek} нед`
          : itemData.rent === "Месяц"
          ? `${resultmonths} мес`
          : "",
      count_date_object:
        itemData.rent === "Час"
          ? resulthours
          : itemData.rent === "День"
          ? resultdate
          : itemData.rent === "Неделя"
          ? resultweek
          : itemData.rent === "Месяц"
          ? resultmonths
          : "",
      reserve_rent:
        itemData.rent === "Час"
          ? "HOUR"
          : itemData.rent === "День"
          ? "DAY"
          : itemData.rent === "Неделя"
          ? "WEEK"
          : itemData.rent === "Месяц"
          ? "MONTH"
          : itemData.rent === "1кв.м."
          ? "SQUARE"
          : itemData.rent === "1чел."
          ? "PERSON"
          : itemData.rent === "1шт."
          ? "PIECE"
          : null,
      reserve_price_rent:
        itemData.offer_price_rent &&
        !serviceIds.includes(itemData && itemData.category_id.id)
          ? null
          : itemData.free_rent ||
            (serviceIds.includes(itemData && itemData.category_id.id) &&
              !itemData.price_rent)
          ? 0
          : itemData.price_rent,
      reserve_offer_price_rent: itemData.offer_price_rent
        ? Number(offeringPrice)
        : null,
      reserve_self_delivery_price: itemData.self_delivery_price,
      reserve_servicefee_price: itemData.servicefee
        ? itemData.servicefee_price
        : false,
      reserve_pledge_price: itemData.pledge_price,
      reserve_insurance_price: itemData.insurance_price,
      reserve_franchise_price: itemData.franchise_price,
      reserve_name_item: itemData.name_item,
      reserve_delivery_free: itemData.delivery_free,
      reserve_contract: itemData.contract,
      reservation_address_delivery:
        radioBooking === "2" || radioBooking === "3" ? coords : "",
      reserve_receive_time: giveTime,
      reserve_return_time: returnTime,
    })
      .then(() => {
        dispatch(openSuccessAlert("Бронирование успешно запрошено!"));
        setRedirect(<Redirect to="/booking" />);
      })
      .catch((e) => dispatch(openErrorAlert(e.response.data)));
  };

  const convertDate = (str) => {
    str = str.toString();
    let parts = str.split(" ");
    let months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    return parts[3] + "-" + months[parts[1]] + "-" + parts[2] + "T" + parts[4];
  };

  React.useEffect(() => {
    let startDateCalc = new Date(startDate);
    if (timechecked) {
      if (itemData.rent === "День") {
        setEndDate(
          new Date(
            startDateCalc.setDate(
              startDateCalc.getDate() + Number(timechecked) - 1
            )
          )
        );
      } else if (itemData.rent === "Неделя") {
        setEndDate(
          startDateCalc.setDate(
            startDateCalc.getDate() + Number(timechecked) * 7 - 1
          )
        );
      } else if (itemData.rent === "Месяц") {
        setEndDate(
          startDateCalc.setDate(
            startDateCalc.getMonth() + Number(timechecked) - 1
          )
        );
      }
    }
  }, [timechecked, startDate]);

  return (
    <div>
      {window.screen.width > 1030 && (
        <div className="card_content_booking" id="card_thing_pc">
          {/* header */}
          <div className="content_booking_shapka_up">
            <p className="content_booking_shapka_up_p">
              Оформление бронирования
            </p>
          </div>
          {/* content */}
          <div className="card_content_booking_all">
            {/* up block content */}
            <div className="card_content_booking_up">
              <div className="content_booking_up_header">
                {/* left block */}
                <div className="booking_up_header_info_item">
                  <div className="header_info_item_photo">
                    {itemData && itemData.image_1 && (
                      <img
                        className={
                          selectedImage === itemData.image_1
                            ? "card_thing_image_booking"
                            : "card_thing_image_booking"
                        }
                        onClick={() =>
                          setSelectedImage(itemData && itemData.image_1)
                        }
                        src={
                          itemData &&
                          `data:image/png;base64,${itemData.image_1}`
                        }
                        alt="razdelisdrugim"
                      />
                    )}
                  </div>

                  <div className="header_info_item_name">
                    {/*название вещи */}
                    <p className="header_info_item_name_p1">
                      {itemData && itemData.name_item}{" "}
                      {!serviceIds.includes(
                        itemData && itemData.category_id.id
                      ) && "в аренду"}
                    </p>
                    {/* стоимость арнеды */}
                    <div className="header_info_item_name_yourCost">
                      {itemData && itemData.offer_price_rent && (
                        <div style={{ display: "flex" }}>
                          <img
                            alt="razdelisdrugim"
                            src={HandShake}
                            className="yourCost_handShake"
                          />
                          <p className="block_up_yourCost-p1">
                            {serviceIds.includes(
                              itemData && itemData.category_id.id
                            )
                              ? "Договорная"
                              : "Предложить свою цену"}
                          </p>
                        </div>
                      )}
                      {itemData && itemData.free_rent && (
                        <div style={{ display: "flex" }}>
                          <img
                            alt="razdelisdrugim"
                            src={freePrice}
                            className="yourCost_handShake"
                          />
                          <p className="block_up_yourCost-p1">Бесплатно</p>
                        </div>
                      )}
                      {itemData &&
                        !itemData.offer_price_rent &&
                        !itemData.free_rent && (
                          <div style={{ display: "flex" }}>
                            <p
                              style={{ marginRight: "10px" }}
                              className="block_up_yourCost-p1"
                            >
                              <span style={{ fontWeight: "500" }}>
                                {itemData && itemData.price_rent}
                              </span>{" "}
                              BYN
                            </p>
                            <p
                              style={{ marginRight: "10px" }}
                              className="block_up_yourCost-p1"
                            >
                              за
                            </p>
                            <p
                              style={{ fontWeight: "500" }}
                              className="block_up_yourCost-p1"
                            >
                              {itemData && itemData.rent === "Час"
                                ? "час"
                                : itemData && itemData.rent === "День"
                                ? "день"
                                : itemData && itemData.rent === "Неделя"
                                ? "неделю"
                                : itemData && itemData.rent === "Месяц"
                                ? "месяц"
                                : itemData && itemData.rent === "1шт."
                                ? "штуку"
                                : itemData && itemData.rent === "1кв.м."
                                ? "1кв.м."
                                : itemData && itemData.rent === "1чел."
                                ? "1чел."
                                : ""}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {/* right block header content */}
                <div className="booking_up_header_info_user">
                  <p className="booking_up_header_info_user_p">
                    {" "}
                    {serviceIds.includes(itemData && itemData.category_id.id)
                      ? "Исполнитель"
                      : "Владелец"}{" "}
                  </p>
                  <div className="booking_up_header_info_user_name">
                    <p className="header_info_user_name-p1">
                      {itemData && itemData.profile.company_name
                        ? itemData && itemData.profile.company_name
                        : itemData && itemData.profile.first_name}
                    </p>
                    <p className="header_info_user_name-p2">
                      {itemData && itemData.profile.company_name
                        ? "Компания"
                        : "Частное лицо"}
                    </p>
                  </div>
                </div>
              </div>
              {/* дата аренды */}
              <div className="content_booking_up_information">
                <div className="content_booking_up_information_all">
                  <p className="information_all_up">
                    {serviceIds.includes(itemData && itemData.category_id.id)
                      ? "Дата исполнения"
                      : "Дата начала аренды"}{" "}
                  </p>
                  <div className="information_all_down">
                    <div className="information_all_down_left">
                      <div className="information_all_down_left_date">
                        <div className="form-group" style={{ display: "flex" }}>
                          <label
                            className="information_all_down_left_date-p"
                            htmlFor="booking_date_input_time"
                          >
                            <Calendar
                              tileClassName={({ date }) =>
                                endDate &&
                                convertDate(date).substr(0, 10) ===
                                  convertDate(endDate).substr(0, 10)
                                  ? "react-calendar-tile-end-active"
                                  : null
                              }
                              showFixedNumberOfWeeks={true}
                              minDate={new Date()}
                              onChange={(value, event) => setStartDate(value)}
                              value={startDate}
                              tileDisabled={({ activeStartDate, date, view }) =>
                                busyBookingDates.includes(
                                  convertDate(date)
                                    .split("")
                                    .splice(0, 10)
                                    .join("")
                                )
                              }
                            />
                          </label>

                          {redirect}

                          {!serviceIds.includes(
                            itemData && itemData.category_id.id
                          ) &&
                            itemData.rent !== "1кв.м." &&
                            itemData.rent !== "1чел." &&
                            itemData.rent !== "1шт." && (
                              <div
                                className="information_all_down_left_date-p"
                                htmlFor="booking_date_end_input_time"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    marginRight: "10px",
                                  }}
                                  className="information_all_down_left_alldate"
                                >
                                  {" "}
                                  на{" "}
                                </span>
                                <input
                                  type="number"
                                  id="booking_date_end_input_time"
                                  onChange={(e) => inputTimeCheked(e)}
                                  value={timechecked}
                                  disabled={startDate === undefined}
                                  className="booking_input_date_end"
                                  required
                                  placeholder="1"
                                />
                                <span className="information_all_down_left_alldate">
                                  {" "}
                                  {itemData && itemData.rent === "Час"
                                    ? "час"
                                    : itemData && itemData.rent === "День"
                                    ? "сут"
                                    : itemData && itemData.rent === "Неделя"
                                    ? "нед"
                                    : itemData && itemData.rent === "Месяц"
                                    ? "мес"
                                    : itemData && itemData.rent === "1шт."
                                    ? "штук"
                                    : itemData && itemData.rent === "1кв.м."
                                    ? "1кв.м."
                                    : ""}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="information_all_down_right">
                      {/* Время получения и возврата*/}

                      <div className="conditions_return">
                        {itemData &&
                          !serviceIds.includes(
                            itemData && itemData.category_id.id
                          ) &&
                          itemData.rent !== "1кв.м." &&
                          itemData.rent !== "1чел." &&
                          itemData.rent !== "1шт." && (
                            <div className="conditions_return_block1">
                              <div className="conditions_row">
                                <p className="conditions_return_row-p1">
                                  {serviceIds.includes(
                                    itemData && itemData.category_id.id
                                  )
                                    ? "Время начала исполнения"
                                    : "Время получения"}
                                </p>
                              </div>
                              <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 120 }}
                              >
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={giveTime}
                                  label="Age"
                                  onChange={(e) => setGiveTime(e.target.value)}
                                >
                                  <MenuItem value={8}>8 часов</MenuItem>
                                  <MenuItem value={9}>9 часов</MenuItem>
                                  <MenuItem value={10}>10 часов</MenuItem>
                                  <MenuItem value={11}>11 часов</MenuItem>
                                  <MenuItem value={12}>12 часов</MenuItem>
                                  <MenuItem value={13}>13 часов</MenuItem>
                                  <MenuItem value={14}>14 часов</MenuItem>
                                  <MenuItem value={15}>15 часов</MenuItem>
                                  <MenuItem value={16}>16 часов</MenuItem>
                                  <MenuItem value={17}>17 часов</MenuItem>
                                  <MenuItem value={18}>18 часов</MenuItem>
                                  <MenuItem value={19}>19 часов</MenuItem>
                                  <MenuItem value={20}>20 часов</MenuItem>
                                  <MenuItem value={21}>21 часа</MenuItem>
                                  <MenuItem value={22}>22 часов</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          )}

                        {itemData &&
                          !serviceIds.includes(
                            itemData && itemData.category_id.id
                          ) &&
                          itemData.rent !== "1кв.м." &&
                          itemData.rent !== "1чел." &&
                          itemData.rent !== "1шт." && (
                            <div className="conditions_return_block2">
                              <div className="conditions_row">
                                <p className="conditions_return_row-p1">
                                  {serviceIds.includes(
                                    itemData && itemData.category_id.id
                                  )
                                    ? "Время конца исполнения"
                                    : "Время возврата"}
                                </p>
                              </div>

                              <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 120 }}
                              >
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={returnTime}
                                  label="Age"
                                  onChange={(e) =>
                                    setReturnTime(e.target.value)
                                  }
                                >
                                  <MenuItem value={8}>8 часов</MenuItem>
                                  <MenuItem value={9}>9 часов</MenuItem>
                                  <MenuItem value={10}>10 часов</MenuItem>
                                  <MenuItem value={11}>13 часов</MenuItem>
                                  <MenuItem value={12}>12 часов</MenuItem>
                                  <MenuItem value={13}>13 часов</MenuItem>
                                  <MenuItem value={14}>14 часов</MenuItem>
                                  <MenuItem value={15}>15 часов</MenuItem>
                                  <MenuItem value={16}>16 часов</MenuItem>
                                  <MenuItem value={17}>17 часов</MenuItem>
                                  <MenuItem value={18}>18 часов</MenuItem>
                                  <MenuItem value={19}>19 часов</MenuItem>
                                  <MenuItem value={20}>20 часов</MenuItem>
                                  <MenuItem value={21}>21 часа</MenuItem>
                                  <MenuItem value={22}>22 часов</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* центральные блоки */}
            <div className="card_content_booking_center">
              <div className="card_content_booking_center_up">
                <div className="booking_center_up_block_first">
                  {itemData && itemData.delivery !== "Доставка не выбрана" && (
                    <div className="booking_center_up_block_left">
                      <img
                        alt="razdelisdrugim"
                        src={Car}
                        className="img_car_booking"
                      />
                      <p className="booking_center_up_block_left-p">
                        {" "}
                        {serviceIds.includes(
                          itemData && itemData.category_id.id
                        )
                          ? "Место исполнения"
                          : "Вид доставки"}
                      </p>
                    </div>
                  )}
                  {/* СПОСОБЫ ДОСТАВКИ  */}

                  <div className="booking_center_up_block_right">
                    {
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="row-radio-buttons-group"
                          value={radioBooking}
                          onChange={(e) => setRadioBooking(e.target.value)}
                          style={{
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          {itemData.delivery.includes("Самовывоз") && (
                            <FormControlLabel
                              style={{ marginRight: "65px" }}
                              value="1"
                              control={<Radio />}
                              label={
                                serviceIds &&
                                serviceIds.includes(itemData.category_id.id)
                                  ? `По месту исполнителя`
                                  : "Самовывоз"
                              }
                              checked={radioBooking === "1"}
                            />
                          )}
                          {itemData.delivery.includes(
                            "Привезу и заберу сам"
                          ) && (
                            <div>
                              <FormControlLabel
                                style={{ marginRight: "65px" }}
                                value="2"
                                control={<Radio />}
                                label={
                                  serviceIds &&
                                  serviceIds.includes(itemData.category_id.id)
                                    ? `По месту заказчика`
                                    : "Привезет и заберет сам"
                                }
                                checked={radioBooking === "2"}
                              />
                              <p className="up_block_right_input_block3_text">
                                — за{" "}
                                {itemData.delivery_free
                                  ? "0"
                                  : itemData.self_delivery_price}{" "}
                                BYN
                              </p>
                            </div>
                          )}
                          {itemData.delivery.includes("Доставка курьером") && (
                            <div>
                              <FormControlLabel
                                style={{ marginRight: "65px" }}
                                value="3"
                                control={<Radio />}
                                label={`Отправит`}
                                checked={radioBooking === "3"}
                              />
                              <p
                                htmlFor="radio_booking3-ipad"
                                className="up_block_right_input_block3_text"
                              >
                                — {itemData.will_send_choice}:
                                {itemData.send_payer === "RENTER"
                                  ? " за счёт арендатора"
                                  : itemData.send_payer === "OWNER"
                                  ? " за счёт владельца"
                                  : ""}
                              </p>
                            </div>
                          )}
                        </RadioGroup>
                      </FormControl>
                    }
                  </div>
                </div>

                {/* предложить цену */}
                {itemData.offer_price_rent &&
                  !serviceIds.includes(itemData && itemData.category_id.id) && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="booking_center_down_block2">
                        <p className="booking_center_down_block2-p">
                          Ваша цена за {itemData.rent.toLowerCase()}{" "}
                          <span className="red_star">*</span>
                        </p>
                        <input
                          type="number"
                          className="add-item-select-input"
                          onChange={(e) => {
                            if (e.target.value < 0) return;
                            setOfferingPrice(e.target.value);
                          }}
                          value={offeringPrice}
                        />
                      </div>
                      <p
                        style={{ marginLeft: "10px" }}
                        className="information_all_down_left_alldate"
                      >
                        BYN
                      </p>
                    </div>
                  )}

                {/* адрес вещи */}

                <div className="booking_center_up_block_second">
                  <div className="up_block_second_block_up">
                    <img
                      alt="razdelisdrugim"
                      src={metka}
                      className="booking_metka"
                    />
                    <p className="up_block_second_block_up-p">
                      {serviceIds.includes(itemData && itemData.category_id.id)
                        ? "Адрес исполнителя"
                        : "Адрес местонахождения вещи"}
                    </p>
                  </div>
                  <div className="up_block_second_block_center">
                    {isLoggedIn ? (
                      <p className="up_block_second_block_center_address">
                        {itemData && itemData.items_address.split(",")[0]}
                        {itemData &&
                        itemData.items_address.split(",")[1] &&
                        itemData.items_address.split(",")[1].length > 2
                          ? `, ${itemData.items_address.split(",")[1]}`
                          : ""}
                      </p>
                    ) : (
                      <p
                        style={{ color: "#4CC9F0" }}
                        className="up_block_second_block_center_address"
                      >
                        Адрес доступен после регистрации
                      </p>
                    )}
                  </div>

                  {(itemData.delivery.includes("Привезу и заберу сам") ||
                    itemData.delivery.includes("Доставка курьером")) &&
                    (radioBooking === "2" || radioBooking === "3") && (
                      <div className="up_block_second_block_down">
                        <div className="up_block_second_block_up">
                          <img
                            alt="razdelisdrugim"
                            src={metka}
                            className="booking_metka"
                          />
                          <p className="up_block_second_block_up-p">
                            {serviceIds.includes(
                              itemData && itemData.category_id.id
                            )
                              ? "Адрес заказчика"
                              : "Адрес доставки"}
                          </p>
                        </div>
                        <div>
                          <div className="add-item-input-wrapper">
                            <input
                              type="text"
                              value={coords}
                              className="add-item-select-input"
                              onChange={(e) => setCoords(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="card_content_booking_center_down">
                <div className="booking_center_down_block1">
                  <img
                    alt="razdelisdrugim"
                    src={People}
                    className="booking_body"
                  />
                  <p className="booking_center_down_block1-p">Ваши данные</p>
                </div>
                <div className="booking_center_down_block2">
                  <p className="booking_center_down_block2-p">
                    Имя <span className="red_star">*</span>
                  </p>
                  <input
                    type="text"
                    className="add-item-select-input"
                    onChange={(e) => renterBookingNameHandler(e)}
                    defaultValue={userData.first_name}
                  />
                </div>
                <div className="booking_center_down_block2">
                  <p className="booking_center_down_block2-p">
                    Номер телефона <span className="red_star">*</span>
                  </p>
                  <input
                    type="text"
                    className="add-item-select-input"
                    onChange={(e) => renterBookingNumberHandler(e)}
                    defaultValue={userData.phone}
                  />
                </div>
                <div className="booking_center_down_block3">
                  <p className="booking_center_down_block2-p">
                    Сообщение{" "}
                    {serviceIds.includes(itemData && itemData.category_id.id)
                      ? "исполнителю"
                      : "владельцу"}
                  </p>
                  <textarea
                    className="booking_center_down_block2-textarea"
                    onChange={(e) => renterBookingSmsHandler(e)}
                  />
                </div>
              </div>
            </div>

            {/* нижний блок  */}
            {((startDate && timechecked && itemData.price_rent !== 0) ||
              (timechecked && offeringPrice && itemData.offer_price_rent) ||
              itemData.free_rent) &&
              !serviceIds.includes(itemData && itemData.category_id.id) &&
              itemData.rent !== "1кв.м." &&
              itemData.rent !== "1чел." &&
              itemData.rent !== "1шт." && (
                <div className="card_content_booking_down">
                  <div className="content_booking_down_block1">
                    <p
                      className="content_booking_down_block1-p"
                      style={{ marginRight: "5px" }}
                    >
                      Итоговая стоимость:{" "}
                    </p>
                    <p className="content_booking_down_block1-p">
                      {" "}
                      {startDate === undefined ? (
                        "-- BYN"
                      ) : startDate <= time ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          Ошибка! (Стартовое время ввода либо меньше настоящего
                          времени либо равное времени завершения аренды...){" "}
                        </span>
                      ) : (resulthours <= 0 || resulthours > 999) &&
                        itemData.rent === "Час" ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          Ошибка длительности аренды!{" "}
                        </span>
                      ) : resulthours !== 0 && itemData.rent === "Час" ? (
                        totalAmount + "  BYN"
                      ) : (resultdate <= 0 || resultdate > 365) &&
                        itemData.rent === "День" ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          Ошибка длительности аренды!{" "}
                        </span>
                      ) : (resultdate !== 0 || resultdate <= 365) &&
                        itemData.rent === "День" ? (
                        totalAmount + "  BYN"
                      ) : (resultweek <= 0 || resultweek > 52) &&
                        itemData.rent === "Неделя" ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          Ошибка длительности аренды!{" "}
                        </span>
                      ) : (resultweek !== 0 || resultweek <= 52) &&
                        itemData.rent === "Неделя" ? (
                        totalAmount + "  BYN"
                      ) : (resultmonths <= 0 || resultmonths > 12) &&
                        itemData.rent === "Месяц" ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          Ошибка длительности аренды!{" "}
                        </span>
                      ) : (resultmonths !== 0 || resultmonths <= 12) &&
                        itemData.rent === "Месяц" ? (
                        totalAmount + "  BYN"
                      ) : isNaN(totalAmount) ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          Ошибка срока аренды!
                        </span>
                      ) : !isNaN(totalAmount) ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          Ошибка срока аренды!
                        </span>
                      ) : (
                        totalAmount + "  BYN"
                      )}{" "}
                    </p>
                  </div>

                  {(itemData.price_rent !== 0 || itemData.offer_price_rent) && (
                    <div className="content_booking_down_block1">
                      <p className="content_booking_down_block1-p">
                        {isNaN(resultSummaArends)
                          ? "--"
                          : resultSummaArends <= 0
                          ? "--"
                          : resultSummaArends}{" "}
                        BYN{" "}
                      </p>
                      <p
                        className="content_booking_down_block1-p"
                        style={{ marginLeft: "5px" }}
                      >
                        {" "}
                        аренда{" "}
                      </p>
                      <p
                        className="content_booking_down_block1-p"
                        style={{ margin: "0 5px" }}
                      >
                        {" "}
                        (
                        <span className="content_booking_down_block1-p">
                          {itemData.offer_price_rent
                            ? offeringPrice
                            : itemData.price_rent}{" "}
                          BYN{" "}
                          <span
                            className="content_booking_down_block1-p"
                            style={{ margin: "0 2px" }}
                          >
                            {" "}
                            x{" "}
                          </span>
                          {itemData.rent === "День"
                            ? isNaN(resultdate)
                              ? 0 + " сут"
                              : resultdate + " сут"
                            : ""}
                          {itemData.rent === "Час"
                            ? isNaN(Number.resulthours)
                              ? resulthours + " час"
                              : 0 + " час"
                            : ""}
                          {itemData.rent === "Неделя"
                            ? isNaN(resultweek)
                              ? 0 + " нед"
                              : resultweek + " нед"
                            : ""}
                          {itemData.rent === "Месяц"
                            ? isNaN(resultmonths)
                              ? 0 + " мес"
                              : resultmonths + " мес"
                            : ""}
                        </span>
                        )
                      </p>
                    </div>
                  )}

                  {itemData.free_rent && (
                    <div className="content_booking_down_block1">
                      <p className="content_booking_down_block1-p">
                        {" "}
                        Аренда: бесплатно
                      </p>
                    </div>
                  )}

                  {radioBooking === "2" && (
                    <div className="content_booking_down_block1">
                      <p className="content_booking_down_block1-p">
                        {itemData.self_delivery_price === undefined
                          ? 0
                          : itemData.self_delivery_price === null
                          ? 0
                          : radioBooking === "2"
                          ? itemData.self_delivery_price
                          : 0}{" "}
                        BYN
                      </p>
                      <p
                        className="content_booking_down_block1-p"
                        style={{ margin: "0 5px" }}
                      >
                        доставка
                      </p>
                    </div>
                  )}

                  {itemData.insurance_price > 0 && (
                    <div className="content_booking_down_block1">
                      <p className="content_booking_down_block1-p">
                        {itemData.insurance_price} BYN
                      </p>
                      <p
                        className="content_booking_down_block1-p"
                        style={{ margin: "0 5px" }}
                      >
                        страхование
                      </p>
                    </div>
                  )}

                  {itemData.franchise_price > 0 && (
                    <div className="content_booking_down_block1">
                      <p className="content_booking_down_block1-p">
                        {itemData.franchise_price} BYN
                      </p>
                      <p
                        className="content_booking_down_block1-p"
                        style={{ margin: "0 5px" }}
                      >
                        франшиза
                      </p>
                    </div>
                  )}

                  {itemData.servicefee_price > 0 && (
                    <div className="content_booking_down_block1">
                      <p className="content_booking_down_block1-p">
                        {itemData.servicefee_price} BYN
                      </p>
                      <p
                        className="content_booking_down_block1-p"
                        style={{ margin: "0 5px" }}
                      >
                        сервисный сбор
                      </p>
                    </div>
                  )}

                  {itemData.pledge_price > 0 && (
                    <div className="content_booking_down_block2">
                      <p className="content_booking_down_block1-p">
                        {" "}
                        {itemData.pledge_price === undefined
                          ? 0
                          : itemData.pledge_price === null
                          ? 0
                          : itemData.pledge_price}{" "}
                        BYN{" "}
                      </p>
                      <p
                        className="content_booking_down_block1-p"
                        style={{ margin: "0 5px" }}
                      >
                        возвратный залог
                      </p>
                    </div>
                  )}
                </div>
              )}
          </div>
          {/* кнопка бронированиия */}
          <div className="card_content_booking_btn">
            <input
              style={{ cursor: "pointer" }}
              onClick={handleBooking}
              type="button"
              value={
                serviceIds.includes(itemData && itemData.category_id.id)
                  ? "Заказать"
                  : "Запросить бронирование"
              }
              className="booking_btn"
            />
          </div>
        </div>
      )}
      {/* IPAD */}
      <div className="card_content_booking" id="card_thing_ipad">
        {/* header */}
        <div className="content_booking_shapka_up">
          <p className="content_booking_shapka_up_p">Оформление бронирования</p>
        </div>
        {/* content */}
        <div className="card_content_booking_all">
          {/* up block content */}
          <div className="card_content_booking_up">
            <div className="content_booking_up_header">
              {/* left block */}
              <div className="booking_up_header_info_item">
                <div className="header_info_item_photo">
                  {itemData && itemData.image_1 && (
                    <img
                      className={
                        selectedImage === itemData.image_1
                          ? "card_thing_image_booking"
                          : "card_thing_image_booking"
                      }
                      onClick={() =>
                        setSelectedImage(itemData && itemData.image_1)
                      }
                      src={
                        itemData && `data:image/png;base64,${itemData.image_1}`
                      }
                      alt="razdelisdrugim"
                    />
                  )}
                </div>{" "}
                {redirect}
                <div className="header_info_item_name">
                  {/*название вещи */}
                  <p className="header_info_item_name_p1">
                    {itemData && itemData.name_item}{" "}
                    {!serviceIds.includes(
                      itemData && itemData.category_id.id
                    ) && "в аренду"}
                  </p>
                  {/* стоимость арнеды */}
                  <div className="header_info_item_name_yourCost">
                    {itemData && itemData.offer_price_rent && (
                      <div style={{ display: "flex" }}>
                        <img
                          alt="razdelisdrugim"
                          src={HandShake}
                          className="yourCost_handShake"
                        />
                        <p className="block_up_yourCost-p1">
                          {serviceIds.includes(
                            itemData && itemData.category_id.id
                          )
                            ? "Договорная"
                            : "Предложить свою цену"}
                        </p>
                      </div>
                    )}
                    {itemData && itemData.free_rent && (
                      <div style={{ display: "flex" }}>
                        <img
                          alt="razdelisdrugim"
                          src={freePrice}
                          className="yourCost_handShake"
                        />
                        <p className="block_up_yourCost-p1">Бесплатно</p>
                      </div>
                    )}
                    {itemData &&
                      !itemData.offer_price_rent &&
                      !itemData.free_rent && (
                        <div style={{ display: "flex" }}>
                          <p
                            style={{ marginRight: "10px" }}
                            className="block_up_yourCost-p1"
                          >
                            <span style={{ fontWeight: "500" }}>
                              {itemData && itemData.price_rent}
                            </span>{" "}
                            BYN
                          </p>
                          <p
                            style={{ marginRight: "10px" }}
                            className="block_up_yourCost-p1"
                          >
                            за
                          </p>
                          <p
                            style={{ fontWeight: "500" }}
                            className="block_up_yourCost-p1"
                          >
                            {itemData && itemData.rent === "Час"
                              ? "час"
                              : itemData && itemData.rent === "День"
                              ? "день"
                              : itemData && itemData.rent === "Неделя"
                              ? "неделю"
                              : itemData && itemData.rent === "Месяц"
                              ? "месяц"
                              : itemData && itemData.rent === "1шт."
                              ? "штуку"
                              : itemData && itemData.rent === "1кв.м."
                              ? "1кв.м."
                              : itemData && itemData.rent === "1чел."
                              ? "1чел."
                              : ""}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {/* right block header content */}
              <div className="booking_up_header_info_user">
                <p className="booking_up_header_info_user_p">
                  {" "}
                  {serviceIds.includes(itemData && itemData.category_id.id)
                    ? "Исполнитель"
                    : "Владелец"}{" "}
                </p>
                <div className="booking_up_header_info_user_name">
                  <p className="header_info_user_name-p1">
                    {itemData && itemData.profile.company_name
                      ? itemData && itemData.profile.company_name
                      : itemData && itemData.profile.first_name}
                  </p>
                  <p className="header_info_user_name-p2">
                    {itemData && itemData.profile.company_name
                      ? "Компания"
                      : "Частное лицо"}
                  </p>
                </div>
              </div>
            </div>
            {/* дата аренды */}
            <div className="content_booking_up_information">
              <div className="content_booking_up_information_all">
                <p className="information_all_up">
                  {" "}
                  {serviceIds.includes(itemData && itemData.category_id.id)
                    ? "Дата исполнения"
                    : "Дата начала аренды"}{" "}
                </p>
                <div className="information_all_down">
                  <div className="information_all_down_left">
                    <div className="information_all_down_left_date">
                      <Calendar
                        minDate={new Date()}
                        onChange={(value, event) => setStartDate(value)}
                        value={startDate}
                        tileDisabled={({ activeStartDate, date, view }) =>
                          busyBookingDates.includes(
                            convertDate(date).split("").splice(0, 10).join("")
                          )
                        }
                      />
                      {!serviceIds.includes(
                        itemData && itemData.category_id.id
                      ) &&
                        itemData.rent !== "1кв.м." &&
                        itemData.rent !== "1чел." &&
                        itemData.rent !== "1шт." && (
                          <div
                            className="information_all_down_left_date-p"
                            htmlFor="booking_date_end_input_time"
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginRight: "10px",
                              }}
                              className="information_all_down_left_alldate"
                            >
                              {" "}
                              на{" "}
                            </span>
                            <input
                              type="number"
                              id="booking_date_end_input_time"
                              onChange={(e) => inputTimeCheked(e)}
                              value={timechecked}
                              disabled={startDate === undefined}
                              className="booking_input_date_end"
                              required
                              placeholder="1"
                            />
                            <span className="information_all_down_left_alldate">
                              {" "}
                              {itemData && itemData.rent === "Час"
                                ? "час"
                                : itemData && itemData.rent === "День"
                                ? "сут"
                                : itemData && itemData.rent === "Неделя"
                                ? "нед"
                                : itemData && itemData.rent === "Месяц"
                                ? "мес"
                                : itemData && itemData.rent === "1шт."
                                ? "штук"
                                : itemData && itemData.rent === "1кв.м."
                                ? "1кв.м."
                                : ""}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="information_all_down_right">
                    {/* Время получения и возврата*/}
                    <div className="conditions_return">
                      {itemData &&
                        !serviceIds.includes(
                          itemData && itemData.category_id.id
                        ) &&
                        itemData.rent !== "1кв.м." &&
                        itemData.rent !== "1чел." &&
                        itemData.rent !== "1шт." && (
                          <div className="conditions_return_block1">
                            <div className="conditions_row">
                              <p className="conditions_return_row-p1">
                                {serviceIds.includes(
                                  itemData && itemData.category_id.id
                                )
                                  ? "Время начала исполнения"
                                  : "Время получения"}
                              </p>
                            </div>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={giveTime}
                                label="Age"
                                onChange={(e) => setGiveTime(e.target.value)}
                              >
                                <MenuItem value={8}>8 часов</MenuItem>
                                <MenuItem value={9}>9 часов</MenuItem>
                                <MenuItem value={10}>10 часов</MenuItem>
                                <MenuItem value={11}>13 часов</MenuItem>
                                <MenuItem value={12}>12 часов</MenuItem>
                                <MenuItem value={13}>13 часов</MenuItem>
                                <MenuItem value={14}>14 часов</MenuItem>
                                <MenuItem value={15}>15 часов</MenuItem>
                                <MenuItem value={16}>16 часов</MenuItem>
                                <MenuItem value={17}>17 часов</MenuItem>
                                <MenuItem value={18}>18 часов</MenuItem>
                                <MenuItem value={19}>19 часов</MenuItem>
                                <MenuItem value={20}>20 часов</MenuItem>
                                <MenuItem value={21}>21 часа</MenuItem>
                                <MenuItem value={22}>22 часов</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}

                      {itemData &&
                        !serviceIds.includes(
                          itemData && itemData.category_id.id
                        ) &&
                        itemData.rent !== "1кв.м." &&
                        itemData.rent !== "1чел." &&
                        itemData.rent !== "1шт." && (
                          <div className="conditions_return_block2">
                            <div className="conditions_row">
                              <p className="conditions_return_row-p1">
                                {serviceIds.includes(
                                  itemData && itemData.category_id.id
                                )
                                  ? "Время конца исполнения"
                                  : "Время возврата"}
                              </p>
                            </div>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={returnTime}
                                label="Age"
                                onChange={(e) => setReturnTime(e.target.value)}
                              >
                                <MenuItem value={8}>8 часов</MenuItem>
                                <MenuItem value={9}>9 часов</MenuItem>
                                <MenuItem value={10}>10 часов</MenuItem>
                                <MenuItem value={11}>13 часов</MenuItem>
                                <MenuItem value={12}>12 часов</MenuItem>
                                <MenuItem value={13}>13 часов</MenuItem>
                                <MenuItem value={14}>14 часов</MenuItem>
                                <MenuItem value={15}>15 часов</MenuItem>
                                <MenuItem value={16}>16 часов</MenuItem>
                                <MenuItem value={17}>17 часов</MenuItem>
                                <MenuItem value={18}>18 часов</MenuItem>
                                <MenuItem value={19}>19 часов</MenuItem>
                                <MenuItem value={20}>20 часов</MenuItem>
                                <MenuItem value={21}>21 часа</MenuItem>
                                <MenuItem value={22}>22 часов</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* центральные блоки */}
          <div className="card_content_booking_center">
            <div className="card_content_booking_center_up">
              <div className="booking_center_up_block_first">
                {itemData && itemData.delivery !== "Доставка не выбрана" && (
                  <div className="booking_center_up_block_left">
                    <img
                      alt="razdelisdrugim"
                      src={Car}
                      className="img_car_booking"
                    />
                    <p className="booking_center_up_block_left-p">
                      {" "}
                      {serviceIds.includes(itemData && itemData.category_id.id)
                        ? "Место исполнения"
                        : "Вид доставки"}
                    </p>
                  </div>
                )}
                {/* СПОСОБЫ ДОСТАВКИ  */}

                <div className="booking_center_up_block_right">
                  {
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                        value={radioBooking}
                        onChange={(e) => setRadioBooking(e.target.value)}
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        {itemData.delivery.includes("Самовывоз") && (
                          <FormControlLabel
                            style={{ marginRight: "65px" }}
                            value="1"
                            control={<Radio />}
                            label={
                              serviceIds &&
                              serviceIds.includes(itemData.category_id.id)
                                ? `По месту исполнителя`
                                : "Самовывоз"
                            }
                            checked={radioBooking === "1"}
                          />
                        )}
                        {itemData.delivery.includes("Привезу и заберу сам") && (
                          <div>
                            <FormControlLabel
                              style={{ marginRight: "65px" }}
                              value="2"
                              control={<Radio />}
                              label={
                                serviceIds &&
                                serviceIds.includes(itemData.category_id.id)
                                  ? `По месту заказчика`
                                  : "Привезет и заберет сам"
                              }
                              checked={radioBooking === "2"}
                            />
                            <p className="up_block_right_input_block3_text">
                              — за{" "}
                              {itemData.delivery_free
                                ? "0"
                                : itemData.self_delivery_price}{" "}
                              BYN
                            </p>
                          </div>
                        )}
                        {itemData.delivery.includes("Доставка курьером") && (
                          <div>
                            <FormControlLabel
                              style={{ marginRight: "65px" }}
                              value="3"
                              control={<Radio />}
                              label={`Отправит`}
                              checked={radioBooking === "3"}
                            />
                            <p
                              htmlFor="radio_booking3-ipad"
                              className="up_block_right_input_block3_text"
                            >
                              — {itemData.will_send_choice}:
                              {itemData.send_payer === "RENTER"
                                ? " за счёт арендатора"
                                : itemData.send_payer === "OWNER"
                                ? " за счёт владельца"
                                : ""}
                            </p>
                          </div>
                        )}
                      </RadioGroup>
                    </FormControl>
                  }
                </div>
              </div>

              {/* предложить цену */}
              {itemData.offer_price_rent &&
                !serviceIds.includes(itemData && itemData.category_id.id) && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="booking_center_down_block2">
                      <p className="booking_center_down_block2-p">
                        Ваша цена за {itemData.rent.toLowerCase()}{" "}
                        <span className="red_star">*</span>
                      </p>
                      <input
                        type="number"
                        className="add-item-select-input"
                        onChange={(e) => {
                          if (e.target.value < 0) return;
                          setOfferingPrice(e.target.value);
                        }}
                        value={offeringPrice}
                      />
                    </div>
                    <p
                      style={{ marginLeft: "10px" }}
                      className="information_all_down_left_alldate"
                    >
                      BYN
                    </p>
                  </div>
                )}

              {/* адрес вещи */}

              <div className="booking_center_up_block_second">
                <div className="up_block_second_block_up">
                  <img
                    alt="razdelisdrugim"
                    src={metka}
                    className="booking_metka"
                  />
                  <p className="up_block_second_block_up-p">
                    {serviceIds.includes(itemData && itemData.category_id.id)
                      ? "Адрес исполнителя"
                      : "Адрес местонахождения вещи"}
                  </p>
                </div>
                <div className="up_block_second_block_center">
                  {isLoggedIn ? (
                    <p className="up_block_second_block_center_address">
                      {itemData && itemData.items_address.split(",")[0]}
                      {itemData &&
                      itemData.items_address.split(",")[1] &&
                      itemData.items_address.split(",")[1].length > 2
                        ? `, ${itemData.items_address.split(",")[1]}`
                        : ""}
                    </p>
                  ) : (
                    <p
                      style={{ color: "#4CC9F0" }}
                      className="up_block_second_block_center_address"
                    >
                      Адрес доступен после регистрации
                    </p>
                  )}
                </div>

                {(itemData.delivery.includes("Привезу и заберу сам") ||
                  itemData.delivery.includes("Доставка курьером")) &&
                  (radioBooking === "2" || radioBooking === "3") && (
                    <div className="up_block_second_block_down">
                      <div className="up_block_second_block_up">
                        <img
                          alt="razdelisdrugim"
                          src={metka}
                          className="booking_metka"
                        />
                        <p className="up_block_second_block_up-p">
                          {serviceIds.includes(
                            itemData && itemData.category_id.id
                          )
                            ? "Адрес заказчика"
                            : "Адрес доставки"}
                        </p>
                      </div>
                      <div>
                        <div className="add-item-input-wrapper">
                          <input
                            type="text"
                            value={coords}
                            className="add-item-select-input"
                            onChange={(e) => setCoords(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="card_content_booking_center_down">
              <div className="booking_center_down_block1">
                <img
                  alt="razdelisdrugim"
                  src={People}
                  className="booking_body"
                />
                <p className="booking_center_down_block1-p">Ваши данные</p>
              </div>
              <div className="booking_center_down_block2">
                <p className="booking_center_down_block2-p">
                  Имя <span className="red_star">*</span>
                </p>
                <input
                  type="text"
                  className="add-item-select-input"
                  onChange={(e) => renterBookingNameHandler(e)}
                  defaultValue={userData.first_name}
                />
              </div>
              <div className="booking_center_down_block2">
                <p className="booking_center_down_block2-p">
                  Номер телефона <span className="red_star">*</span>
                </p>
                <input
                  type="text"
                  className="add-item-select-input"
                  onChange={(e) => renterBookingNumberHandler(e)}
                  defaultValue={userData.phone}
                />
              </div>
              <div className="booking_center_down_block3">
                <p className="booking_center_down_block2-p">
                  Сообщение{" "}
                  {serviceIds.includes(itemData && itemData.category_id.id)
                    ? "исполнителю"
                    : "владельцу"}
                </p>
                <textarea
                  className="booking_center_down_block2-textarea"
                  onChange={(e) => renterBookingSmsHandler(e)}
                />
              </div>
            </div>
          </div>

          {/* нижний блок  */}
          {((startDate && timechecked && itemData.price_rent !== 0) ||
            (timechecked && offeringPrice && itemData.offer_price_rent) ||
            itemData.free_rent) &&
            !serviceIds.includes(itemData && itemData.category_id.id) &&
            itemData.rent !== "1кв.м." &&
            itemData.rent !== "1чел." &&
            itemData.rent !== "1шт." && (
              <div className="card_content_booking_down">
                <div className="content_booking_down_block1">
                  <p
                    className="content_booking_down_block1-p"
                    style={{ marginRight: "5px" }}
                  >
                    Итоговая стоимость:{" "}
                  </p>
                  <p className="content_booking_down_block1-p">
                    {" "}
                    {startDate === undefined ? (
                      "-- BYN"
                    ) : startDate <= time ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка! (Стартовое время ввода либо меньше настоящего
                        времени либо равное времени завершения аренды...){" "}
                      </span>
                    ) : (resulthours <= 0 || resulthours > 999) &&
                      itemData.rent === "Час" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : resulthours !== 0 && itemData.rent === "Час" ? (
                      totalAmount + "  BYN"
                    ) : (resultdate <= 0 || resultdate > 365) &&
                      itemData.rent === "День" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : (resultdate !== 0 || resultdate <= 365) &&
                      itemData.rent === "День" ? (
                      totalAmount + "  BYN"
                    ) : (resultweek <= 0 || resultweek > 52) &&
                      itemData.rent === "Неделя" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : (resultweek !== 0 || resultweek <= 52) &&
                      itemData.rent === "Неделя" ? (
                      totalAmount + "  BYN"
                    ) : (resultmonths <= 0 || resultmonths > 12) &&
                      itemData.rent === "Месяц" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : (resultmonths !== 0 || resultmonths <= 12) &&
                      itemData.rent === "Месяц" ? (
                      totalAmount + "  BYN"
                    ) : isNaN(totalAmount) ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка срока аренды!
                      </span>
                    ) : (
                      totalAmount + "  BYN"
                    )}{" "}
                  </p>
                </div>

                {(itemData.price_rent !== 0 || itemData.offer_price_rent) && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {isNaN(resultSummaArends)
                        ? "--"
                        : resultSummaArends <= 0
                        ? "--"
                        : resultSummaArends}{" "}
                      BYN{" "}
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ marginLeft: "5px" }}
                    >
                      {" "}
                      аренда{" "}
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      {" "}
                      (
                      <span className="content_booking_down_block1-p">
                        {itemData.offer_price_rent
                          ? offeringPrice
                          : itemData.price_rent}{" "}
                        BYN{" "}
                        <span
                          className="content_booking_down_block1-p"
                          style={{ margin: "0 2px" }}
                        >
                          {" "}
                          x{" "}
                        </span>
                        {itemData.rent === "День"
                          ? isNaN(resultdate)
                            ? 0 + " сут"
                            : resultdate + " сут"
                          : ""}
                        {itemData.rent === "Час"
                          ? isNaN(Number.resulthours)
                            ? resulthours + " час"
                            : 0 + " час"
                          : ""}
                        {itemData.rent === "Неделя"
                          ? isNaN(resultweek)
                            ? 0 + " нед"
                            : resultweek + " нед"
                          : ""}
                        {itemData.rent === "Месяц"
                          ? isNaN(resultmonths)
                            ? 0 + " мес"
                            : resultmonths + " мес"
                          : ""}
                      </span>
                      )
                    </p>
                  </div>
                )}

                {itemData.free_rent && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {" "}
                      Аренда: бесплатно
                    </p>
                  </div>
                )}

                {radioBooking === "2" && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.self_delivery_price === undefined
                        ? 0
                        : itemData.self_delivery_price === null
                        ? 0
                        : radioBooking === "2"
                        ? itemData.self_delivery_price
                        : 0}{" "}
                      BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      доставка
                    </p>
                  </div>
                )}

                {itemData.insurance_price > 0 && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.insurance_price} BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      страхование
                    </p>
                  </div>
                )}

                {itemData.franchise_price > 0 && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.franchise_price} BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      франшиза
                    </p>
                  </div>
                )}

                {itemData.servicefee_price > 0 && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.servicefee_price} BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      сервисный сбор
                    </p>
                  </div>
                )}

                {itemData.pledge_price > 0 && (
                  <div className="content_booking_down_block2">
                    <p className="content_booking_down_block1-p">
                      {" "}
                      {itemData.pledge_price === undefined
                        ? 0
                        : itemData.pledge_price === null
                        ? 0
                        : itemData.pledge_price}{" "}
                      BYN{" "}
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      возвратный залог
                    </p>
                  </div>
                )}
              </div>
            )}
        </div>
        {/* кнопка бронированиия */}
        <div className="card_content_booking_btn">
          <input
            style={{ cursor: "pointer" }}
            onClick={handleBooking}
            type="button"
            value={
              serviceIds.includes(itemData && itemData.category_id.id)
                ? "Заказать"
                : "Запросить бронирование"
            }
            className="booking_btn"
          />
        </div>
      </div>

      {/*  мобильная версия */}
      <div className="card_content_booking" id="card_thing_mobile">
        {/* header */}
        <div className="content_booking_shapka_up">
          <p className="content_booking_shapka_up_p">Оформление бронирования</p>
        </div>
        {/* content */}
        <div className="card_content_booking_all">
          {/* up block content */}
          <div className="card_content_booking_up">
            <div className="content_booking_up_header">
              {/* left block */}
              <div className="booking_up_header_info_item">
                <div className="header_info_item_photo">
                  {itemData && itemData.image_1 && (
                    <img
                      alt="razdelisdrugim"
                      className={
                        selectedImage === itemData.image_1
                          ? "card_thing_image_booking"
                          : "card_thing_image_booking"
                      }
                      onClick={() =>
                        setSelectedImage(itemData && itemData.image_1)
                      }
                      src={
                        itemData && `data:image/png;base64,${itemData.image_1}`
                      }
                    />
                  )}
                </div>

                <div className="header_info_item_name">
                  {/*название вещи */}
                  <p className="header_info_item_name_p1">
                    {itemData && itemData.name_item}{" "}
                    {!serviceIds.includes(
                      itemData && itemData.category_id.id
                    ) && "в аренду"}
                  </p>
                  {/* стоимость арнеды */}
                  <div className="header_info_item_name_yourCost">
                    {itemData && itemData.offer_price_rent && (
                      <div style={{ display: "flex" }}>
                        <img
                          alt="razdelisdrugim"
                          src={HandShake}
                          className="yourCost_handShake"
                        />
                        <p className="block_up_yourCost-p1">
                          {serviceIds.includes(
                            itemData && itemData.category_id.id
                          )
                            ? "Договорная"
                            : "Предложить свою цену"}
                        </p>
                      </div>
                    )}
                    {itemData && itemData.free_rent && (
                      <div style={{ display: "flex" }}>
                        <img
                          alt="razdelisdrugim"
                          src={freePrice}
                          className="yourCost_handShake"
                        />
                        <p className="block_up_yourCost-p1">Бесплатно</p>
                      </div>
                    )}
                    {itemData &&
                      !itemData.offer_price_rent &&
                      !itemData.free_rent && (
                        <div style={{ display: "flex" }}>
                          <p
                            style={{ marginRight: "10px", fontWeight: "500" }}
                            className="block_up_yourCost-p1"
                          >
                            <span style={{ fontWeight: "500" }}>
                              {itemData && itemData.price_rent}
                            </span>{" "}
                            BYN
                          </p>
                          <p
                            style={{ marginRight: "10px", fontWeight: "500" }}
                            className="block_up_yourCost-p1"
                          >
                            /
                          </p>
                          <p
                            style={{ fontWeight: "500" }}
                            className="block_up_yourCost-p1"
                          >
                            {itemData && itemData.rent === "Час"
                              ? "час"
                              : itemData && itemData.rent === "День"
                              ? "день"
                              : itemData && itemData.rent === "Неделя"
                              ? "неделю"
                              : itemData && itemData.rent === "Месяц"
                              ? "месяц"
                              : itemData && itemData.rent === "1шт."
                              ? "штука"
                              : itemData && itemData.rent === "1кв.м."
                              ? "1кв.м."
                              : "1чел."
                              ? "человек"
                              : ""}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {/* right block header content */}
              <div className="booking_up_header_info_user">
                <p className="booking_up_header_info_user_p">
                  {" "}
                  {serviceIds.includes(itemData && itemData.category_id.id)
                    ? "Исполнитель"
                    : "Владелец"}{" "}
                </p>
                <div className="booking_up_header_info_user_name">
                  <p className="header_info_user_name-p1">
                    {itemData && itemData.profile.company_name
                      ? itemData && itemData.profile.company_name
                      : itemData && itemData.profile.first_name}
                  </p>
                  <p className="header_info_user_name-p2">
                    {itemData && itemData.profile.company_name
                      ? "Компания"
                      : "Частное лицо"}
                  </p>
                </div>
              </div>
            </div>
            {/* дата аренды */}
            <div className="content_booking_up_information">
              <div className="content_booking_up_information_all">
                <p className="information_all_up">
                  {" "}
                  {serviceIds.includes(itemData && itemData.category_id.id)
                    ? "Дата исполнения"
                    : "Дата начала аренды"}{" "}
                </p>
                <div className="information_all_down">
                  <div className="information_all_down_left">
                    <div className="information_all_down_left_date">
                      <Calendar
                        minDate={new Date()}
                        onChange={(value, event) => setStartDate(value)}
                        value={startDate}
                        tileDisabled={({ activeStartDate, date, view }) =>
                          busyBookingDates.includes(
                            convertDate(date).split("").splice(0, 10).join("")
                          )
                        }
                      />
                    </div>
                    {!serviceIds.includes(
                      itemData && itemData.category_id.id
                    ) &&
                      itemData.rent !== "1кв.м." &&
                      itemData.rent !== "1чел." &&
                      itemData.rent !== "1шт." && (
                        <div
                          className="information_all_down_left_date-p"
                          htmlFor="booking_date_end_input_time"
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginRight: "10px",
                            }}
                            className="information_all_down_left_alldate"
                          >
                            {" "}
                            на{" "}
                          </span>
                          <input
                            type="number"
                            id="booking_date_end_input_time"
                            onChange={(e) => inputTimeCheked(e)}
                            value={timechecked}
                            disabled={startDate === undefined}
                            className="booking_input_date_end"
                            required
                            placeholder="1"
                          />
                          <span className="information_all_down_left_alldate">
                            {" "}
                            {itemData && itemData.rent === "Час"
                              ? "час"
                              : itemData && itemData.rent === "День"
                              ? "сут"
                              : itemData && itemData.rent === "Неделя"
                              ? "нед"
                              : itemData && itemData.rent === "Месяц"
                              ? "мес"
                              : itemData && itemData.rent === "1шт."
                              ? "штук"
                              : itemData && itemData.rent === "1кв.м."
                              ? "1кв.м."
                              : "1чел."
                              ? "человек"
                              : ""}
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="information_all_down_right">
                    {/* Время получения и возврата*/}

                    <div className="conditions_return">
                      {itemData &&
                        !serviceIds.includes(
                          itemData && itemData.category_id.id
                        ) &&
                        itemData.rent !== "1кв.м." &&
                        itemData.rent !== "1чел." &&
                        itemData.rent !== "1шт." && (
                          <div className="conditions_return_block1">
                            <div className="conditions_row">
                              <p className="conditions_return_row-p1">
                                {serviceIds.includes(
                                  itemData && itemData.category_id.id
                                )
                                  ? "Время начала исполнения"
                                  : "Время получения"}
                              </p>
                            </div>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              {" "}
                              {redirect}
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={giveTime}
                                label="Age"
                                onChange={(e) => setGiveTime(e.target.value)}
                              >
                                <MenuItem value={8}>8 часов</MenuItem>
                                <MenuItem value={9}>9 часов</MenuItem>
                                <MenuItem value={10}>10 часов</MenuItem>
                                <MenuItem value={11}>13 часов</MenuItem>
                                <MenuItem value={12}>12 часов</MenuItem>
                                <MenuItem value={13}>13 часов</MenuItem>
                                <MenuItem value={14}>14 часов</MenuItem>
                                <MenuItem value={15}>15 часов</MenuItem>
                                <MenuItem value={16}>16 часов</MenuItem>
                                <MenuItem value={17}>17 часов</MenuItem>
                                <MenuItem value={18}>18 часов</MenuItem>
                                <MenuItem value={19}>19 часов</MenuItem>
                                <MenuItem value={20}>20 часов</MenuItem>
                                <MenuItem value={21}>21 часа</MenuItem>
                                <MenuItem value={22}>22 часов</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}

                      {itemData &&
                        !serviceIds.includes(
                          itemData && itemData.category_id.id
                        ) &&
                        itemData.rent !== "1кв.м." &&
                        itemData.rent !== "1чел." &&
                        itemData.rent !== "1шт." && (
                          <div className="conditions_return_block2">
                            <div className="conditions_row">
                              <p className="conditions_return_row-p1">
                                {serviceIds.includes(
                                  itemData && itemData.category_id.id
                                )
                                  ? "Время конца исполнения"
                                  : "Время возврата"}
                              </p>
                            </div>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={returnTime}
                                label="Age"
                                onChange={(e) => setReturnTime(e.target.value)}
                              >
                                <MenuItem value={8}>8 часов</MenuItem>
                                <MenuItem value={9}>9 часов</MenuItem>
                                <MenuItem value={10}>10 часов</MenuItem>
                                <MenuItem value={11}>13 часов</MenuItem>
                                <MenuItem value={12}>12 часов</MenuItem>
                                <MenuItem value={13}>13 часов</MenuItem>
                                <MenuItem value={14}>14 часов</MenuItem>
                                <MenuItem value={15}>15 часов</MenuItem>
                                <MenuItem value={16}>16 часов</MenuItem>
                                <MenuItem value={17}>17 часов</MenuItem>
                                <MenuItem value={18}>18 часов</MenuItem>
                                <MenuItem value={19}>19 часов</MenuItem>
                                <MenuItem value={20}>20 часов</MenuItem>
                                <MenuItem value={21}>21 часа</MenuItem>
                                <MenuItem value={22}>22 часов</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* центральные блоки */}
          <div className="card_content_booking_center">
            <div className="card_content_booking_center_up">
              <div className="booking_center_up_block_first">
                {itemData && itemData.delivery !== "Доставка не выбрана" && (
                  <div className="booking_center_up_block_left">
                    <img
                      alt="razdelisdrugim"
                      src={Car}
                      className="img_car_booking"
                    />
                    <p className="booking_center_up_block_left-p">
                      {" "}
                      {serviceIds.includes(itemData && itemData.category_id.id)
                        ? "Место исполнения"
                        : "Вид доставки"}
                    </p>
                  </div>
                )}
                {/* СПОСОБЫ ДОСТАВКИ  */}
                <div className="booking_center_up_block_right">
                  {
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                        value={radioBooking}
                        onChange={(e) => setRadioBooking(e.target.value)}
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        {itemData.delivery.includes("Самовывоз") && (
                          <FormControlLabel
                            style={{ marginRight: "65px" }}
                            value="1"
                            control={<Radio />}
                            label={
                              serviceIds &&
                              serviceIds.includes(itemData.category_id.id)
                                ? `По месту исполнителя`
                                : "Самовывоз"
                            }
                            checked={radioBooking === "1"}
                          />
                        )}
                        {itemData.delivery.includes("Привезу и заберу сам") && (
                          <div>
                            <FormControlLabel
                              style={{ marginRight: "65px" }}
                              value="2"
                              control={<Radio />}
                              label={
                                serviceIds &&
                                serviceIds.includes(itemData.category_id.id)
                                  ? `По месту заказчика`
                                  : "Привезет и заберет сам"
                              }
                              checked={radioBooking === "2"}
                            />
                            <p className="up_block_right_input_block3_text">
                              — за{" "}
                              {itemData.delivery_free
                                ? "0"
                                : itemData.self_delivery_price}{" "}
                              BYN
                            </p>
                          </div>
                        )}
                        {itemData.delivery.includes("Доставка курьером") && (
                          <div>
                            <FormControlLabel
                              style={{ marginRight: "65px" }}
                              value="3"
                              control={<Radio />}
                              label={`Отправит`}
                              checked={radioBooking === "3"}
                            />
                            <p
                              htmlFor="radio_booking3-ipad"
                              className="up_block_right_input_block3_text"
                            >
                              — {itemData.will_send_choice}:
                              {itemData.send_payer === "RENTER"
                                ? " за счёт арендатора"
                                : itemData.send_payer === "OWNER"
                                ? " за счёт владельца"
                                : ""}
                            </p>
                          </div>
                        )}
                      </RadioGroup>
                    </FormControl>
                  }
                </div>
              </div>

              {itemData.offer_price_rent &&
                !serviceIds.includes(itemData && itemData.category_id.id) && (
                  <div className="booking_center_down_block2">
                    <p className="booking_center_down_block2-p">
                      Ваша цена за {itemData.rent.toLowerCase()}{" "}
                      <span className="red_star">*</span>
                    </p>
                    <input
                      type="number"
                      className="add-item-select-input"
                      onChange={(e) => {
                        if (e.target.value < 0) return;
                        setOfferingPrice(e.target.value);
                      }}
                      value={offeringPrice}
                    />
                  </div>
                )}

              {/* адрес вещи */}

              <div className="booking_center_up_block_second">
                <div className="up_block_second_block_up">
                  <img
                    alt="razdelisdrugim"
                    src={metka}
                    className="booking_metka"
                  />
                  <p className="up_block_second_block_up-p">
                    {serviceIds.includes(itemData && itemData.category_id.id)
                      ? "Адрес исполнителя"
                      : "Адрес местонахождения вещи"}
                  </p>
                </div>
                <div className="up_block_second_block_center">
                  {isLoggedIn ? (
                    <p className="up_block_second_block_center_address">
                      {itemData && itemData.items_address.split(",")[0]}
                      {itemData &&
                      itemData.items_address.split(",")[1] &&
                      itemData.items_address.split(",")[1].length > 2
                        ? `, ${itemData.items_address.split(",")[1]}`
                        : ""}
                    </p>
                  ) : (
                    <p
                      style={{ color: "#4CC9F0" }}
                      className="up_block_second_block_center_address"
                    >
                      Адрес доступен после регистрации
                    </p>
                  )}
                  {/* <p className="up_block_second_block_center-p">
                  Показать на карте
                </p> */}
                </div>

                {(itemData.delivery.includes("Привезу и заберу сам") ||
                  itemData.delivery.includes("Доставка курьером")) &&
                  (radioBooking === "2" || radioBooking === "3") && (
                    <div className="up_block_second_block_down">
                      <div className="up_block_second_block_up">
                        <img
                          alt="razdelisdrugim"
                          src={metka}
                          className="booking_metka"
                        />
                        <p className="up_block_second_block_up-p">
                          {serviceIds.includes(
                            itemData && itemData.category_id.id
                          )
                            ? "Адрес заказчика"
                            : "Адрес доставки"}
                        </p>
                      </div>
                      <div>
                        <div className="add-item-input-wrapper">
                          <input
                            type="text"
                            value={coords}
                            className="add-item-select-input"
                            onChange={(e) => setCoords(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="card_content_booking_center_down">
              <div className="booking_center_down_block1">
                <img
                  alt="razdelisdrugim"
                  src={People}
                  className="booking_body"
                />
                <p className="booking_center_down_block1-p">Ваши данные</p>
              </div>
              <div className="booking_center_down_block2">
                <p className="booking_center_down_block2-p">
                  Имя <span className="red_star">*</span>
                </p>
                <input
                  type="text"
                  className="add-item-select-input"
                  onChange={(e) => renterBookingNameHandler(e)}
                  defaultValue={userData.first_name}
                />
              </div>
              <div className="booking_center_down_block2">
                <p className="booking_center_down_block2-p">
                  Номер телефона <span className="red_star">*</span>
                </p>
                <input
                  type="text"
                  className="add-item-select-input"
                  onChange={(e) => renterBookingNumberHandler(e)}
                  defaultValue={userData.phone}
                />
              </div>
              <div className="booking_center_down_block3">
                <p className="booking_center_down_block2-p">
                  Сообщение{" "}
                  {serviceIds.includes(itemData && itemData.category_id.id)
                    ? "исполнителю"
                    : "владельцу"}
                </p>
                <textarea
                  className="booking_center_down_block2-textarea"
                  onChange={(e) => renterBookingSmsHandler(e)}
                />
              </div>
            </div>
          </div>

          {/* нижний блок  */}
          {((startDate && timechecked && itemData.price_rent !== 0) ||
            (timechecked && offeringPrice && itemData.offer_price_rent) ||
            itemData.free_rent) &&
            !serviceIds.includes(itemData && itemData.category_id.id) &&
            itemData.rent !== "1кв.м." &&
            itemData.rent !== "1чел." &&
            itemData.rent !== "1шт." && (
              <div className="card_content_booking_down">
                <div className="content_booking_down_block1">
                  <p
                    className="content_booking_down_block1-p"
                    style={{ marginRight: "5px" }}
                  >
                    Итоговая стоимость:{" "}
                  </p>
                  <p className="content_booking_down_block1-p">
                    {" "}
                    {startDate === undefined ? (
                      "-- BYN"
                    ) : startDate <= time ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка! (Стартовое время ввода либо меньше настоящего
                        времени либо равное времени завершения аренды...){" "}
                      </span>
                    ) : (resulthours <= 0 || resulthours > 999) &&
                      itemData.rent === "Час" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : resulthours !== 0 && itemData.rent === "Час" ? (
                      totalAmount + "  BYN"
                    ) : (resultdate <= 0 || resultdate > 365) &&
                      itemData.rent === "День" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : (resultdate !== 0 || resultdate <= 365) &&
                      itemData.rent === "День" ? (
                      totalAmount + "  BYN"
                    ) : (resultweek <= 0 || resultweek > 52) &&
                      itemData.rent === "Неделя" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : (resultweek !== 0 || resultweek <= 52) &&
                      itemData.rent === "Неделя" ? (
                      totalAmount + "  BYN"
                    ) : (resultmonths <= 0 || resultmonths > 12) &&
                      itemData.rent === "Месяц" ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка длительности аренды!{" "}
                      </span>
                    ) : (resultmonths !== 0 || resultmonths <= 12) &&
                      itemData.rent === "Месяц" ? (
                      totalAmount + "  BYN"
                    ) : isNaN(totalAmount) ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        Ошибка срока аренды!
                      </span>
                    ) : (
                      totalAmount + "  BYN"
                    )}{" "}
                  </p>
                </div>

                {(itemData.price_rent !== 0 || itemData.offer_price_rent) && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {isNaN(resultSummaArends)
                        ? "--"
                        : resultSummaArends <= 0
                        ? "--"
                        : resultSummaArends}{" "}
                      BYN{" "}
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ marginLeft: "5px" }}
                    >
                      {" "}
                      аренда{" "}
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      {" "}
                      (
                      <span className="content_booking_down_block1-p">
                        {itemData.offer_price_rent
                          ? offeringPrice
                          : itemData.price_rent}{" "}
                        BYN{" "}
                        <span
                          className="content_booking_down_block1-p"
                          style={{ margin: "0 2px" }}
                        >
                          {" "}
                          x{" "}
                        </span>
                        {itemData.rent === "День"
                          ? isNaN(resultdate)
                            ? 0 + " сут"
                            : resultdate + " сут"
                          : ""}
                        {itemData.rent === "Час"
                          ? isNaN(Number.resulthours)
                            ? resulthours + " час"
                            : 0 + " час"
                          : ""}
                        {itemData.rent === "Неделя"
                          ? isNaN(resultweek)
                            ? 0 + " нед"
                            : resultweek + " нед"
                          : ""}
                        {itemData.rent === "Месяц"
                          ? isNaN(resultmonths)
                            ? 0 + " мес"
                            : resultmonths + " мес"
                          : ""}
                      </span>
                      )
                    </p>
                  </div>
                )}

                {itemData.free_rent && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      Аренда: бесплатно
                    </p>
                  </div>
                )}

                {radioBooking === "2" && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.self_delivery_price === undefined
                        ? 0
                        : itemData.self_delivery_price === null
                        ? 0
                        : radioBooking === "2"
                        ? itemData.self_delivery_price
                        : 0}{" "}
                      BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      доставка
                    </p>
                  </div>
                )}

                {itemData.insurance_price > 0 && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.insurance_price} BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      страхование
                    </p>
                  </div>
                )}

                {itemData.franchise_price > 0 && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.franchise_price} BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      франшиза
                    </p>
                  </div>
                )}

                {itemData.servicefee_price > 0 && (
                  <div className="content_booking_down_block1">
                    <p className="content_booking_down_block1-p">
                      {itemData.servicefee_price} BYN
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      сервисный сбор
                    </p>
                  </div>
                )}

                {itemData.pledge_price > 0 && (
                  <div className="content_booking_down_block2">
                    <p className="content_booking_down_block1-p">
                      {" "}
                      {itemData.pledge_price === undefined
                        ? 0
                        : itemData.pledge_price === null
                        ? 0
                        : itemData.pledge_price}{" "}
                      BYN{" "}
                    </p>
                    <p
                      className="content_booking_down_block1-p"
                      style={{ margin: "0 5px" }}
                    >
                      возвратный залог
                    </p>
                  </div>
                )}
              </div>
            )}
        </div>
        {/* кнопка бронированиия */}
        <div className="card_content_booking_btn">
          <input
            style={{ cursor: "pointer" }}
            onClick={handleBooking}
            type="button"
            value={
              serviceIds.includes(itemData && itemData.category_id.id)
                ? "Заказать"
                : "Запросить бронирование"
            }
            className="booking_btn"
          />
        </div>
      </div>
    </div>
  );
};

export default Booking;
