import React, { useState } from "react";
import "./MyGlobalData.css";
import MyDataIndividual from "./MyData/MyDataIndividual";
import MyAddresses from "./MyAddresses/MyAddresses";
import MyDataBusiness from "./MyData/MyDataBusiness";
import WalletPage from "../Wallet/WalletPage";
import { useSelector } from "react-redux";
import { Notifications } from "../..";

const MyGlobalData = ({
  setModalActiveNumber,
  setModalActiveEmail,
  setModalActiveSubmit,
  setDeleteId,
  setModalActivePay,
}) => {
  const [activeForm, setActiveForm] = useState("myData");

  const { status } = useSelector(({ userData }) => userData.userData);

  React.useEffect(() => {
    if (window.location.href.includes("?page=wallet")) {
      setActiveForm("wallet");
    }
  }, [window.location.href]);

  return (
    <div className="container_profile" style={{ marginRight: "15px" }}>
      <div className="container_profile" id="globaldata_pk">
        {/* ЛЕВЫЙ ОПЦИОНАЛ */}
        <div className="container_profile_optional">
          <div className="optional_scroll1">
            <p
              className={
                activeForm === "myData" && "container_profile_optional_myData"
              }
              onClick={() => setActiveForm("myData")}
            >
              {" "}
              Мои данные{" "}
            </p>
          </div>
          <div className="optional_scroll2">
            <p
              className={
                activeForm === "myAddresses" &&
                "container_profile_optional_myAddresses"
              }
              onClick={() => setActiveForm("myAddresses")}
            >
              {" "}
              Мои адреса{" "}
            </p>
          </div>
          <div className="optional_scroll4">
            <p
              onClick={() => setActiveForm("wallet")}
              className={
                activeForm === "wallet" &&
                "container_profile_optional_myAddresses"
              }
            >
              {" "}
              Кошелёк{" "}
            </p>
          </div>
          <div className="optional_scroll5">
            <p
              className={
                activeForm === "notifications" &&
                "container_profile_optional_myAddresses"
              }
              onClick={() => setActiveForm("notifications")}
            >
              {" "}
              Уведомления{" "}
            </p>
          </div>
        </div>

        {/* ПРАВАЯ ЧАСТЬ */}

        <div className="container_profile_content">
          {activeForm === "myData" && status === 2 && (
            <MyDataBusiness
              setModalActiveEmail={setModalActiveEmail}
              setModalActiveNumber={setModalActiveNumber}
            />
          )}
          {activeForm === "myData" && status === 1 && (
            <MyDataIndividual
              setModalActiveEmail={setModalActiveEmail}
              setModalActiveNumber={setModalActiveNumber}
            />
          )}
          {activeForm === "myAddresses" && (
            <MyAddresses
              setModalActiveSubmit={setModalActiveSubmit}
              setDeleteId={setDeleteId}
            />
          )}
          {activeForm === "wallet" && (
            <WalletPage setModalActivePay={setModalActivePay} />
          )}
          {activeForm === "notifications" && <Notifications />}
        </div>
      </div>

      {/* МОБИЛЬНАЯ ВЕРСИЯ */}
      <div
        className="container_profile"
        id="globaldata_mobile"
        style={{ marginRight: "15px" }}
      >
        {/* ЛЕВЫЙ ОПЦИОНАЛ */}
        <div className="container_profile_optional">
          <div className="optional_scroll1">
            <p
              className={
                activeForm === "myData" && "container_profile_optional_myData"
              }
              onClick={() => setActiveForm("myData")}
            >
              {" "}
              Мои данные{" "}
            </p>
          </div>
          <div className="optional_scroll2">
            <p
              className={
                activeForm === "myAddresses" &&
                "container_profile_optional_myAddresses"
              }
              onClick={() => setActiveForm("myAddresses")}
            >
              {" "}
              Мои адреса{" "}
            </p>
          </div>
          <div className="optional_scroll4">
            <p
              onClick={() => setActiveForm("wallet")}
              className={
                activeForm === "wallet" &&
                "container_profile_optional_myAddresses"
              }
            >
              {" "}
              Кошелёк{" "}
            </p>
          </div>
          <div className="optional_scroll5">
            <p
              className={
                activeForm === "notifications" &&
                "container_profile_optional_myAddresses"
              }
              onClick={() => setActiveForm("notifications")}
            >
              {" "}
              Уведомления{" "}
            </p>
          </div>
        </div>

        {/* ПРАВАЯ ЧАСТЬ */}

        <div className="container_profile_content">
          {activeForm === "myData" && status === 2 && (
            <MyDataBusiness
              setModalActiveEmail={setModalActiveEmail}
              setModalActiveNumber={setModalActiveNumber}
            />
          )}
          {activeForm === "myData" && status === 1 && (
            <MyDataIndividual
              setModalActiveEmail={setModalActiveEmail}
              setModalActiveNumber={setModalActiveNumber}
            />
          )}
          {activeForm === "myAddresses" && (
            <MyAddresses
              setModalActiveSubmit={setModalActiveSubmit}
              setDeleteId={setDeleteId}
            />
          )}
          {activeForm === "wallet" && (
            <WalletPage setModalActivePay={setModalActivePay} />
          )}
          {activeForm === "notifications" && <Notifications />}
        </div>
      </div>

      {/* ПЛАНШЕТ ВЕРСИЯ */}
      <div
        className="container_profile"
        id="globaldata_ipad"
        style={{ marginRight: "0" }}
      >
        {/* ЛЕВЫЙ ОПЦИОНАЛ */}
        <div className="container_profile_optional">
          <div className="optional_scroll1">
            <p
              className={
                activeForm === "myData" && "container_profile_optional_myData"
              }
              onClick={() => setActiveForm("myData")}
            >
              {" "}
              Мои данные{" "}
            </p>
          </div>
          <div className="optional_scroll2">
            <p
              className={
                activeForm === "myAddresses" &&
                "container_profile_optional_myAddresses"
              }
              onClick={() => setActiveForm("myAddresses")}
            >
              {" "}
              Мои адреса{" "}
            </p>
          </div>
          <div className="optional_scroll4">
            <p
              onClick={() => setActiveForm("wallet")}
              className={
                activeForm === "wallet" &&
                "container_profile_optional_myAddresses"
              }
            >
              {" "}
              Кошелёк{" "}
            </p>
          </div>
          <div className="optional_scroll5">
            <p
              className={
                activeForm === "notifications" &&
                "container_profile_optional_myAddresses"
              }
              onClick={() => setActiveForm("notifications")}
            >
              {" "}
              Уведомления{" "}
            </p>
          </div>
        </div>

        {/* ПРАВАЯ ЧАСТЬ */}

        <div className="container_profile_content">
          {activeForm === "myData" && status === 2 && (
            <MyDataBusiness
              setModalActiveEmail={setModalActiveEmail}
              setModalActiveNumber={setModalActiveNumber}
            />
          )}
          {activeForm === "myData" && status === 1 && (
            <MyDataIndividual
              setModalActiveEmail={setModalActiveEmail}
              setModalActiveNumber={setModalActiveNumber}
            />
          )}
          {activeForm === "myAddresses" && (
            <MyAddresses
              setModalActiveSubmit={setModalActiveSubmit}
              setDeleteId={setDeleteId}
            />
          )}
          {activeForm === "wallet" && (
            <WalletPage setModalActivePay={setModalActivePay} />
          )}
          {activeForm === "notifications" && <Notifications />}
        </div>
      </div>
    </div>
  );
};

export default MyGlobalData;
