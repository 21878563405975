import React from "react";
import { Header, Footer } from "../../components/index";
import "./footer-pages.css";
import bepaid from "../../img/bepaid.png";
import { rootAddress } from "../../http/axios-requests";
import { Helmet } from "react-helmet";

const PaymentWays = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CardThings">
      <Header />
      <Helmet>
        <link rel="canonical" href={`https://razdelisdrugim.by/payment-ways`} />
        <title>Способы оплаты: #разделисдругим</title>
        <meta name="description" content=" Способы оплаты: #разделисдругим" />
        <meta name="keywords" content="Способы оплаты: #разделисдругим" />
        <meta property="og:title" content={`Способы оплаты: #разделисдругим`} />
        <meta property="title" content={`Способы оплаты: #разделисдругим`} />
        <meta property="og:site_name" content="Razdelisdrugim.by" />
        <meta
          property="og:description"
          content="Способы оплаты: #разделисдругим"
        />

        <meta
          property="og:url"
          content={`https://razdelisdrugim.by/payment-ways`}
        />
        <meta
          property="og:image"
          content={`${rootAddress}/media/favicon.ico`}
          data-react-helmet="true"
        />
      </Helmet>
      <div className="CardThings_Wrapper">
        <div className="CardThings_Wrapper_container">
          <div className="container_content_card">
            <div
              itemscope
              itemtype="http://schema.org/Organization"
              className="card_content_footer_pages"
            >
              <h2 className="footer_pages_main_title">
                Оплатить услуги по размещению информации у нас вы можете
                следующими способами:
              </h2>
              <p className="footer_pages_main_p">
                <strong>
                  Банковской карточкой через интернет через пополнение
                  внутреннего кошелька
                </strong>
                <br></br>
                После нажатия кнопки «Пополнить» вы перейдете на специальную
                защищенную платежную страницу процессинговой системы{" "}
                <a target="_blank" rel="noreferrer" href="https://bepaid.by/">
                  bePaid
                </a>{" "}
                <br></br>
                <br></br>
                На платежной странице будет указан номер заказа и сумма платежа.
                Для оплаты вам необходимо ввести свои карточные данные и
                подтвердить платеж, нажав кнопку «Оплатить».
                <br></br>
                <br></br>
                Если ваша карта поддерживает технологию 3-D Secure, системой ваш
                будет предложено пройти стандартную одноминутную процедуру
                проверки владельца карты на странице вашего банка (банка,
                который выдал вашу карту).
                <br></br>
                <br></br>
                После оплаты вы получите подтверждение об оплате по электронной
                почте, и сумма пополнения отразится в вашем кошельке на сайте.
                <br></br>
                <br></br>
                Обращаем ваше внимание, что после проведения платежа на
                указанный вами электронный адрес придет подтверждение оплаты.
                Просим вас сохранять данные оплат.
                <br></br>
                <br></br>
                Мы принимаем платежи по следующим банковским картам: Visa, Visa
                Electron, MasterCard, Maestro, Белкарт.
                <br></br>
                <br></br>
                <img src={bepaid} />
                <br></br>
                <br></br>
                Платежи по банковским картам осуществляются через систему
                электронных платежей{" "}
                <a target="_blank" rel="noreferrer" href="https://bepaid.by/">
                  bePaid
                </a>
                . Платежная страница системы bePaid отвечает всем требованиям
                безопасности передачи данных (PCI DSS Level 1). Все
                конфиденциальные данные хранятся в зашифрованном виде и
                максимально устойчивы к взлому. Доступ к авторизационным
                страницам осуществляется с использованием протокола,
                обеспечивающего безопасную передачу данных в Интернетe
                (SSL/TLS).
                <br></br>
                <br></br>
                Возврат денежных средств осуществляется по письменному обращению
                на карту, с которой ранее была произведена оплата. Срок
                поступления денежных средств на карту от 1 до 30 дней с момента
                осуществления возврата Исполнителем.
                <br />
                <br />
                Условия возврата описаны здесь:{" "}
                <a href="https://razdelisdrugim.by/delivery-and-payment">
                  https://razdelisdrugim.by/delivery-and-payment
                </a>
                <br />
                <br />
                Договор публичной оферты представлен тут:{" "}
                <a href="https://razdelisdrugim.by/offer-agreement">
                  https://razdelisdrugim.by/offer-agreement
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentWays;
