import React, { useState } from "react";
import "..//css/Catalog.css";
import { Footer, Header } from "../components/index.js";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CatalogComponent from "../components/Catalog/CatalogComponent.jsx";
import Vector1 from "../img/SearchPage/Vector1.png";
import Vector2 from "../img/SearchPage/Vector2.png";
import { Helmet } from "react-helmet";
import { rootAddress } from "../http/axios-requests";
const Catalog = () => {
  const params = useParams();
  const chapterId = params.chapter;

  const { items, isLoaded } = useSelector(({ items }) => items);

  const [activeForm, setActiveForm] = useState("realty");

  const [activeVector, setActiveVector] = useState(false);

  //Если с английского на русский, то передаём вторым параметром true.
  const transliterate = (function () {
    var rus =
        "щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь".split(
          / +/g
        ),
      eng =
        "shh sh ch cz yu ya yo zh `` y' e` a b v g d e z i j k l m n o p r s t u f x `".split(
          / +/g
        );
    return function (text, engToRus) {
      var x;
      for (x = 0; x < rus.length; x++) {
        text = text
          .split(engToRus ? eng[x] : rus[x])
          .join(engToRus ? rus[x] : eng[x]);
        text = text
          .split(engToRus ? eng[x].toUpperCase() : rus[x].toUpperCase())
          .join(engToRus ? rus[x].toUpperCase() : eng[x].toUpperCase());
      }
      return text;
    };
  })();

  console.log(activeVector);

  //выделяем разделы
  const chapters = {};
  isLoaded &&
    items.length > 2 &&
    items.map((item, index) => {
      if (!chapters.hasOwnProperty(item.chapter_id.name_chapter)) {
        chapters[item.chapter_id.name_chapter] = [
          item.chapter_id.id,
          item.chapter_id.chapter_image,
          item.chapter_id.chapter_logo,
          item.chapter_id.chapter_description,
          item.chapter_id.name_chapter,
          item.chapter_id.chapter_seo_phrase,
        ];
      }
    });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setActiveForm(chapterId);
  }, [window.location.href]);

  const history = useHistory();

  return (
    <div className="Catalog">
      <Helmet>
        <title>
          {`${[].concat
            .apply(Object.entries(chapters))
            .map((chapter, index) => {
              if (activeForm === chapter[1][5]) {
                return `${chapter[0]} в аренду: #разделисдругим`;
              }
            })}`.replace(/,/g, "")}
        </title>
        <meta
          name="description"
          content={`${[].concat
            .apply(Object.entries(chapters))
            .map((chapter, index) => {
              if (activeForm === chapter[1][5]) {
                return chapter[1][3]
                  .replaceAll("<h1>", "")
                  .replaceAll("</h1>", "")
                  .replaceAll("<h2>", "")
                  .replaceAll("</h2>", "")
                  .replaceAll("<p>", "")
                  .replaceAll("</p>", "")
                  .replaceAll("<br>", "")
                  .replaceAll("<strong>", "")
                  .replaceAll("</strong>", "");
              }
            })}`.replace(/,/g, "")}
        />
        <meta
          name="keywords"
          content={`${[].concat
            .apply(Object.entries(chapters))
            .map((chapter, index) => {
              if (activeForm === chapter[1][5]) {
                return chapter[1][3]
                  .replaceAll("<h1>", "")
                  .replaceAll("</h1>", "")
                  .replaceAll("<h2>", "")
                  .replaceAll("</h2>", "")
                  .replaceAll("<p>", "")
                  .replaceAll("</p>", "")
                  .replaceAll("<br>", "")
                  .replaceAll("<strong>", "")
                  .replaceAll("</strong>", "");
              }
            })}`.replace(/,/g, "")}
        />
        <meta
          property="og:title"
          content={`${[].concat
            .apply(Object.entries(chapters))
            .map((chapter, index) => {
              if (activeForm === chapter[1][5]) {
                return `${chapter[0]} в аренду: #разделисдругим`;
              }
            })}`.replace(/,/g, "")}
        />

        <meta
          property="title"
          content={`${[].concat
            .apply(Object.entries(chapters))
            .map((chapter, index) => {
              if (activeForm === chapter[1][5]) {
                return `${chapter[0]} в аренду: #разделисдругим`;
              }
            })}`.replace(/,/g, "")}
        />

        <meta property="og:site_name" content="Razdelisdrugim.by" />

        <meta
          property="og:description"
          content={`${[].concat
            .apply(Object.entries(chapters))
            .map((chapter, index) => {
              if (activeForm === chapter[1][5]) {
                return chapter[1][3]
                  .replaceAll("<h1>", "")
                  .replaceAll("</h1>", "")
                  .replaceAll("<h2>", "")
                  .replaceAll("</h2>", "")
                  .replaceAll("<p>", "")
                  .replaceAll("</p>", "")
                  .replaceAll("<br>", "")
                  .replaceAll("<strong>", "")
                  .replaceAll("</strong>", "");
              }
            })}`.replace(/,/g, "")}
        />

        <meta
          property="og:image"
          content={`${rootAddress}${[].concat
            .apply(Object.entries(chapters))
            .map((chapter, index) => {
              if (activeForm === chapter[1][5]) {
                return chapter[1][1];
              }
            })}`.replace(/,/g, "")}
        />
      </Helmet>
      <Header />
      <div className="Catalog_wrapper">
        <div className="Catalog_wrapper_container">
          <div className="Catalog_wrapper_container_up">
            {/* ТЕКС ПУТЕЙ ПО КАТЕГОРИЯМ ( с cardthings взято ) */}
          </div>

          {/* КОНТЕЙНЕР С КОНТЕНТОМ */}
          <div className="Catalog_wrapper_container_down" id="catalog_pk">
            <div className="container_down_left">
              <h1 className="container_down_left-p">Весь каталог</h1>

              <div className="container_down_left_category">
                {isLoaded &&
                  items &&
                  [].concat
                    .apply(Object.entries(chapters))
                    .map((chapter, index) => {
                      return (
                        <div
                          onClick={() => {
                            setActiveForm(chapter[1][5]);
                            window.scrollTo(0, 0);
                            history.push({
                              pathname: `/catalog/${chapter[1][5]}`,
                            });
                          }}
                          key={index}
                          className="left_category_global_div"
                        >
                          <img
                            alt="razdelisdrugim"
                            src={`https://razdelisdrugim.by${chapter[1][2]}`}
                            className="category_img"
                          />
                          <p
                            className={
                              activeForm === chapter[1][5]
                                ? "left_category_global_div_active"
                                : "left_category_global_div1"
                            }
                          >
                            {chapter[0]}
                          </p>
                        </div>
                      );
                    })}
              </div>
            </div>

            {/* ПРАВАЯ СТОРОНА КОНТЕНТА */}
            <div className="container_down_right">
              <CatalogComponent chapterId={activeForm} />
            </div>
          </div>

          {/* МОБИЛЬНАЯ ВЕРСИЯ */}
          <div
            className="container_down_left_burger"
            onClick={() => setActiveVector(!activeVector)}
            id="globaldata_mobile"
          >
            <p className="container_down_left-p">Каталог</p>
            <img src={Vector2} alt="vector" className="vector2_img" />
          </div>

          <div className="Catalog_wrapper_container_down" id="catalog_mobile">
            {activeVector && (
              <div className="container_down_left">
                <div className="container_down_left_category">
                  {isLoaded &&
                    items &&
                    [].concat
                      .apply(Object.entries(chapters))
                      .map((chapter, index) => {
                        return (
                          <div
                            onClick={() => {
                              setActiveForm(chapter[1][5]);
                              setActiveVector(false);
                            }}
                            key={index}
                            className="left_category_global_div"
                          >
                            <img
                              alt="razdelisdrugim"
                              src={`https://razdelisdrugim.by${chapter[1][2]}`}
                              className="category_img"
                            />
                            <p
                              className={
                                activeForm === chapter[1][5]
                                  ? "left_category_global_div_active"
                                  : "left_category_global_div_p"
                              }
                            >
                              {chapter[0]}
                            </p>
                          </div>
                        );
                      })}
                </div>
              </div>
            )}

            {/* ПРАВАЯ СТОРОНА КОНТЕНТА */}
            <div className="container_down_right">
              <CatalogComponent chapterId={activeForm} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Catalog;
