import React from "react";
import { Header, Footer } from "../../components/index";
import "./footer-pages.css";
import { Helmet } from "react-helmet";
import { rootAddress } from "../../http/axios-requests";

const HelpPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CardThings">
      <Helmet>
        <link rel="canonical" href={`https://razdelisdrugim.by/help`} />
        <title>Помощь: #разделисдругим</title>
        <meta
          name="description"
          content="Для получения консультации или при обнаружении ошибок в работе
                сервиса свяжитесь с нами по адресу support@razdelisdrugim.by."
        />
        <meta
          name="keywords"
          content="Для получения консультации или при обнаружении ошибок в работе
                сервиса свяжитесь с нами по адресу support@razdelisdrugim.by."
        />
        <meta property="og:title" content={`Помощь: #разделисдругим`} />
        <meta property="title" content={`Помощь: #разделисдругим`} />
        <meta property="og:site_name" content="Razdelisdrugim.by" />
        <meta
          property="og:description"
          content="Для получения консультации или при обнаружении ошибок в работе
                сервиса свяжитесь с нами по адресу support@razdelisdrugim.by."
        />

        <meta property="og:url" content={`https://razdelisdrugim.by/help`} />
        <meta
          property="og:image"
          content={`${rootAddress}/media/favicon.ico`}
          data-react-helmet="true"
        />
      </Helmet>
      <Header />
      <div className="CardThings_Wrapper">
        <div className="CardThings_Wrapper_container">
          <div className="container_content_card">
            <div className="card_content_footer_pages">
              <h1 className="footer_pages_main_title">Помощь</h1>
              <p className="footer_pages_underline">
                Не нашли ответ на свой вопрос?
              </p>
              <p className="footer_pages_main_p">
                Для получения консультации или при обнаружении ошибок в работе
                сервиса свяжитесь с нами по адресу support@razdelisdrugim.by.{" "}
                <br></br> В обращении подробно опишите возникающую проблему. При
                необходимости приложите скриншоты экрана.<br></br>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpPage;
