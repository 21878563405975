import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Requests, { rootAddress } from "../../http/axios-requests";
import Delete from "../../img/MainPage/delete.webp";
import hide from "../../img/MainPage/hide.webp";
import { useSelector, useDispatch } from "react-redux";
import { openErrorAlert, openSuccessAlert } from "../../redux/actions/userData";
import "../../css/ItemsModerate.scss";

const ItemReviewModerateComponent = ({ item, reload, toggleReload, page }) => {
  //массивы для фото

  const [files, setFiles] = React.useState([]);
  const [resultList, setResultList] = React.useState([]);
  let parsedFiles = [];
  const [sendPhotosArray, setSendPhotosArray] = React.useState([]);

  //конвертация байтов в размер
  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const dispatch = useDispatch();

  const [textFieldsActive, setTextFieldsActive] = React.useState(false);

  const [editedItemName, setEditedItemName] = React.useState(item.name_item);
  const [editedItemDescription, setEditedItemDescription] = React.useState(
    item.description
  );
  const [editedKeyWords, setEditedKeyWords] = React.useState(item.key_words);
  const [category, setCategory] = React.useState(item.category_id.id);
  const [chapter, setChapter] = React.useState(item.category_id.chapter_id.id);
  const [placementType, setPlacementType] = React.useState(
    item.is_service ? "service" : "subject"
  );
  const [deliveryType, setDeliveryType] = React.useState(item.delivery);

  // ВИД ДОСТАВКИ - САМОВЫВОЗ
  const [pickUp, setPickUp] = React.useState(
    item.delivery.split("").includes("1")
  );

  // ВИД ДОСТАВКИ - ЗАБЕРУ ПРИВЕЗУ САМ ( ОПЦИИ - выбор и стомость )
  const [takeAway, setTakeAway] = React.useState(
    item.delivery.split("").includes("2")
  );

  const [typeService, setTypeService] = React.useState(item.delivery_free);
  const [indicateCost, setIndicateCost] = React.useState(
    item.self_delivery_price
  );

  //ОТПРАВЛЮ
  const [yourSend, setYourSend] = React.useState(
    item.delivery.split("").includes("3")
  );

  const [taxi, setTaxi] = React.useState(
    item.will_send_choice.split("").includes("1")
  );
  const [courier, setCourier] = React.useState(
    item.will_send_choice.split("").includes("2")
  );
  const [pochta, setPochta] = React.useState(
    item.will_send_choice.split("").includes("3")
  );

  const [radio, setRadio] = React.useState(item.send_payer);

  //методы доставки отправкой
  const [willSendWays, setWillSendWays] = React.useState(
    item && item.will_send_choice ? item && item.will_send_choice : "NONE"
  );

  const [loadedPhotos, setLoadedPhotos] = React.useState();

  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);

  //ВИД ДОСТАВКИ - ПРИВЕЗУ ЗАБЕРУ САМ
  const takeAwayHandler = () => {
    setTakeAway(!takeAway);
  };

  //обработчик суммы за доставку
  const setIndicateCostHandler = (e) => {
    console.log(e.target.value);
    if (!e.target.value.includes("-") && !e.target.value.includes("--")) {
      setIndicateCost(e.target.value);
    }
  };

  //ВИД ДОСТАВКИ - САМОВЫВОЗ
  const pickupHandler = () => {
    setPickUp(!pickUp);
  };

  //ВИД ДОСТАВКИ - ОТПРАВЛЮ
  const yourSendHandler = () => {
    setYourSend(!yourSend);
  };

  //ПУНКТ ОТПРАВЛЮ - ТАКСИ
  const taxiHandler = () => {
    setTaxi(!taxi);
  };
  //ПУНКТ ОТПРАВЛЮ - КУРЬЕР
  const courierHandler = () => {
    setCourier(!courier);
  };
  //ПУНКТ ОТПРАВЛЮ - ПОЧТА
  const pochtaHandler = () => {
    setPochta(!pochta);
  };

  // отправка за счет
  const radioHandler = (e) => {
    setRadio(e.target.value);
  };

  const { items, isLoaded } = useSelector(({ items }) => items);
  const { serviceIds } = useSelector(({ settings }) => settings);

  //выделяем разделы
  const chapters = {};
  isLoaded &&
    items.map((item, index) => {
      if (!chapters.hasOwnProperty(item.chapter_id.name_chapter)) {
        chapters[item.chapter_id.name_chapter] = item.chapter_id.id;
      }
    });

  //выделяем категории
  const categories = {};
  isLoaded &&
    items.map((item, index) => {
      if (item.chapter_id.id === Number(chapter)) {
        if (serviceIds.includes(item.id) && placementType === "service") {
          categories[item.name_category] = item.id;
        } else if (
          !serviceIds.includes(item.id) &&
          placementType === "subject"
        ) {
          categories[item.name_category] = item.id;
        }
      }
    });

  //обработчик удаления фотографии
  const removePhotoHandler = (index) => {
    files.splice(index, 1);
    sendPhotosArray.splice(index, 1);
    loadedPhotos.splice(index, 1);
    setLoadedPhotos(loadedPhotos);
    forceUpdate();
  };

  function getBase64(file, callback) {
    const reader = new FileReader();

    reader.addEventListener("load", () => callback(reader.result));

    reader.readAsDataURL(file);
  }

  //обработчик добавления фотографий
  const photoHandler = (e) => {
    if (files.length + e.target.files.length > 5) {
      dispatch(openErrorAlert("Максимум 5 изображений!"));
      return;
    }
    parsedFiles = Array.from(e.target.files);
    parsedFiles.forEach((file) => {
      if (file.size > 4.9e6) {
        dispatch(
          openErrorAlert("Вес одной картинки не может превышать 5 мегабайт!")
        );
        return;
      }
      files.push(file);
      resultList.push(URL.createObjectURL(file));

      getBase64(file, function (base64Data) {
        sendPhotosArray.push(base64Data); // Here you can have your code which uses Base64 for its operation, // file to Base64 by oneshubh
      });
    });
    setLoadedPhotos(resultList);
    forceUpdate();
  };

  //записываем фотки в служебные массивы
  React.useEffect(() => {
    item.image_1 &&
      setTimeout(() => {
        sendPhotosArray.push(`data:image/png;base64,${item.image_1}`);
        resultList.push(`data:image/png;base64,${item.image_1}`);
        files.push(`data:image/png;base64,${item.image_1}`);
        setLoadedPhotos(resultList);
        forceUpdate();
      }, 50);

    item.image_2 &&
      setTimeout(() => {
        sendPhotosArray.push(`data:image/png;base64,${item.image_2}`);
        resultList.push(`data:image/png;base64,${item.image_2}`);
        files.push(`data:image/png;base64,${item.image_2}`);
        setLoadedPhotos(resultList);
        forceUpdate();
      }, 50);

    item.image_3 &&
      setTimeout(() => {
        sendPhotosArray.push(`data:image/png;base64,${item.image_3}`);
        resultList.push(`data:image/png;base64,${item.image_3}`);
        files.push(`data:image/png;base64,${item.image_3}`);
        setLoadedPhotos(resultList);
        forceUpdate();
      }, 50);

    item.image_4 &&
      setTimeout(() => {
        sendPhotosArray.push(`data:image/png;base64,${item.image_4}`);
        resultList.push(`data:image/png;base64,${item.image_4}`);
        files.push(`data:image/png;base64,${item.image_4}`);
        setLoadedPhotos(resultList);
        forceUpdate();
      }, 50);

    item.image_5 &&
      setTimeout(() => {
        sendPhotosArray.push(`data:image/png;base64,${item.image_5}`);
        resultList.push(`data:image/png;base64,${item.image_5}`);
        files.push(`data:image/png;base64,${item.image_5}`);
        setLoadedPhotos(resultList);
        forceUpdate();
      }, 50);
  }, []);

  //очистка полей при отмене выбора
  React.useEffect(() => {
    if (takeAway === false) {
      setTypeService(false);
      setIndicateCost(false);
    }

    if (yourSend === false) {
      setTaxi(false);
      setCourier(false);
      setPochta(false);
    }
  }, [takeAway, yourSend, typeService]);

  //определение способов доставки
  React.useEffect(() => {
    if (pickUp) {
      if (pickUp && takeAway && yourSend) {
        setDeliveryType("1, 2, 3");
      } else if (pickUp && takeAway) {
        setDeliveryType("1, 2");
      } else if (pickUp && yourSend) {
        setDeliveryType("1, 3");
      } else if (pickUp) {
        setDeliveryType("1");
      }
    } else if (takeAway) {
      if (takeAway && yourSend) {
        setDeliveryType("2, 3");
      } else if (takeAway) {
        setDeliveryType("2");
      }
    } else if (yourSend) {
      setDeliveryType("3");
    } else setDeliveryType("NONE");
  }, [pickUp, takeAway, yourSend]);

  //определение способов доставки отправкой
  React.useEffect(() => {
    if (taxi) {
      if (taxi && courier && pochta) {
        setWillSendWays("1, 2, 3");
      } else if (taxi && courier) {
        setWillSendWays("1, 2");
      } else if (taxi && pochta) {
        setWillSendWays("1, 3");
      } else if (taxi) {
        setWillSendWays("1");
      }
    } else if (courier) {
      if (courier && pochta) {
        setWillSendWays("2, 3");
      } else if (courier) {
        setWillSendWays("2");
      }
    } else if (pochta) {
      setWillSendWays("3");
    } else setWillSendWays("NONE");
    console.log(willSendWays);
  }, [taxi, courier, pochta, willSendWays]);

  const updateItem = () => {
    if (takeAway && !typeService && !indicateCost) {
      dispatch(openErrorAlert("Не указана сумма за личную доставку!"));
      return;
    }

    Requests.moderatorEditItem(
      item.id,
      editedItemName,
      editedItemDescription,
      category,
      editedKeyWords,
      sendPhotosArray[0] ? sendPhotosArray[0] : null,
      sendPhotosArray[1] ? sendPhotosArray[1] : null,
      sendPhotosArray[2] ? sendPhotosArray[2] : null,
      sendPhotosArray[3] ? sendPhotosArray[3] : null,
      sendPhotosArray[4] ? sendPhotosArray[4] : null,
      placementType === "service",
      deliveryType,
      typeService,
      indicateCost,
      yourSend,
      willSendWays,
      radio
    )
      .then(() => {
        setTextFieldsActive(false);
        dispatch(openSuccessAlert("Успешно!"));
      })
      .catch((err) => dispatch(openErrorAlert(err.response.data)))
      .finally(() => toggleReload());
  };

  const hideItem = () => {
    Requests.moderatorHideItem(item.id)
      .then(() => dispatch(openSuccessAlert("Успешно!")))
      .catch((err) => dispatch(openErrorAlert(err.response.data)))
      .finally(() => toggleReload());
  };

  const showItem = () => {
    Requests.moderatorShowItem(item.id)
      .then(() => dispatch(openSuccessAlert("Успешно!")))
      .catch((err) => dispatch(openErrorAlert(err.response.data)))
      .finally(() => toggleReload(!reload));
  };

  const deleteItem = () => {
    if (window.confirm("Вы уверены?")) {
      Requests.moderatorDeleteItem(item.id)
        .then(() => dispatch(openSuccessAlert("Успешно!")))
        .catch((err) => dispatch(openErrorAlert(err.response.data)))
        .finally(() => toggleReload(!reload));
    }
  };

  //обработчик клика по esc
  const keyDownHandler = React.useCallback((event) => {
    if (event.keyCode === 27) {
      setTextFieldsActive(false);
      toggleReload(!reload);
    }
  });

  //обработка поста в соц сети
  const postItem = () => {
    Requests.moderatorSocialPost(
      item.id,
      `${rootAddress}/item/${item.id}`,
      item.description,
      `data:image/png;base64,${item.image_1}`,
      `${item.image_1_url}`
    )
      .then(() => dispatch(openSuccessAlert("Опубликовано!")))
      .catch((err) => dispatch(openErrorAlert(err.response.data)));
  };

  return (
    <div
      className={"cardthing_rewieus_container_content"}
      onKeyDown={(e) => keyDownHandler(e)}
    >
      <a
        target="_blank"
        href={`/item/${item.id}`}
        style={{ textDecoration: "none" }}
      >
        <img
          alt="logo"
          src={`data:image/png;base64,${item.image_1}`}
          className="logo_photo_rewieus_block"
        />
      </a>
      <div className="rewieus_container_content_info">
        <div className="rewieus_container_content_info_firstblock">
          <div style={{ display: "flex" }}>
            <a
              target="_blank"
              href={`/public-profile?id=${item.profile.id}`}
              style={{ textDecoration: "none" }}
            >
              <p
                style={{ marginRight: "20px" }}
                className="rewieus_container_content_info_firstblock-p1"
              >
                Владелец:{" "}
                {item.profile.status === 1
                  ? item.profile.first_name
                  : item.profile.company_name}
              </p>
            </a>
            <p className="rewieus_container_content_info_firstblock-p2">
              id: {item.id}
            </p>
          </div>
        </div>

        {!textFieldsActive && (
          <div
            style={{ marginTop: "10px" }}
            className="moderate_items_name_item_wrapper"
          >
            <p
              style={{ marginRight: "5px", fontWeight: "500" }}
              className="rewieus_container_content_info_text"
            >
              Наименование:
            </p>
            <p className="moderate_items_name_item_name_item">
              {item.name_item}
            </p>
          </div>
        )}

        {textFieldsActive && (
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            label="Наименование"
            id="outlined-size-small"
            size="small"
            value={editedItemName}
            onChange={(e) => setEditedItemName(e.target.value)}
          />
        )}

        <div
          style={{ marginTop: "10px", marginBottom: "0px" }}
          className="add-item-input-wrapper"
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Тип позиции</FormLabel>
            <RadioGroup
              value={placementType}
              onChange={(e) => setPlacementType(e.target.value)}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="subject"
                control={
                  <Radio
                    checked={placementType === "subject"}
                    disabled={!textFieldsActive}
                  />
                }
                label="Вещь"
              />

              <FormControlLabel
                value="service"
                control={
                  <Radio
                    checked={placementType === "service"}
                    disabled={!textFieldsActive}
                  />
                }
                label="Услуга"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div
          style={{ marginTop: "10px" }}
          className="moderate_items_category_wrapper"
        >
          <div className="add-item-input-wrapper">
            <FormControl
              variant="standard"
              sx={{ m: 1, width: 200, marginLeft: 0 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Раздел
              </InputLabel>
              <Select
                disabled={!textFieldsActive}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={chapter}
                onChange={(e) => setChapter(e.target.value)}
              >
                {isLoaded &&
                  [].concat
                    .apply(Object.entries(chapters))
                    .map((chapter, index) => (
                      <MenuItem key={index} value={chapter[1]}>
                        {chapter[0]}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </div>

          {/*  КАТЕГОРИЯ  */}
          <div className="add-item-input-wrapper">
            <FormControl
              variant="standard"
              sx={{ m: 1, width: 200, marginLeft: 0 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Категория
              </InputLabel>
              <Select
                disabled={!textFieldsActive}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {isLoaded &&
                  [].concat
                    .apply(Object.entries(categories))
                    .map((category, index) => (
                      <MenuItem key={index} value={category[1]}>
                        {category[0]}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {!textFieldsActive && (
          <div
            style={{ marginTop: "10px" }}
            className="moderate_items_name_item_wrapper"
          >
            <p
              style={{ marginRight: "5px", fontWeight: "500" }}
              className="rewieus_container_content_info_text"
            >
              Описание:
            </p>
            <p className="moderate_items_name_item_name_item">
              {item.description}
            </p>
          </div>
        )}

        {textFieldsActive && (
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            multiline
            label="Описание"
            id="outlined-multiline-flexible"
            value={editedItemDescription}
            onChange={(e) => setEditedItemDescription(e.target.value)}
          />
        )}

        {!textFieldsActive && (
          <div
            style={{ marginTop: "10px" }}
            className="moderate_items_name_item_wrapper"
          >
            <p
              style={{ marginRight: "5px", fontWeight: "500" }}
              className="rewieus_container_content_info_text"
            >
              Ключевые слова:
            </p>
            <p className="moderate_items_name_item_name_item">
              {item.key_words}
            </p>
          </div>
        )}

        {textFieldsActive && (
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            label="Ключевые слова"
            id="outlined-size-small"
            size="small"
            value={editedKeyWords}
            onChange={(e) => setEditedKeyWords(e.target.value)}
          />
        )}

        {/*  ЗАГРУЗКА ФОТО  */}
        <div>
          <div>
            <div style={{ position: "relative" }}>
              <div className="add-item-photo-field">
                <div className="add-item-photos">
                  {loadedPhotos
                    ? loadedPhotos.map((photo, index) => (
                        <div key={index} className="add-item-photo-wrapper">
                          {textFieldsActive && (
                            <div
                              onClick={() => removePhotoHandler(index)}
                              className="add-item-photo-remove"
                            >
                              &times;
                            </div>
                          )}
                          <img
                            alt="razdelisdrugim"
                            className="add-item-photo"
                            key={index}
                            src={photo}
                          />
                        </div>
                      ))
                    : ""}
                </div>
              </div>
              {textFieldsActive && (
                <div>
                  <input
                    id={`photo_input${item.id}`}
                    className="input_photo"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png,"
                    multiple
                    onChange={(e) => photoHandler(e)}
                  />
                  <label
                    style={{ left: "0px", fontSize: "16px" }}
                    class="upload-file__label"
                    htmlFor={`photo_input${item.id}`}
                  >
                    Добавить фото
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Доставка */}

        <label style={{ marginTop: "20px" }} className="add-item-input-label">
          {" "}
          {placementType === "service" ? "Место исполнения " : "Вид доставки "}
        </label>
        <div className="checkbox-btn secondary">
          <input type="checkbox" className="input-checkbox" checked={pickUp} />
          <span
            style={!textFieldsActive ? { pointerEvents: "none" } : {}}
            onClick={(e) => pickupHandler(e.target.value)}
          >
            {" "}
            {placementType === "service" ? "По месту исполнителя" : "Самовывоз"}
          </span>
        </div>

        {/*  ПРИВЕЗУ/ЗАБЕРУ САМ  */}
        <div
          style={{ flexDirection: "column" }}
          className="add-item-delivery-type-wrapper"
        >
          <div className="checkbox-btn secondary">
            <input
              type="checkbox"
              className="input-checkbox"
              checked={takeAway}
            />
            <span
              style={!textFieldsActive ? { pointerEvents: "none" } : {}}
              onClick={(e) => takeAwayHandler(e.target.value)}
            >
              {placementType === "service"
                ? "По месту заказчика"
                : "Привезу и заберу сам"}
            </span>
          </div>

          {takeAway && (
            <div
              className="take-away-secondary-wrapper"
              id="take-away-secondary-wrapper"
            >
              <div className="checkbox-btn secondary">
                <input
                  type="checkbox"
                  className="input-checkbox"
                  checked={typeService}
                />
                <span onClick={() => setTypeService(!typeService)}>
                  Бесплатно
                </span>
              </div>
              <span className="add-item-cost-or__secondary">или</span>
              <span className="add-item-input-label__lower">
                Указать стоимость
              </span>
              <input
                disabled={typeService}
                type="number"
                max="9999"
                step="any"
                placeholder="0.00"
                className="add-item-input-number__secondary"
                value={indicateCost}
                onChange={(e) => setIndicateCostHandler(e)}
              />
              <span className="span-valuts">BYN</span>
            </div>
          )}
        </div>

        {/*  ОТПРАВЛЮ  */}
        {placementType === "subject" && (
          <span className="take-away-secondary-wrapper">
            <div className="take-away-secondary-wrapper-column">
              <div className="take-away-secondary-wrapper">
                <div className="checkbox-btn secondary">
                  <input
                    type="checkbox"
                    className="input-checkbox"
                    checked={yourSend}
                  />
                  <span
                    style={!textFieldsActive ? { pointerEvents: "none" } : {}}
                    onClick={(e) => yourSendHandler(e.target.value)}
                  >
                    Отправлю
                  </span>
                </div>

                {yourSend && (
                  <span
                    className="take-away-secondary-wrapper"
                    id="take-away-secondary-wrapper"
                  >
                    <div
                      className="checkbox-btn secondary"
                      id="checkbox-btn-margin"
                    >
                      <input
                        type="checkbox"
                        className="input-checkbox"
                        checked={taxi}
                      />
                      <span onClick={(e) => taxiHandler(e.target.value)}>
                        Такси
                      </span>
                    </div>

                    <div
                      className="checkbox-btn secondary"
                      id="checkbox-btn-margin"
                    >
                      <input
                        type="checkbox"
                        className="input-checkbox"
                        checked={courier}
                      />
                      <span onClick={(e) => courierHandler(e.target.value)}>
                        Курьер
                      </span>
                    </div>

                    <div className="checkbox-btn secondary">
                      <input
                        type="checkbox"
                        className="input-checkbox"
                        checked={pochta}
                      />
                      <span onClick={(e) => pochtaHandler(e.target.value)}>
                        Почта
                      </span>
                    </div>
                  </span>
                )}
              </div>

              {(taxi || courier || pochta) && (
                <div
                  className="add-item-radio-wrapper"
                  onChange={(e) => radioHandler(e)}
                >
                  <span class="form_radio_btn">
                    <input
                      defaultChecked={radio === "OWNER"}
                      id="radio-1"
                      type="radio"
                      name="radio_choice"
                      value="OWNER"
                    />
                    <label for="radio-1">За счёт Владельца</label>
                  </span>

                  <span class="form_radio_btn">
                    <input
                      defaultChecked={radio === "RENTER"}
                      id="radio-2"
                      type="radio"
                      name="radio_choice"
                      value="RENTER"
                    />
                    <label for="radio-2">За счёт арендатора</label>
                  </span>
                </div>
              )}
            </div>
          </span>
        )}

        <div className="reviews_moderate_lower_buttons">
          {textFieldsActive && (
            <div className="item-card-profile-button-wrapper">
              <p
                style={{ marginLeft: "0px" }}
                type="button"
                className="item-card-profile-button__optional"
                onClick={updateItem}
              >
                Сохранить
              </p>
            </div>
          )}

          {textFieldsActive && (
            <div className="item-card-profile-button-wrapper">
              <p
                style={{ marginLeft: "0px" }}
                type="button"
                className="item-card-profile-button__optional"
                onClick={() => {
                  toggleReload(!reload);
                  setTextFieldsActive(false);
                }}
              >
                Отменить
              </p>
            </div>
          )}

          {!textFieldsActive && (
            <div className="item-card-profile-button-wrapper">
              <p
                style={{ marginLeft: "0px" }}
                type="button"
                className="item-card-profile-button__optional"
                onClick={() => setTextFieldsActive(true)}
              >
                Редактировать
              </p>
            </div>
          )}
          {item.is_hidden && (
            <div
              onClick={showItem}
              className="item-card-profile-button-wrapper"
            >
              <img
                alt="razdelisdrugim"
                src={hide}
                className="item-card-profile-button-image"
              />
              <p
                value="Показать"
                type="button"
                className="item-card-profile-button__optional"
              >
                Показать
              </p>
            </div>
          )}

          {!item.is_hidden && (
            <div
              onClick={hideItem}
              className="item-card-profile-button-wrapper"
            >
              <img
                alt="razdelisdrugim"
                src={hide}
                className="item-card-profile-button-image"
              />
              <p
                value="Показать"
                type="button"
                className="item-card-profile-button__optional"
              >
                Скрыть
              </p>
            </div>
          )}
          <div
            onClick={deleteItem}
            className="item-card-profile-button-wrapper"
          >
            <img
              alt="razdelisdrugim"
              src={Delete}
              className="item-card-profile-button-image"
            />
            <p
              value="Удалить"
              type="button"
              className="item-card-profile-button__optional"
            >
              Удалить
            </p>
          </div>
          <div onClick={postItem} className="item-card-profile-button-wrapper">
            <p
              value="Удалить"
              type="button"
              className="item-card-profile-button__optional"
            >
              Опубликовать в соц. сети{" "}
              {item.is_post && (
                <span
                  className="item-card-profile-button__optional"
                  style={{ color: "red", marginLeft: "10px" }}
                >
                  Был репост
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemReviewModerateComponent;
