import React from "react";
import { Header, Footer } from "../../components/index";
import "./footer-pages.css";
import { Helmet } from "react-helmet";
import { rootAddress } from "../../http/axios-requests";

const HowToRent = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CardThings">
      <Helmet>
        <link rel="canonical" href={`https://razdelisdrugim.by/how-to-rent`} />
        <title>Как взять в аренду?: #разделисдругим</title>
        <meta
          name="description"
          content="Сервис #Разделисдругим прост и удобен в использовании. Вам не
                нужно тратить свое время на длительный поиск в прокатных центрах
                города.  1. Воспользуйтесь поисковой строкой в шапке
                сайта или перейдите в необходимый раздел каталога.
               2. Выберите объект и свяжитесь с владельцем. 3.
                Свяжитесь с владельцем, заключите соглашение, пользуйтесь на
                здоровье."
        />
        <meta
          name="keywords"
          content="Сервис #Разделисдругим прост и удобен в использовании. Вам не
                нужно тратить свое время на длительный поиск в прокатных центрах
                города.  1. Воспользуйтесь поисковой строкой в шапке
                сайта или перейдите в необходимый раздел каталога.
               2. Выберите объект и свяжитесь с владельцем. 3.
                Свяжитесь с владельцем, заключите соглашение, пользуйтесь на
                здоровье."
        />
        <meta
          property="og:title"
          content={`Как взять в аренду?: #разделисдругим`}
        />
        <meta
          property="title"
          content={`Как взять в аренду?: #разделисдругим`}
        />
        <meta property="og:site_name" content="Razdelisdrugim.by" />
        <meta
          property="og:description"
          content="Сервис #Разделисдругим прост и удобен в использовании. Вам не
                нужно тратить свое время на длительный поиск в прокатных центрах
                города.  1. Воспользуйтесь поисковой строкой в шапке
                сайта или перейдите в необходимый раздел каталога.
               2. Выберите объект и свяжитесь с владельцем. 3.
                Свяжитесь с владельцем, заключите соглашение, пользуйтесь на
                здоровье."
        />

        <meta
          property="og:url"
          content={`https://razdelisdrugim.by/how-to-rent`}
        />
        <meta
          property="og:image"
          content={`${rootAddress}/media/favicon.ico`}
          data-react-helmet="true"
        />
      </Helmet>
      <Header />
      <div className="CardThings_Wrapper">
        <div className="CardThings_Wrapper_container">
          <div className="container_content_card">
            <div className="card_content_footer_pages">
              <h1 className="footer_pages_main_title">Как взять в аренду?</h1>
              <p className="footer_pages_main_p">
                Сервис #Разделисдругим прост и удобен в использовании. Вам не
                нужно тратить свое время на длительный поиск в прокатных центрах
                города. <br></br> 1. Воспользуйтесь поисковой строкой в шапке
                сайта или перейдите в необходимый раздел каталога.
                <br></br>2. Выберите объект и свяжитесь с владельцем.<br></br>3.
                Свяжитесь с владельцем, заключите соглашение, пользуйтесь на
                здоровье.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowToRent;
