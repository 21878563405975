import React from "react";
import "./Notifications.css";
import { useSelector, useDispatch } from "react-redux";
import Requests from "../../../../http/axios-requests";
import {
  openErrorAlert,
  openSuccessAlert,
  reloadData,
} from "../../../../redux/actions/userData";

const Notifications = () => {
  const dispatch = useDispatch();

  const [newMessageActive, setNewMessageActive] = React.useState();
  const [reserveActive, setReserveActive] = React.useState();
  const [reserveChangeActive, setReserveChaneActive] = React.useState();
  const [newReviewActive, setNewReviewActive] = React.useState();

  const { userData, reload } = useSelector(({ userData }) => userData);

  React.useEffect(() => {
    userData.id && setNewMessageActive(userData.is_new_message);
    userData.id && setReserveActive(userData.is_new_reserve);
    userData.id && setReserveChaneActive(userData.is_change_reserve);
    userData.id && setNewReviewActive(userData.is_new_review);
  }, [userData]);

  const updateNotificationsSet = () => {
    Requests.updateNotifications({
      message: newMessageActive,
      reserve: reserveActive,
      changeReserve: reserveChangeActive,
      review: newReviewActive,
      first_name: userData.first_name
        ? userData.first_name
        : userData.company_name,
      last_name: userData.last_name ? userData.last_name : "",
      sex: userData.sex,
      date_birthday: userData.date_birthday,
      email: userData.email,
      phone: userData.phone,
    })
      .then(() => {
        dispatch(openSuccessAlert("Настройки обновлены!"));
        dispatch(reloadData(!reload));
      })
      .catch(() =>
        dispatch(openErrorAlert("Произошла ошибка. Попробуйте еще раз."))
      );
  };

  return (
    <div className="container_myAddresses_content">
      <div className="notifications_header">
        <h1 className="notifications_title">Управление рассылками</h1>
      </div>
      <div className="notifications_body">
        <div className="notifications_body_header">
          <h2 className="notifications_body_header_title">
            Присылать по E-mail
          </h2>
          <div className="notifications_body_checkboxes">
            <div className="add-item-ready-sell-wrapper">
              <input
                className="add-item-input-checkbox_notify"
                type="checkbox"
                id="add-item-input-checkbox__1"
                checked={newMessageActive}
                onChange={() => setNewMessageActive(!newMessageActive)}
              />
              <label htmlFor="add-item-input-checkbox__1">
                 Уведомления о новых сообщениях
              </label>
            </div>

            <div className="add-item-ready-sell-wrapper">
              <input
                className="add-item-input-checkbox_notify"
                type="checkbox"
                id="add-item-input-checkbox__2"
                checked={reserveActive}
                onChange={() => setReserveActive(!reserveActive)}
              />
              <label htmlFor="add-item-input-checkbox__2">
                Уведомления о новых бронирваниях
              </label>
            </div>

            <div className="add-item-ready-sell-wrapper">
              <input
                className="add-item-input-checkbox_notify"
                type="checkbox"
                id="add-item-input-checkbox__3"
                checked={reserveChangeActive}
                onChange={() => setReserveChaneActive(!reserveChangeActive)}
              />
              <label htmlFor="add-item-input-checkbox__3">
                Уведомления об изменении условий бронирования
              </label>
            </div>

            <div className="add-item-ready-sell-wrapper">
              <input
                className="add-item-input-checkbox_notify"
                type="checkbox"
                id="add-item-input-checkbox__4"
                checked={newReviewActive}
                onChange={() => setNewReviewActive(!newReviewActive)}
              />
              <label htmlFor="add-item-input-checkbox__4">
                Уведомления о новых отзывах
              </label>
            </div>
            <input
              value="Сохранить"
              type="button"
              className=" button_download"
              onClick={updateNotificationsSet}
            />
          </div>
        </div>
      </div>
      <div className="notifications_body border">
        <div className="notifications_body_header">
          {" "}
          <h2 className="notifications_body_header_title">
            Настройка уведомлений Telegram
          </h2>
          <p className="notifications_body_header_p">
            {" "}
            Пройдите по{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="notifications_body_header_p link"
              href="https://t.me/razdelisdrugim_bot"
            >
              ссылке
            </a>{" "}
            и нажмите кнопку START в чате, чтобы привязать ваш аккаунт к
            telegram-боту.{" "}
          </p>
        </div>{" "}
      </div>

      <div className="notifications_body">
        <div className="notifications_body_header">
          <h2 className="notifications_body_header_title">
            Настройка уведомлений Viber
          </h2>
          <p className="notifications_body_header_p">
            Пройдите по{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="viber://pa?chatURI=razdelisdrugim"
              className="notifications_body_header_p link"
            >
              ссылке
            </a>{" "}
            и нажмите кнопку START в чате, чтобы привязать ваш аккаунт к
            viber-боту.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
