import React from "react";
import Shape from "../../img/Shape.png";
import Visa from "../../img/Wallet/visa.png";
import Mastercard from "../../img/Wallet/mc.png";
import Belcart from "../../img/Wallet/belcart.png";
import Vector from "../../img/Wallet/vector.png";
import Requests from "../../http/axios-requests";
import { useSelector, useDispatch } from "react-redux";
import { openErrorAlert } from "../../redux/actions/userData";

const WalletPayModal = ({ modalActivePay, setModalActivePay }) => {
  const dispatch = useDispatch();

  const [activePaymentMethod, setActivePaymentMethod] = React.useState(1);
  const [activePaymentSumSelect, setActivePaymentSumSelect] =
    React.useState(null);
  const [activePaymentSumInput, setActivePaymentSumInput] = React.useState();

  const { userData } = useSelector(({ userData }) => userData);

  const redirectPayment = () => {
    if (!activePaymentSumInput && !activePaymentSumSelect) {
      dispatch(openErrorAlert("Не указана сумма пополнения!"));
      return;
    }

    Requests.getRedirectPaymentURL(
      userData.id + "-" + convertDate(new Date()),
      activePaymentSumSelect
        ? activePaymentSumSelect
        : activePaymentSumInput * 100
    )
      .then(
        (res) => (window.location.href = `${res.data.checkout.redirect_url}`)
      )
      .catch((e) => dispatch(openErrorAlert(`${e.response.data.message}`)));
  };

  const convertDate = (str) => {
    str = str.toString();
    let parts = str.split(" ");
    let months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    return parts[3] + "-" + months[parts[1]] + "-" + parts[2] + "T" + parts[4];
  };

  const sumSelectHandler = (sum) => {
    setActivePaymentSumInput("");
    setActivePaymentSumSelect(sum);
  };

  const sumInputHandler = (e) => {
    if (
      e.target.value.includes("-") ||
      e.target.value.includes("0") ||
      e.target.value.includes(" ") ||
      e.target.value.includes("e") ||
      e.target.value.includes(".") ||
      e.target.value.includes(",")
    ) {
      return;
    }
    setActivePaymentSumInput(e.target.value);
    setActivePaymentSumSelect(null);
  };

  return (
    <div
      className={
        modalActivePay ? "reg-auth-wrapper active" : "reg-auth-wrapper"
      }
      style={{ display: "flex" }}
      onClick={() => setModalActivePay(false)}
    >
      <div className="reg-content">
        <div onClick={(e) => e.stopPropagation()} className="reg-form-wrapper">
          <div className="reviews_form">
            <div className="reviews_modal_header">
              <h1 className="reviews_main_title">Пополнить кошелек</h1>
              <img
                alt="razdelisdrugim"
                onClick={() => setModalActivePay(false)}
                src={Shape}
                className="img_krestik"
                style={{ marginLeft: "0" }}
              />
            </div>
            <div className="wallet_pay_content">
              <div className="wallet_pay_content_way_choice">
                <p className="wallet_pay_content_way_choice_p">
                  Выберите способ пополнения
                </p>
                <div className="wallet_pay_content_way_choice_cards">
                  <div
                    className={
                      activePaymentMethod === 1
                        ? "wallet_pay_content_way_choice_card active"
                        : "wallet_pay_content_way_choice_card"
                    }
                    onClick={() => setActivePaymentMethod(1)}
                  >
                    <p className="wallet_pay_content_way_choice_card_title">
                      Банковская карта
                    </p>
                    <div className="wallet_pay_content_way_choice_card_img_wrapper">
                      <img
                        src={Visa}
                        className="wallet_pay_content_way_choice_card_img"
                        alt="bank card"
                      />
                      <img
                        src={Mastercard}
                        className="wallet_pay_content_way_choice_card_img"
                        alt="bank card"
                      />
                      <img
                        src={Belcart}
                        className="wallet_pay_content_way_choice_card_img"
                        alt="bank card"
                      />
                    </div>
                    {activePaymentMethod === 1 && (
                      <img
                        src={Vector}
                        className="wallet_pay_content_way_choice_card_vector"
                        alt="payment method choice"
                      />
                    )}
                  </div>

                  <div
                    className={
                      activePaymentMethod === 2
                        ? "wallet_pay_content_way_choice_card active"
                        : "wallet_pay_content_way_choice_card"
                    }
                    style={{ backgroundColor: "rgb(139 159 165 / 14%)" }}
                    /* onClick={() => setActivePaymentMethod(2)} */
                  >
                    <h1 className="wallet_pay_content_way_choice_card_title">
                      Баланс телефона
                    </h1>
                    <p className="wallet_pay_content_way_choice_card_p">
                      Через SMS, комиссия зависит от оператора
                    </p>
                    {activePaymentMethod === 2 && (
                      <img
                        src={Vector}
                        className="wallet_pay_content_way_choice_card_vector"
                        alt="payment method choice"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="wallet_pay_content_sum_choice">
                <p className="wallet_pay_content_sum_choice_title">
                  Сумма пополнения
                </p>
                <div className="wallet_pay_content_sum_choice_number_wrapper">
                  <div className="wallet_pay_content_sum_choice_number_wrapper_separate">
                    <div
                      onClick={() => sumSelectHandler(500)}
                      className={
                        activePaymentSumSelect === 500
                          ? "wallet_pay_content_sum_choice_number active"
                          : "wallet_pay_content_sum_choice_number"
                      }
                    >
                      5 BYN
                    </div>
                    <div
                      onClick={() => sumSelectHandler(1000)}
                      className={
                        activePaymentSumSelect === 1000
                          ? "wallet_pay_content_sum_choice_number active"
                          : "wallet_pay_content_sum_choice_number"
                      }
                    >
                      10 BYN
                    </div>
                    <div
                      onClick={() => sumSelectHandler(1500)}
                      className={
                        activePaymentSumSelect === 1500
                          ? "wallet_pay_content_sum_choice_number active"
                          : "wallet_pay_content_sum_choice_number"
                      }
                    >
                      15 BYN
                    </div>
                  </div>
                  <div className="wallet_pay_content_sum_choice_number_wrapper_separate">
                    <div
                      onClick={() => sumSelectHandler(2000)}
                      className={
                        activePaymentSumSelect === 2000
                          ? "wallet_pay_content_sum_choice_number active"
                          : "wallet_pay_content_sum_choice_number"
                      }
                    >
                      20 BYN
                    </div>
                    <div
                      onClick={() => sumSelectHandler(3000)}
                      className={
                        activePaymentSumSelect === 3000
                          ? "wallet_pay_content_sum_choice_number active"
                          : "wallet_pay_content_sum_choice_number"
                      }
                    >
                      30 BYN
                    </div>
                    <div
                      onClick={() => sumSelectHandler(5000)}
                      className={
                        activePaymentSumSelect === 5000
                          ? "wallet_pay_content_sum_choice_number active"
                          : "wallet_pay_content_sum_choice_number"
                      }
                    >
                      50 BYN
                    </div>
                  </div>
                </div>
                {/*  <p className="wallet_pay_content_sum_choice_subtitle">
                  или укажите сумму
                </p>
                <input
                  placeholder="Введите сумму, BYN"
                  type="number"
                  className="wallet_pay_content_sum_choice_input"
                  value={activePaymentSumInput}
                  onChange={(e) => sumInputHandler(e)}
                /> */}
              </div>
              <input
                value="Оплатить"
                type="button"
                className="wallet_pay_content_button_send"
                onClick={redirectPayment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletPayModal;
