import React from "react";
import Requests from "../../http/axios-requests";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Statistics = () => {
  const [stats, setStats] = React.useState();
  const [statsParameter, setStatsParameter] = React.useState("today");

  React.useEffect(() => {
    Requests.getModeratorStatistics(statsParameter).then((res) =>
      setStats(JSON.parse(res.data))
    );
  }, [statsParameter]);

  return (
    <div className="admin_reviews_wrapper">
      <div className="admin_reviews_body_wrapper">
        <div className="admin_reviews_optional_row">
          <p className="admin_reviews_optional_row_title"></p>
          <FormControl component="fieldset">
            <FormLabel component="legend">Период</FormLabel>
            <RadioGroup
              onChange={(e) => setStatsParameter(e.target.value)}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
            >
              {" "}
              <FormControlLabel
                value="today"
                checked={statsParameter === "today"}
                control={<Radio color="secondary" />}
                label="Сегодня"
              />
              <FormControlLabel
                value="week"
                checked={statsParameter === "week"}
                control={<Radio color="secondary" />}
                label="Неделя"
              />
              <FormControlLabel
                value="month"
                checked={statsParameter === "month"}
                control={<Radio color="secondary" />}
                label="Месяц"
              />
              <FormControlLabel
                value="all"
                checked={statsParameter === "all"}
                control={<Radio color="secondary" />}
                label="Все время"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="admin_reviews_body_cards">
          <div
            style={{ flexDirection: "column" }}
            className={"cardthing_rewieus_container_content"}
          >
            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">Все позиции:</p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.all_items}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">Вещи:</p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.items}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">Услуги:</p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.services}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">Все пользователи:</p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.all_user}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">
                Не заблокированные пользователи:
              </p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.users}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">
                Ожидающие бронирования:
              </p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.waiting_reserve}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">
                Подтвержденные бронирования:
              </p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.submitted_reserve}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">
                Отклоненные бронирования:
              </p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.denied_reservations}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">
                Завершенные бронирования:
              </p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.completed_reservations}
              </p>
            </div>

            <div className="admin_reviews_body_stats_wrapper">
              <p className="admin_reviews_body_stats_key">Сообщения:</p>
              <p className="admin_reviews_body_stats_value">
                {stats && stats.messages}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
