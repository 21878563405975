import React from "react";
import { useSelector } from "react-redux";
import Fade from "@mui/material/Fade";

const FirstBlockNavigationTitleTransition = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [slideIsVisible, setSlideIsVisible] = React.useState(true);

  const { mainPagePhrases } = useSelector(({ settings }) => settings);

  const changeSlideHandler = () => {
    if (currentSlide >= mainPagePhrases.length - 1) {
      setCurrentSlide(0);
    } else if (currentSlide < mainPagePhrases.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  React.useEffect(() => {
    if (slideIsVisible) {
      setTimeout(() => {
        setSlideIsVisible(!slideIsVisible);
      }, 3000);
    } else {
      setTimeout(() => {
        changeSlideHandler();
      }, 200);
      setTimeout(() => {
        setSlideIsVisible(!slideIsVisible);
      }, 500);
    }
  }, [slideIsVisible]);

  return (
    <Fade in={slideIsVisible}>
      <p className="main_page_fris_block_left_second_p">
        {mainPagePhrases.length >= 1 && mainPagePhrases[currentSlide]}
      </p>
    </Fade>
  );
};

export default FirstBlockNavigationTitleTransition;
