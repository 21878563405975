import React from "react";
import { Link } from "react-router-dom";
import FirstImage from "../../img/MainPage/first_block_image.webp";
import FirstBlockNavigationTitleTransition from "./FirstBlockNavigationTitleTransition";
import { ProposePositionButton } from "..";
import { useSelector } from "react-redux";

const FirstBlockNavigation = () => {
  const { mainPageSecondaryPhrase } = useSelector(({ settings }) => settings);

  return (
    <section className="recent-wrapper">
      <div className="main_page_first_content">
        <div className="main_page_fris_block_left">
          <h1 className="main_page_fris_block_left_main_p">#РАЗДЕЛИСДРУГИМ</h1>
          <h2 className="main_page_fris_block_left_secondary_p">
            {mainPageSecondaryPhrase}
          </h2>
          <FirstBlockNavigationTitleTransition />
          <img alt="razdelisdrugim" src={FirstImage} className="FirstImage2" />
          <div
            style={{ justifyContent: "flex-start" }}
            className="main_page_first_block_left_bottons"
          >
            <Link style={{ textDecoration: "none" }} to="/search">
              <input
                type="button"
                value="Найти вещь"
                className="header-button add-subject2"
                id="add-subject2"
              />
            </Link>
            {window.screen.width > 420 && <ProposePositionButton />}
          </div>
        </div>
        <img alt="razdelisdrugim" src={FirstImage} className="FirstImage1" />
      </div>
    </section>
  );
};

export default FirstBlockNavigation;
