import React from "react";
import Star from "../../img/ProfilePage/Star 5.png";
import disableStar from "../../img/ProfilePage/Star 1.png";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Requests from "../../http/axios-requests";
import Delete from "../../img/MainPage/delete.webp";
import hide from "../../img/MainPage/hide.webp";

const ItemReviewModerateComponent = ({
  item,
  showOnlyHidden,
  reloadReviews,
  toggleReloadReviews,
}) => {
  const hideReview = () => {
    Requests.moderatorHideItemReview(item.id).then(() => {
      toggleReloadReviews(!reloadReviews);
    });
  };

  const showReview = () => {
    Requests.moderatorShowItemReview(item.id).then(() => {
      toggleReloadReviews(!reloadReviews);
    });
  };

  const deleteReview = () => {
    if (window.confirm("Вы уверены?")) {
      Requests.moderatorDeleteItemReview(item.id).then(() => {
        toggleReloadReviews(!reloadReviews);
      });
    }
  };

  const editReview = () => {
    Requests.moderatorEditItemReview(item.id, editedContent).then(() => {
      setTextFieldActive(false);
      toggleReloadReviews(!reloadReviews);
    });
  };

  const [textFielsActive, setTextFieldActive] = React.useState(false);
  const [editedContent, setEditedContent] = React.useState(item.content);

  return (
    <div
      style={
        showOnlyHidden === "true" && !item.is_hidden_review
          ? { display: "none" }
          : {}
      }
      className={
        item.mark <= 2
          ? "cardthing_rewieus_container_content low_ranked"
          : "cardthing_rewieus_container_content"
      }
    >
      <a
        href={`/item/${item.item.id}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <img
          src={`data:image/png;base64,${item.item.image_1}`}
          alt="logo"
          className="logo_photo_rewieus_block"
        />
      </a>
      <div className="rewieus_container_content_info">
        <div className="rewieus_container_content_info_firstblock">
          <div style={{ display: "flex" }}>
            <a
              href={`/public-profile?id=${item.item.profile.id}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <p
                style={{ marginRight: "20px" }}
                className="rewieus_container_content_info_firstblock-p1"
              >
                Владелец:{" "}
                {item.item.profile.status === 1
                  ? item.item.profile.first_name
                  : item.item.profile.status === 2
                  ? item.item.profile.company_name
                  : ""}
              </p>
            </a>
            <a
              href={`/public-profile?id=${item.author_comment.id}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <p className="rewieus_container_content_info_firstblock-p1">
                Отправитель:{" "}
                {item.author_comment.status === 1
                  ? item.author_comment.first_name
                  : item.author_comment.status === 2
                  ? item.author_comment.company_name
                  : ""}
              </p>
            </a>
          </div>
          <p className="rewieus_container_content_info_firstblock-p2">
            {`${item.comment_date
              .split("")
              .splice(8, 2)
              .join("")}.${item.comment_date
              .split("")
              .splice(5, 2)
              .join("")}.${item.comment_date.split("").splice(0, 4).join("")}`}
          </p>
        </div>
        <div className="rewieus_container_content_info_rating">
          <img
            src={item.mark >= 1 ? Star : disableStar}
            alt="disable_star"
            className="rewieus_star"
          />
          <img
            src={item.mark >= 2 ? Star : disableStar}
            alt="disable_star"
            className="rewieus_star"
          />
          <img
            src={item.mark >= 3 ? Star : disableStar}
            alt="disable_star"
            className="rewieus_star"
          />
          <img
            src={item.mark >= 4 ? Star : disableStar}
            alt="disable_star"
            className="rewieus_star"
          />
          <img
            src={item.mark >= 5 ? Star : disableStar}
            alt="disable_star"
            className="rewieus_star"
          />
        </div>
        {!textFielsActive && (
          <p className="rewieus_container_content_info_text">{item.content}</p>
        )}
        {textFielsActive && (
          <TextField
            fullWidth
            label="Редактирование"
            id="fullWidth"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
        )}

        <div className="reviews_moderate_lower_buttons">
          {textFielsActive && (
            <div className="item-card-profile-button-wrapper">
              <p
                onClick={editReview}
                value="Показать"
                type="button"
                className="item-card-profile-button__optional"
              >
                Сохранить
              </p>
            </div>
          )}
          <div
            onClick={() => {
              if (textFielsActive) {
                setEditedContent(item.content);
              }
              setTextFieldActive(!textFielsActive);
            }}
            className="item-card-profile-button-wrapper"
          >
            <p
              value="Показать"
              type="button"
              className="item-card-profile-button__optional"
            >
              {textFielsActive ? "Отмена" : "Редатировать"}
            </p>
          </div>
          {item.is_hidden_review && (
            <div
              onClick={showReview}
              className="item-card-profile-button-wrapper"
            >
              <img
                alt="razdelisdrugim"
                src={hide}
                className="item-card-profile-button-image"
              />
              <p
                value="Показать"
                type="button"
                className="item-card-profile-button__optional"
              >
                Показать
              </p>
            </div>
          )}
          {!item.is_hidden_review && (
            <div
              onClick={hideReview}
              className="item-card-profile-button-wrapper"
            >
              <img
                alt="razdelisdrugim"
                src={hide}
                className="item-card-profile-button-image"
              />
              <p
                value="Показать"
                type="button"
                className="item-card-profile-button__optional"
              >
                Скрыть
              </p>
            </div>
          )}
          <div
            onClick={deleteReview}
            className="item-card-profile-button-wrapper"
          >
            <img
              alt="razdelisdrugim"
              src={Delete}
              className="item-card-profile-button-image"
            />
            <p
              value="Удалить"
              type="button"
              className="item-card-profile-button__optional"
            >
              Удалить
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemReviewModerateComponent;
