import React from "react";
import "../../../css/Wallet.scss";
import Wallet from "../../../img/Wallet/wallet.png";
import inProcess from "../../../img/inProcess.jpg";
import { useSelector, useDispatch } from "react-redux";
import Requests from "../../../http/axios-requests";
import {
  openSuccessAlert,
  openErrorAlert,
} from "../../../redux/actions/userData";

const WalletPage = ({ setModalActivePay }) => {
  const { userData } = useSelector(({ userData }) => userData);

  const dispatch = useDispatch();

  //получаем список транзакций
  React.useEffect(() => {
    Requests.getTransactions().then((res) => {
      setTransactions(res.data);
    });

    if (window.location.href.includes("success")) {
      dispatch(openSuccessAlert("Пополнение кошелька прошло успешно!"));
    } else if (window.location.href.includes("failed")) {
      dispatch(
        openErrorAlert(
          "Произошла ошибка при попытке пополнить кошелек. Обратитесь в техподдержку!"
        )
      );
    }
  }, []);

  const [transactions, setTransactions] = React.useState();

  return (
    <div>
      {false && (
        <div>
          <img
            style={{ width: "100%", height: "100%" }}
            src={inProcess}
            alt=""
          />
        </div>
      )}
      {true && (
        <div>
          <div className="container_myAddresses_content" id="globaldata_pk">
            <h1 className="wallet_main_h1">Мой кошелек</h1>
            <div className="wallet_balance_container">
              <p className="wallet_balance_container_p">Баланс</p>
              <div className="wallet_balance_container_count_wrapper">
                <img
                  src={Wallet}
                  className="wallet_balance_container_count_wrapper_img"
                  alt="wallet image"
                />
                <p className="wallet_balance_container_count_wrapper_p">
                  {userData && userData.amount_money === 0
                    ? "0.00"
                    : userData.amount_money + ".00"}{" "}
                  BYN
                </p>
              </div>
              <div className="wallet_balance_container_buttons_wrapper">
                <input
                  value="Пополнить"
                  className="wallet_balance_container_buttons_wrapper_button_add"
                  type="button"
                  onClick={() => setModalActivePay(true)}
                />
                <input
                  value="Купить услугу"
                  className="wallet_balance_container_buttons_wrapper_button_buy"
                  type="button"
                />
              </div>
            </div>
            <div className="wallet_history">
              <p className="wallet_history_p">История операции</p>
              <div className="wallet_history_options_wrapper">
                <select
                  placeholder="Все операции"
                  type="select"
                  className="wallet_history_options_wrapper_select"
                />
                <select
                  placeholder="За все время"
                  type="select"
                  className="wallet_history_options_wrapper_select"
                />
                <div className="wallet_history_options_wrapper_date_wrapper">
                  <p className="wallet_history_options_wrapper_date_wrapper_p">
                    Дата с:
                  </p>
                  <input
                    type="date"
                    className="wallet_history_options_wrapper_date"
                  />
                </div>
                <div className="wallet_history_options_wrapper_date_wrapper">
                  <p className="wallet_history_options_wrapper_date_wrapper_p">
                    Дата по:
                  </p>
                  <input
                    type="date"
                    className="wallet_history_options_wrapper_date"
                  />
                </div>
                <input
                  value="Показать"
                  type="button"
                  className="wallet_history_options_wrapper_button"
                />
              </div>
              <div className="wallet_history_stats_wrapper">
                <p className="wallet_history_stats_wrapper_p">
                  Всего зачислено за период:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    20 BYN
                  </span>
                </p>
                <p className="wallet_history_stats_wrapper_p">
                  Всего потрачено за период:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    0 BYN
                  </span>
                </p>
                <p className="wallet_history_stats_wrapper_p">
                  Входящий остаток:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    0 BYN
                  </span>
                </p>
              </div>
            </div>
            {/*  <WalletTable transactions={transactions} /> */}
          </div>

          {/* MOBILE */}
          <div className="container_myAddresses_content" id="globaldata_mobile">
            <h1 className="wallet_main_h1">Мой кошелек</h1>
            <div className="wallet_balance_container">
              <p className="wallet_balance_container_p">Баланс</p>
              <div className="wallet_balance_container_count_wrapper">
                <img
                  src={Wallet}
                  className="wallet_balance_container_count_wrapper_img"
                  alt="wallet image"
                />
                <p className="wallet_balance_container_count_wrapper_p">
                  0.00 BYN
                </p>
              </div>
              <div className="wallet_balance_container_buttons_wrapper">
                <input
                  value="Пополнить"
                  className="wallet_balance_container_buttons_wrapper_button_add"
                  type="button"
                  onClick={() => setModalActivePay(true)}
                />
                <input
                  value="Купить услугу"
                  className="wallet_balance_container_buttons_wrapper_button_buy"
                  type="button"
                />
              </div>
            </div>
            <div className="wallet_history">
              <p className="wallet_history_p">История операции</p>
              <div className="wallet_history_options_wrapper">
                <div className="wallet_history_options_wrapper-row">
                  <select
                    placeholder="Все операции"
                    type="select"
                    className="wallet_history_options_wrapper_select"
                  />
                  <select
                    placeholder="За все время"
                    type="select"
                    className="wallet_history_options_wrapper_select"
                  />
                </div>

                <div className="wallet_history_options_wrapper-row">
                  <div className="wallet_history_options_wrapper_date_wrapper">
                    <p className="wallet_history_options_wrapper_date_wrapper_p">
                      Дата с:
                    </p>
                    <input
                      type="date"
                      className="wallet_history_options_wrapper_date"
                    />
                  </div>
                  <div className="wallet_history_options_wrapper_date_wrapper">
                    <p className="wallet_history_options_wrapper_date_wrapper_p">
                      Дата по:
                    </p>
                    <input
                      type="date"
                      className="wallet_history_options_wrapper_date"
                    />
                  </div>
                </div>

                <input
                  value="Показать"
                  type="button"
                  className="wallet_history_options_wrapper_button"
                />
              </div>
              <div className="wallet_history_stats_wrapper">
                <p className="wallet_history_stats_wrapper_p">
                  Всего зачислено за период:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    20 BYN
                  </span>
                </p>
                <p className="wallet_history_stats_wrapper_p">
                  Всего потрачено за период:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    0 BYN
                  </span>
                </p>
                <p className="wallet_history_stats_wrapper_p">
                  Входящий остаток:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    0 BYN
                  </span>
                </p>
              </div>
            </div>
            {/*  <WalletTransactions transactions={transactions} /> */}
          </div>

          {/* IPAD */}
          <div className="container_myAddresses_content" id="globaldata_ipad">
            <h1 className="wallet_main_h1">Мой кошелек</h1>
            <div className="wallet_balance_container">
              <p className="wallet_balance_container_p">Баланс</p>
              <div className="wallet_balance_container_count_wrapper">
                <img
                  src={Wallet}
                  className="wallet_balance_container_count_wrapper_img"
                  alt="wallet image"
                />
                <p className="wallet_balance_container_count_wrapper_p">
                  0.00 BYN
                </p>
              </div>
              <div className="wallet_balance_container_buttons_wrapper">
                <input
                  value="Пополнить"
                  className="wallet_balance_container_buttons_wrapper_button_add"
                  type="button"
                  onClick={() => setModalActivePay(true)}
                />
                <input
                  value="Купить услугу"
                  className="wallet_balance_container_buttons_wrapper_button_buy"
                  type="button"
                />
              </div>
            </div>
            <div className="wallet_history">
              <p className="wallet_history_p">История операции</p>
              <div className="wallet_history_options_wrapper">
                <div className="wallet_history_options_wrapper_1">
                  <select
                    placeholder="Все операции"
                    type="select"
                    className="wallet_history_options_wrapper_select"
                  />
                  <select
                    placeholder="За все время"
                    type="select"
                    className="wallet_history_options_wrapper_select"
                  />
                </div>
                <div className="wallet_history_options_wrapper_1">
                  <div className="wallet_history_options_wrapper_date_wrapper">
                    <p className="wallet_history_options_wrapper_date_wrapper_p">
                      Дата с:
                    </p>
                    <input
                      type="date"
                      className="wallet_history_options_wrapper_date"
                    />
                  </div>
                  <div className="wallet_history_options_wrapper_date_wrapper">
                    <p className="wallet_history_options_wrapper_date_wrapper_p">
                      Дата по:
                    </p>
                    <input
                      type="date"
                      className="wallet_history_options_wrapper_date"
                    />
                  </div>
                </div>

                <input
                  value="Показать"
                  type="button"
                  className="wallet_history_options_wrapper_button"
                />
              </div>
              <div className="wallet_history_stats_wrapper">
                <p className="wallet_history_stats_wrapper_p">
                  Всего зачислено за период:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    20 BYN
                  </span>
                </p>
                <p className="wallet_history_stats_wrapper_p">
                  Всего потрачено за период:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    0 BYN
                  </span>
                </p>
                <p className="wallet_history_stats_wrapper_p">
                  Входящий остаток:{" "}
                  <span className="wallet_history_stats_wrapper_p_span">
                    0 BYN
                  </span>
                </p>
              </div>
            </div>
            {/*  <WalletTable transactions={transactions} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletPage;
