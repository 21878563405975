import React from "react";
import "../../css/ReviewModerate.scss";
import Requests from "../../http/axios-requests";
import { Editor } from "@tinymce/tinymce-react";

const Mailing = () => {
  //обработчик клика по enter
  const keyDownHandler = () => {};

  const editorRef = React.useRef(null);

  const [parameter, setParameter] = React.useState("all_users");
  const [emails, setEmails] = React.useState();
  const [singleEmail, setSingleEmail] = React.useState();
  const [emailSender, setEmailSender] = React.useState(
    "mail@razdelisdrugim.by"
  );
  const [greeting, setGreeting] = React.useState();
  const [appeal, setAppeal] = React.useState();
  const [theme, setTheme] = React.useState();
  const [sentCount, setSentCount] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  const [mailingStarted, setMailingStarted] = React.useState(0);

  let _errors = [];

  //получаем списки мэйлов
  React.useEffect(() => {
    Requests.moderatorGetEmails(parameter).then((res) =>
      setEmails(res.data.map((item) => item.email))
    );
  }, [parameter]);

  const handleSend = () => {
    setMailingStarted(true);

    if (parameter === "users_list") {
      const emailsArr = singleEmail.split(",");
      emailsArr.forEach((email) => {
        Requests.moderatorSendEmail(
          email,
          editorRef.current.getContent(),
          theme,
          greeting,
          appeal,
          emailSender
        )
          .then(() => setSentCount((sentCount) => sentCount + 1))
          .catch(() => {
            _errors.push(email);
            setErrors(_errors);
          });
      });
      return;
    }

    if (parameter === "one_user") {
      Requests.moderatorSendEmail(
        singleEmail,
        editorRef.current.getContent(),
        theme,
        greeting,
        appeal,
        emailSender
      )
        .then(() => setSentCount((sentCount) => sentCount + 1))
        .catch(() => {
          _errors.push(singleEmail);
          setErrors(_errors);
        });
      return;
    }

    if (parameter !== "one_user" && parameter !== "users_list") {
      emails.forEach((email) => {
        Requests.moderatorSendEmail(
          email,
          editorRef.current.getContent(),
          theme,
          greeting,
          appeal,
          emailSender
        )
          .then(() => setSentCount((sentCount) => sentCount + 1))
          .catch(() => {
            _errors.push(email);
            setErrors(_errors);
          });
      });
      return;
    }
  };

  return (
    <div className="admin_reviews_wrapper">
      <div className="admin_reviews_body_wrapper">
        <div className="admin_reviews_body_cards">
          <div class="mailing_wrapper">
            <div>
              <select
                id="option_for_sending"
                className="option_for_sending"
                onChange={(e) => setParameter(e.target.value)}
              >
                <option value="all_users">всем пользотелям</option>
                <option value="private_users">
                  всем пользователям с частным профилем
                </option>
                <option value="private_users_with_things">
                  всем пользователям с частным профилем и минимум одной вещью
                </option>
                <option value="private_users_without_things">
                  всем пользователям с частным профилем и без вещей
                </option>
                <option value="business_users">
                  всем пользователям с бизнес профилем
                </option>
                <option value="business_users_with_things">
                  всем пользователям с бизнес профилем и минимум одной вещью
                </option>
                <option value="business_users_without_things">
                  всем пользователям с бизнес профилем и без вещей
                </option>
                <option value="one_user">одному пользователю</option>
                <option value="users_list">по списку email-ов</option>
              </select>
              {parameter !== "one_user" && parameter !== "users_list" && (
                <p>Найдено почт: {emails && emails.length}</p>
              )}

              {(parameter === "one_user" || parameter === "users_list") && (
                <textarea
                  placeholder="Email через запятую, если их несколько"
                  cols="60"
                  rows="20"
                  id="message"
                  value={singleEmail}
                  onChange={(e) => setSingleEmail(e.target.value)}
                ></textarea>
              )}
              <select
                onChange={(e) => setEmailSender(e.target.value)}
                id="select_senders_email"
                className="option_for_sending"
              >
                <option value="mail@razdelisdrugim.by">
                  mail@razdelisdrugim.by
                </option>
                <option value="admin@razdelisdrugim.by">
                  admin@razdelisdrugim.by
                </option>
                <option value="support@razdelisdrugim.by">
                  support@razdelisdrugim.by
                </option>
              </select>
              <div id="input_field"></div>
              <input
                id="input_for_title"
                className="person_to_send"
                placeholder="Введите тему письма"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
              />
              <input
                id="greeting"
                className="person_to_send"
                placeholder="Введите приветсвие для письма"
                value={greeting}
                onChange={(e) => setGreeting(e.target.value)}
              />
              <input
                id="instead_of_name"
                className="person_to_send"
                placeholder="Введите обращение к пользователю без имени"
                value={appeal}
                onChange={(e) => setAppeal(e.target.value)}
              />
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <button onClick={handleSend} id="button_for_sending">
                Начать рассылку
              </button>
              {mailingStarted && (
                <p className="sentMailsCount" id="count_message">
                  Успешно: {sentCount} из{" "}
                  {parameter === "one_user"
                    ? "1"
                    : parameter === "users_list"
                    ? singleEmail.split(",").length
                    : emails.length}
                  <br />
                  Ошибки: {errors.length}
                </p>
              )}

              <textarea
                value={errors}
                style={{ width: "100%", height: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mailing;
