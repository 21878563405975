import React from "react";
import { useHistory } from "react-router-dom";

const ItemRedirect = () => {
  const history = useHistory();

  React.useEffect(() => {
    history.push(`/item/${window.location.href.split("?id=")[1]}`);
  });

  return <h1>Переадресация</h1>;
};

export default ItemRedirect;
