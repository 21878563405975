import React from "react";
import { ItemModerateComponent } from "../../components";
import "../../css/ReviewModerate.scss";
import Requests from "../../http/axios-requests";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";

const ItemModerate = () => {
  const [items, setItems] = React.useState();

  const [showItemsType, setShowItemsType] = React.useState("shown");
  const [reload, toggleReload] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [pagesCount, setPagesCount] = React.useState();
  const [words, setWords] = React.useState();

  //обработчик клика по enter
  const keyDownHandler = React.useCallback((event) => {
    if (event.keyCode === 13) {
      setItems([]);
      Requests.getModeratorItems(page, words).then((res) => {
        setPagesCount(Number(res.headers["count-filter-items"]));
        setItems(res.data);
      });
    }

    if (event.keyCode === 27) {
      setItems([]);
      setPage(1);
      setWords("");
      Requests.getModeratorItems(1).then((res) => {
        setPagesCount(Number(res.headers["count-filter-items"]));
        setItems(res.data);
      });
    }
  });

  const handleChange = (event, value) => {
    setItems([]);
    setPage(value);
    Requests.getModeratorItems(value, words).then((res) => {
      setPagesCount(Number(res.headers["count-filter-items"]));
      setItems(res.data);
    });
  };

  React.useEffect(() => {
    setItems([]);
    Requests.getModeratorItems(page, words).then((res) => {
      setPagesCount(Number(res.headers["count-filter-items"]));
      setItems(res.data);
      console.log(res.headers);
    });
  }, [reload]);

  return (
    <div className="admin_reviews_wrapper" onKeyDown={(e) => keyDownHandler(e)}>
      <div className="admin_reviews_body_wrapper">
        <div className="admin_reviews_optional_row">
          <p className="admin_reviews_optional_row_title"></p>
          <TextField
            style={{ marginBottom: "20px" }}
            id="standard-basic"
            label="Поиск по словам или id"
            variant="standard"
            value={words}
            onChange={(e) => setWords(e.target.value)}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Показывать</FormLabel>
            <RadioGroup
              onChange={(e) => setShowItemsType(e.target.value)}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
            >
              {" "}
              <FormControlLabel
                value="shown"
                checked={showItemsType === "shown"}
                control={<Radio color="secondary" />}
                label="Только открытые"
              />
              <FormControlLabel
                value="hidden"
                checked={showItemsType === "hidden"}
                control={<Radio color="secondary" />}
                label="Только скрытые"
              />
              <FormControlLabel
                value="all"
                checked={showItemsType === "all"}
                control={<Radio color="secondary" />}
                label="Все"
              />
            </RadioGroup>
          </FormControl>
          <Pagination count={pagesCount} page={page} onChange={handleChange} />
        </div>
        <div className="admin_reviews_body_cards">
          {items &&
            items.map((item, index) => {
              if (showItemsType === "hidden" && item.is_hidden) {
                return (
                  <ItemModerateComponent
                    page={page}
                    reload={reload}
                    toggleReload={toggleReload}
                    item={item}
                    index={index}
                  />
                );
              } else if (showItemsType === "all") {
                return (
                  <ItemModerateComponent
                    page={page}
                    reload={reload}
                    toggleReload={toggleReload}
                    item={item}
                    index={index}
                  />
                );
              } else if (showItemsType === "shown" && !item.is_hidden) {
                return (
                  <ItemModerateComponent
                    page={page}
                    reload={reload}
                    toggleReload={toggleReload}
                    item={item}
                    index={index}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default ItemModerate;
