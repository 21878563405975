import React from "react";
import { Header, Footer } from "../../components/index";
import "./footer-pages.css";
import { Helmet } from "react-helmet";
import { rootAddress } from "../../http/axios-requests";

const HowToRentOut = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CardThings">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={`https://razdelisdrugim.by/how-to-rent-out`}
        />
        <title>Как сдать в аренду?: #разделисдругим</title>
        <meta
          name="description"
          content=" 1. Рекомендуется загрузить в свое объявление фотографии, на
                которых видно состояние объекта и его основные характеристики.
                
                2. Отметить стоимость и длительность аренды.
                3. Сделать подробное описание (функции, состояние, внешний вид и
                другие характеристики, которые помогут пользователям сделать
                выбор)."
        />
        <meta
          name="keywords"
          content="1. Рекомендуется загрузить в свое объявление фотографии, на
                которых видно состояние объекта и его основные характеристики.
                
                2. Отметить стоимость и длительность аренды.
                3. Сделать подробное описание (функции, состояние, внешний вид и
                другие характеристики, которые помогут пользователям сделать
                выбор)."
        />
        <meta
          property="og:title"
          content={`Как сдать в аренду?: #разделисдругим`}
        />
        <meta
          property="title"
          content={`Как сдать в аренду?: #разделисдругим`}
        />
        <meta property="og:site_name" content="Razdelisdrugim.by" />
        <meta
          property="og:description"
          content="1. Рекомендуется загрузить в свое объявление фотографии, на
                которых видно состояние объекта и его основные характеристики.
                
                2. Отметить стоимость и длительность аренды.
                3. Сделать подробное описание (функции, состояние, внешний вид и
                другие характеристики, которые помогут пользователям сделать
                выбор)."
        />

        <meta
          property="og:url"
          content={`https://razdelisdrugim.by/how-to-rent-out`}
        />
        <meta
          property="og:image"
          content={`${rootAddress}/media/favicon.ico`}
          data-react-helmet="true"
        />
      </Helmet>
      <div className="CardThings_Wrapper">
        <div className="CardThings_Wrapper_container">
          <div className="container_content_card">
            <div className="card_content_footer_pages">
              <h1 className="footer_pages_main_title">Как сдать в аренду?</h1>
              <p className="footer_pages_main_p">
                На сайте #разделисдругим вы можете сдать любое имущество в
                аренду. Для этого пройдите простую процедуру регистрации и
                заполните всю информацию об объекте.
              </p>
              <p className="footer_pages_underline">
                Какие данные нужно указывать?
              </p>
              <p className="footer_pages_main_p">
                1. Рекомендуется загрузить в свое объявление фотографии, на
                которых видно состояние объекта и его основные характеристики.
                <br></br>
                2. Отметить стоимость и длительность аренды.<br></br>
                3. Сделать подробное описание (функции, состояние, внешний вид и
                другие характеристики, которые помогут пользователям сделать
                выбор).<br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowToRentOut;
