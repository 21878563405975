import React from "react";
import { Header, Footer } from "../../components/index";
import "./footer-pages.css";
import { Helmet } from "react-helmet";
import { rootAddress } from "../../http/axios-requests";

const Contacts = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CardThings">
      <Header />
      <Helmet>
        <link rel="canonical" href={`https://razdelisdrugim.by/contacts`} />
        <title>Контакты: #разделисдругим</title>
        <meta name="description" content="Контакты сервиса #разделисдругим" />
        <meta
          name="keywords"
          content="раздели с другим, контакты, контакты раздели с другим, разделисдругим"
        />
        <meta property="og:title" content={`Контакты: #разделисдругим`} />
        <meta property="title" content={`Контакты: #разделисдругим`} />
        <meta property="og:site_name" content="Razdelisdrugim.by" />
        <meta
          property="og:description"
          content="Контакты сервиса #разделисдругим"
        />

        <meta
          property="og:url"
          content={`https://razdelisdrugim.by/contacts`}
        />
        <meta
          property="og:image"
          content={`${rootAddress}/media/favicon.ico`}
          data-react-helmet="true"
        />
      </Helmet>
      <div className="CardThings_Wrapper">
        <div className="CardThings_Wrapper_container">
          <div className="container_content_card">
            <div
              itemscope
              itemtype="http://schema.org/Organization"
              className="card_content_footer_pages"
            >
              <h1 className="footer_pages_main_title">
                Контакты #разделисдругим
              </h1>
              <p className="footer_pages_main_p">
                Мы работаем 24/7.
                <br></br>
                <br></br>
                Администратору платформы Вы можете позвонить в рабочие дни с
                10:00 до 20:00.
                <br></br>
                <br></br>
                Телефоны (Viber):{" "}
                <a rel="noreferrer" target="_blank" href={`tel:+375291587752`}>
                  +375 29 1587752
                </a>
                ,{" "}
                <a rel="noreferrer" target="_blank" href={`tel:+375445860988`}>
                  +375 44 5860988
                </a>
                .<br></br>
                <br></br>
                Вы всегда можете связаться с нами по электронной почте:
                <strong>
                  <a
                    href="mailto:admin@razdelisdrugim.by"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    admin@razdelisdrugim.by
                  </a>{" "}
                </strong>
                <br></br>
                <br></br>
                Вы можете написать или позвонить нам в мессенджере Telegram
                сюда:{" "}
                <strong>
                  <a
                    href="https://telegram.me/razdelisdrugim"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    @razdelisdrugim
                  </a>
                </strong>
                <br></br>
                <br></br>
                Приглашаем Вам принять участие в разработке нашего проекта, и
                внести все свои предложения и пожелания тут:{" "}
                <a
                  href="https://forms.gle/p3LjbTYphj1QCLK5A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>https://forms.gle/p3LjbTYphj1QCLK5A</strong>
                </a>
                <br></br>
                <br></br>
              </p>
              <p className="footer_pages_main_p">
                По вопросам рекламы и сотрудничества обращайтесь по адресу:
                <strong>
                  <a
                    href="mailto:mail@razdelisdrugim.by"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    mail@razdelisdrugim.by.
                  </a>
                </strong>
                <br></br>
                <br></br>
                <h2 className="footer_pages_main_title">Оператор платформы:</h2>
                Индивидуальный предприниматель Клементьева Ольга Анатольевна
                <br></br>
                <strong>Адрес</strong>: Республика Беларусь, Минский район, аг.
                Вишневка, ул. Школьная, д. 12А
                <br></br>
                223044
                <br></br>
                УНП 692190483
                <br></br>
                Зарегистрирован 06.09.2021 г. Минским райисполкомом.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
