import React from "react";
import Address from "./Address";
import { useSelector } from "react-redux";
import telephone from "../../img/BookingPage/telephone.png";
import gmailImg from "../../img/BookingPage/gmailimg.png";
import tgImg from "../../img/BookingPage/tgimg.png";
import viberImg from "../../img/BookingPage/viberimg.png";
import WhatsAppLogo from "../../img/CardThings/RightContent/Component 38.png";
import InstagramLogo from "../../img/CardThings/RightContent/Component 39.png";
import VkLogo from "../../img/CardThings/RightContent/Component 42.png";
import OkLogo from "../../img/ProfilePage/ok.png";
import FbLogo from "../../img/ProfilePage/facebook2.png";
import Site from "../../img/ProfilePage/site.png";
import Email from "../../img/ProfilePage/email.png";

const AboutMe = ({ about, addresses, profileData }) => {
  const { isLoggedIn } = useSelector(({ userData }) => userData);

  return (
    <div style={{ width: "100%" }}>
      <div className="public_profile_about_wrapper">
        <p className="public_profile_about_upper_p">О себе</p>
        <p className="public_profile_about_main_p">
          {about ? (
            about
          ) : (
            <span style={{ color: "rgba(9, 29, 32, 0.4)" }}>
              Данный пользователь не указал информацию о себе.
            </span>
          )}
        </p>
      </div>

      <div className="public_profile_about_wrapper">
        <p className="public_profile_about_upper_p">Адреса</p>
        {isLoggedIn ? (
          <Address addresses={addresses} />
        ) : !isLoggedIn ? (
          <p style={{ color: "#4CC9F0" }} className="block_up_address-p">
            Адреса доступны после регистрации
          </p>
        ) : (
          ""
        )}
      </div>

      {profileData && profileData.phone && isLoggedIn && (
        <div className="public_profile_about_wrapper">
          <p className="public_profile_about_upper_p">Контакты</p>
          <div className="body_allblock_header_left_contacts">
            <a
              style={{ textDecoration: "none" }}
              href={`tel:${profileData.phone}`}
              className="left_contacts_row"
            >
              <img width="30px" height="30px" src={telephone} alt="vectors" />
              <p> {profileData && profileData.phone}</p>
            </a>

            {profileData && profileData.email && (
              <a
                style={{ textDecoration: "none" }}
                href={`mailto:${profileData.email}`}
                className="left_contacts_row"
              >
                <img width="30px" height="30px" src={Email} alt="vectors" />
                <p>{profileData && profileData.email}</p>
              </a>
            )}

            {profileData && profileData.user_site && (
              <a
                style={{ textDecoration: "none" }}
                href={
                  profileData.user_site.includes("https://")
                    ? `${profileData.user_site}`
                    : `https://${profileData.user_site}`
                }
                target="_blank"
                className="left_contacts_row"
                rel="noreferrer"
              >
                <img width="30px" height="30px" src={Site} alt="vectors" />
                <p>{profileData && profileData.user_site}</p>
              </a>
            )}

            {profileData && profileData.google_account && (
              <a
                rel="noreferrer"
                target="_blank"
                href={`${
                  profileData && profileData.google_account.includes("https")
                    ? profileData.google_account
                    : `https://${profileData && profileData.google_account}`
                }`}
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img width="30px" height="30px" src={gmailImg} alt="vectors" />
                <p>{profileData && profileData.google_account}</p>
              </a>
            )}

            {profileData && profileData.telegram_account && (
              <a
                rel="noreferrer"
                href={`https://t.me/${
                  profileData && profileData.telegram_account
                }`}
                target="_blank"
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img width="30px" height="30px" src={tgImg} alt="vectors" />
                <p>@{profileData && profileData.telegram_account}</p>
              </a>
            )}

            {profileData && profileData.viber_account && (
              <a
                rel="noreferrer"
                target="_blank"
                href={`viber://chat?number=+${
                  profileData && profileData.viber_account
                }`}
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img width="30px" height="30px" src={viberImg} alt="vectors" />
                <p>+{profileData && profileData.viber_account}</p>
              </a>
            )}

            {profileData && profileData.whatsapp_account && (
              <a
                rel="noreferrer"
                href={`https://api.whatsapp.com/send/?phone=${
                  profileData && profileData.whatsapp_account
                }`}
                target="_blank"
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img
                  width="30px"
                  height="30px"
                  src={WhatsAppLogo}
                  alt="vectors"
                />
                <p>+{profileData && profileData.whatsapp_account}</p>
              </a>
            )}

            {profileData && profileData.link_instagram && (
              <a
                rel="noreferrer"
                href={`${
                  profileData &&
                  profileData.link_instagram.includes(
                    "https://www.instagram.com"
                  )
                    ? profileData.link_instagram
                    : profileData.link_instagram.includes("instagram.com") &&
                      !profileData.link_instagram.includes("https://")
                    ? `https://${profileData.link_instagram}/`
                    : `https://www.instagram.com/${profileData.link_instagram}/`
                }`}
                target="_blank"
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img
                  width="30px"
                  height="30px"
                  src={InstagramLogo}
                  alt="vectors"
                />
                <p>{`${
                  profileData &&
                  profileData.link_instagram.includes(
                    "https://www.instagram.com"
                  )
                    ? profileData.link_instagram
                    : profileData.link_instagram.includes("instagram.com") &&
                      !profileData.link_instagram.includes("https://")
                    ? `https://${profileData.link_instagram}/`
                    : `https://www.instagram.com/${profileData.link_instagram}/`
                }`}</p>
              </a>
            )}

            {profileData && profileData.ok_account && (
              <a
                href={`${
                  profileData && profileData.ok_account.includes("https")
                    ? profileData.ok_account
                    : `https://${profileData && profileData.ok_account}`
                }`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img width="30px" height="30px" src={OkLogo} alt="vectors" />
                <p>{profileData && profileData.ok_account}</p>
              </a>
            )}

            {profileData && profileData.vk_account && (
              <a
                rel="noreferrer"
                href={`${
                  profileData && profileData.vk_account.includes("https")
                    ? profileData.vk_account
                    : `https://${profileData && profileData.vk_account}`
                }`}
                target="_blank"
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img width="30px" height="30px" src={VkLogo} alt="vectors" />
                <p>{profileData && profileData.vk_account}</p>
              </a>
            )}

            {profileData && profileData.link_facebook && (
              <a
                rel="noreferrer"
                href={`${
                  profileData && profileData.link_facebook.includes("https")
                    ? profileData.link_facebook
                    : `https://${profileData && profileData.link_facebook}`
                }`}
                target="_blank"
                style={{ textDecoration: "none" }}
                className="left_contacts_row"
              >
                <img width="30px" height="30px" src={FbLogo} alt="vectors" />
                <p>{profileData && profileData.link_facebook}</p>
              </a>
            )}
          </div>
        </div>
      )}

      {!isLoggedIn && profileData.phone && (
        <div className="public_profile_about_wrapper">
          <p className="public_profile_about_upper_p">Контакты</p>
          <p style={{ color: "#4CC9F0" }} className="block_up_address-p">
            Контакты доступны после регистрации
          </p>
        </div>
      )}
    </div>
  );
};

export default AboutMe;
