import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { openSuccessAlert, openErrorAlert } from "../../redux/actions/userData";

const ProposePositionButton = () => {
  const { userData, subjects, isLoggedIn } = useSelector(
    ({ userData }) => userData
  );
  const { maxItemsToPlaceFree, maxItemsToPlaceFreeLegal } = useSelector(
    ({ settings }) => settings
  );

  const dispatch = useDispatch();

  const addSubjectHandler = () => {
    if (
      isLoggedIn &&
      userData.status === 1 &&
      subjects.length >= maxItemsToPlaceFree &&
      !userData.count_items
    ) {
      dispatch(
        openErrorAlert(`Лимит вещей достигнут (${maxItemsToPlaceFree})`)
      );
      return;
    } else if (isLoggedIn && !userData.id) {
      dispatch(
        openErrorAlert("Идет загрузка данных о профиле. Повторите попытку.")
      );

      return;
    } else if (
      isLoggedIn &&
      userData.status === 2 &&
      subjects.length >= maxItemsToPlaceFreeLegal &&
      !userData.count_items
    ) {
      dispatch(
        openErrorAlert(`Лимит вещей достигнут (${maxItemsToPlaceFreeLegal})`)
      );
      return;
    } else if (
      isLoggedIn &&
      userData.count_items &&
      subjects.length >= userData.count_items
    ) {
      dispatch(
        openErrorAlert(`Лимит вещей достигнут (${userData.count_items})`)
      );
      return;
    } else if (!isLoggedIn) {
      dispatch(openErrorAlert("Сначала авторизуйтесь!"));
      return;
    } else if (!userData.phone_verify) {
      dispatch(
        openErrorAlert(
          "У вас не подтвержден номер телефона. Подтвердите его в профиле."
        )
      );
      setRedirect(<Redirect to="/private-profile" />);
      return;
    }
    window.location.href = "/place-item";
  };

  const [redirect, setRedirect] = React.useState();

  return (
    <div>
      <input
        onClick={addSubjectHandler}
        type="button"
        value="Предложить своё"
        className="header-button add-subject"
      />
      {redirect}
    </div>
  );
};

export default ProposePositionButton;
